import { FormikHelpers } from "formik";
import { Dispatch, SetStateAction } from "react";

import { FormattedOption } from "@/app/lib/format-rec-options";
import { OrderExpiry, OrderType } from "@/app/types/generated/graphql";

import { FiltersType, StackedFilterState, UseFilterReturn } from "../../page-header/filter/filter.types";
export type BuyFormPrefilledAsset = {
  vintages?: string[];
  projects?: string[];
  fuelSources?: string[];
  eligibilities?: string[];
  locations?: string[];
  certifications?: string[];
};

export enum BuyOrderLifeCycle {
  Form = "Form",
  Success = "Success",
}

export type BuyFormValues = {
  volume: number;
  unitPrice: number;
  orderType: OrderType;
  orderExpiry: OrderExpiry | null;
  expiryDate?: string | null;
  fee: number;
};
export type BuyFormOptions = {
  orderTypes: FormattedOption;
  orderExpiries: FormattedOption;
};
export type BuyFormViewProps = {
  formikInitialValue: BuyFormValues;
  handleSubmit: (values: BuyFormValues, actions: FormikHelpers<BuyFormValues>) => Promise<void>;
  buyFormOptions: BuyFormOptions;
  currencySymbol: string;
  overLimitError?: string;
  loading: boolean;
  recOptionsLoading?: boolean;
  setOverLimitError: Dispatch<SetStateAction<string | undefined>>;

  /**Current Step in Buy Order Lifecycle */
  currentStep: BuyOrderLifeCycle;

  closeModal: () => void;

  /** Product Attributes Filter */
  filters: FiltersType;
  stackedValues: UseFilterReturn["stackedValues"];
  removeValue: UseFilterReturn["removeValue"];
  isFilterValid: boolean;
};

export type BuyOrderModalProps = {
  closeModal: () => void;
  defaultAsset?: Omit<StackedFilterState, "statuses">;
  visible: boolean;
};
export type UseBuyOrderModal = (args: Omit<BuyOrderModalProps, "visible">) => BuyFormViewProps;
