import { useOktaAuth } from "@okta/okta-react";
import { Box, Button, IconLoadingOutlined, Text } from "@powerledger/ui-component-lib";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { ModalContext } from "@/app/modal-provider";

export const ResendEmail = ({ userId }: { userId: string }) => {
  const { t } = useTranslation();
  const { oktaAuth } = useOktaAuth();
  const { showContactSupportModal } = useContext(ModalContext);
  const [{ busy, success }, setResendState] = useState({
    busy: false,
    success: false,
  });

  const sendEmailConfirmation = useCallback(async () => {
    if (userId) {
      setResendState({ busy: true, success: false });
      try {
        await oktaAuth.forgotPassword({ factorType: "EMAIL", username: userId });
        setResendState({ busy: false, success: true });
      } catch (error: any) {
        if (error?.xhr?.status !== 401) showContactSupportModal();
        setResendState({ busy: false, success: false });
      }
    }
  }, [userId, oktaAuth, showContactSupportModal]);

  return (
    <Box sx={{ display: ["block", "flex"], alignItems: "center" }}>
      <Box sx={{ mr: 4, mb: [2, 0] }}>{t("Didn't receive it yet?")}</Box>
      <Box sx={{ display: ["block", "flex"], alignItems: "center" }}>
        <Button sx={{ px: 5, mr: 2 }} variant="primary" disabled={busy} onClick={sendEmailConfirmation}>
          {busy ? <IconLoadingOutlined /> : <span>{t(`Send it again`)}</span>}
        </Button>
        {success && <Text sx={{ color: "positive" }}>{t("Success")}</Text>}
      </Box>
    </Box>
  );
};

export default ResendEmail;
