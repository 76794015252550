import { useTranslation } from "react-i18next";

import { volumeFormatter } from "@/app/lib/volume-formatter";

import RecActionDetailsModal from "../common/rec-actions-details-modal";
import { ExportRequestDetailsViewProps } from "./export-history-details-modal.types";
import { getExportHistoryDetailsData } from "./use-export-request-details";

export default function ExportRequestDetailsView({
  exportData,
  recOptions,
  loading,
  closeModal,
  visible,
}: Readonly<ExportRequestDetailsViewProps>) {
  const { t } = useTranslation();

  return (
    <RecActionDetailsModal
      recOptions={recOptions}
      showStatusMessage
      data={getExportHistoryDetailsData(exportData?.exportRequestDetails ?? [], recOptions)}
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      title={t("Export Request {{requestId}}", { requestId: exportData?.exportNumber })}
      subtitle={t("Total {{recs}} REC(s) requested for export.", {
        recs: volumeFormatter(exportData?.totalVolume),
      })}
    />
  );
}
