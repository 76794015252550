import { Button, Flex, IconLoading, Table } from "@powerledger/ui-component-lib";
import { ErrorBoundary } from "@sentry/react";
import { lazy, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { ActionStatusChip } from "@/app/components/action-status-chip";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { getRetirementType } from "@/app/lib/format-commodity-action-data-for-report";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { volumeFormatter } from "@/app/lib/volume-formatter";
import { RetirementStatus } from "@/app/types/generated/graphql";

import { RetirementHistoryTableData, RetirementHistoryTableProps } from "../retirement-history.types";

const CommodityActionConfirmationDownloadButton = lazy(
  () =>
    import(
      "@/app/components/download-report-components/commodity-action-confirmation-download-button/commodity-action-confirmation-download-button"
    ),
);

export default function RetirementHistoryTable({
  data,
  loading,
  pageInfo,
  fetchData,
  onSeeDetails,
}: Readonly<RetirementHistoryTableProps>) {
  const { t } = useTranslation();
  const columns: Column<RetirementHistoryTableData>[] = useMemo(
    () => [
      {
        Header: t("Retirement Number"),
        disableSortBy: true,
        accessor: "retirementNumber",
        Cell: ({ value }: { value: string }) => {
          return <TableValueWrapper value={value} />;
        },
      },
      {
        Header: t("Requested At"),
        accessor: "requestedOn",
        Cell: ({ value }: { value: string }) => {
          return (
            <TableValueWrapper
              value={formatDate(value, { formatStyle: AppDateFormats.AbbreviatedMonthFormatWithTime })}
              containerSx={{ minWidth: 80 }}
            />
          );
        },
      },
      {
        Header: t("Quantity"),
        accessor: "totalVolume",
        Cell: ({ value }: { value: number }) => {
          return <TableValueWrapper value={volumeFormatter(value)} />;
        },
      },
      {
        Header: t("Retirement Type"),
        id: "type",
        Cell: ({ row }: { row: Row<RetirementHistoryTableData> }) => {
          return (
            <TableValueWrapper
              value={getRetirementType(row.original.retirementType, row.original.retirementCategory)}
            />
          );
        },
      },
      {
        Header: t("Status"),
        accessor: "status",
        Cell: ({ value }: { value: RetirementStatus }) => {
          return <ActionStatusChip status={value} />;
        },
      },
      {
        Header: t("Action"),
        id: "action",
        Cell: ({ row }: { row: Row<RetirementHistoryTableData> }) => {
          return (
            <Flex>
              {row.original.status === RetirementStatus.Retired ? (
                <ErrorBoundary fallback={<div>{t("Something went wrong")}</div>}>
                  <Suspense fallback={<IconLoading sx={{ ml: 2 }} size="small" />}>
                    <CommodityActionConfirmationDownloadButton actionId={row.original.id} action="retirement" />
                  </Suspense>
                </ErrorBoundary>
              ) : (
                <Button
                  variant="pill.compactSecondary"
                  sx={{ py: 1, mr: 2 }}
                  aria-label={t("See details for retirement request {{id}}", { id: row.original.retirementNumber })}
                  onClick={() => {
                    onSeeDetails(row.original.id);
                  }}
                >
                  {t("See Details")}
                </Button>
              )}
            </Flex>
          );
        },
      },
    ],
    [t, onSeeDetails],
  );

  return (
    <Table
      loading={loading}
      showScrollArrows
      manualPagination
      columns={columns}
      dataSource={data}
      fetchData={fetchData}
      pageInfo={pageInfo}
      translation={getTableTranslation(t)}
    />
  );
}
