import { ButtonProps, Option, SelectType, ThemeUIStyleObject } from "@powerledger/ui-component-lib";
import { ActionMeta, GroupBase, MultiValue, OnChangeValue, SingleValue } from "react-select";
// eslint-disable-next-line import/no-unresolved
import { SelectComponents } from "react-select/dist/declarations/src/components";

import { FormattedOption } from "@/app/lib/format-rec-options";

import { FormFieldLabelProps } from "../../form/form-field-label";

export type SelectTypeOnChange<T, K extends boolean> =
  | ((newValue: OnChangeValue<T, K>, actionMeta: ActionMeta<T>) => void)
  | undefined;

type FilterSelectors =
  | {
      onChange: (option: MultiValue<Option>) => void;
      isMulti: true;
      values: string[];
    }
  | { onChange: (option: SingleValue<Option>) => void; isMulti: false; values: string };

export type FilterDropdownProps<
  T extends FormattedOption["0"] = FormattedOption["0"],
  K extends boolean = true,
> = SelectType<T, K> & {
  render?: () => JSX.Element;
  menuOpen?: boolean;
  header?: JSX.Element | null;
  wrapperStyles?: {
    menuStyles?: ThemeUIStyleObject;
    containerStyles?: ThemeUIStyleObject;
  };
  button?: ButtonProps;
};

export type FiltersType = Array<
  {
    name: string;
    options: FormattedOption;
    disabled?: boolean;
    keys?: FilterKeys | FilterKeys[];
    disableOptions?: (key?: string) => boolean;
    /**
     * This style goes to inputStyles of Select component
     */
    styles?: ThemeUIStyleObject;
    components?: Partial<SelectComponents<Option, boolean, GroupBase<Option>>>;
    header?: JSX.Element | null;
    render?: () => JSX.Element;
    /**
     * Shows if the filter is required to be selected (Add * if required)
     */
    isRequired?: boolean;
    /**
     * This style is the style of menu container
     */
    wrapperStyles?: FilterDropdownProps["wrapperStyles"];
    isMenuOpen?: boolean;
    /**
     * Is the filter a dropdown or other fields like checkbox / button, etc
     * by default set this as true
     */
    isDropdown?: boolean;
  } & FilterSelectors &
    FormFieldLabelProps
>;

export type FilterKeys =
  | "vintages"
  | "locations"
  | "eligibilities"
  | "fuelSources"
  | "projects"
  | "certifications"
  | "cod"
  | "statuses"
  | "dateRange"
  // exportNumber, retirementNumber, orderNumber etc
  | "actionCodes";

export type FilterValue = { value: string; key: Date };

export type FilterState = Record<FilterKeys, string[]>;

export type FilterProps = {
  buttonDisabled: boolean;
  someValueFilled: boolean;
  filters: FiltersType;
  applyFilter: () => void;
  resetFilter: () => void;
};

export type StackedFilterState = Record<FilterKeys, FilterValue[]>;

/**
 * This is the state of value
 */
export enum StackedValueState {
  /**
   * If the value is applied previously, but currently user wants to remove it
   * The value state is deleting , Since the value is still in applied state and for better UX we show them with
   * The action they can perform would be (Add them back)
   */
  DELETING = "deleting",
  /**
   * If the value is applied previously, and user does not change it (the value is Added)
   * Every value that is once applied will be in this state
   * User can only delete those values from current state, we will still show them this value
   * Once deleted (cross icon on the chip for eg), the state would be deleting state
   */
  ADDED = "added",
  /**
   * If the value is not applied previously,  user wants to add new values
   * The new values value state is Adding , This value can be removed since it was not applied before, once removed it wont be shown in UI
   */
  ADDING = " adding",
}

export type StackedValues = Array<FilterValue & { type: FilterKeys; label: string; state: StackedValueState }>;

export type UseFilterReturn = FilterProps & {
  removeValue: (type: FilterKeys, value: string | string[]) => void;
  addValue: (type: FilterKeys, value: StackedValues["0"]) => void;
  stackedValues: StackedValues;
  filterValues: StackedFilterState;
  appliedFilterValues: StackedFilterState;
};

export type ApplyFiltersFn = (filter: StackedFilterState) => void;

export type UseFilter = (args: {
  customSetter?: React.Dispatch<React.SetStateAction<StackedFilterState>>;
  customValues?: StackedFilterState;
  shouldSelectVintage?: boolean;
  hideStatusFilter?: boolean;
  initialValues?: StackedFilterState;
  applyFilters?: ApplyFiltersFn;
  overrideFilters?: FiltersType;
  filterFormFieldProps?: Partial<
    Record<FilterKeys, FormFieldLabelProps & { dynamicInfo?: (filterValues: FilterValue[]) => string }>
  >;
}) => UseFilterReturn;
