import { useOktaAuth } from "@okta/okta-react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import notifications, { fallBackErrorMessage } from "@/app/container/notifications";
import { usePlaidLinkModal } from "@/app/hooks";
import { getSupportEmail } from "@/app/lib/get-support-email";
import {
  AccountDocument,
  FundingSourceStatus,
  useFundingSourceInitialisingTokenQuery,
  useRemoveFundingSourceMutation,
} from "@/app/types/generated/graphql";

import { fundingSourceStatusLabelMap, VALID_FUNDING_SOURCE_UNLINK_STATUSES } from "../../funding-source-status-maps";
import { BankAccountHookProps, BankAccountProps } from "./bank-account.types";

export const useBankAccount = ({ linkedPaymentMethod }: BankAccountHookProps): BankAccountProps => {
  const { t } = useTranslation();
  const {
    oktaAuth: { tokenManager },
  } = useOktaAuth();

  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [removeFundingSource, { loading: unlinkLoading }] = useRemoveFundingSourceMutation({
    refetchQueries: [AccountDocument],
  });
  const supportEmail = getSupportEmail();

  const bankAccount = linkedPaymentMethod.fundingSource;

  const doRemoveFundingSource = useCallback(async () => {
    try {
      await removeFundingSource({
        variables: {
          removeFundingSourceInput: { paymentMethodCode: linkedPaymentMethod.paymentMethod.code },
        },
      });
      await tokenManager.renew("accessToken");
    } catch (e) {
      if (e instanceof Error) {
        notifications.error({
          description: t(e?.message),
        });
      }
    }
  }, [linkedPaymentMethod.paymentMethod.code, tokenManager, removeFundingSource, t]);

  const fundingSourceStatusLabel = bankAccount?.status ? fundingSourceStatusLabelMap[bankAccount.status] : "";

  const showCompleteLinkButton = !!(
    bankAccount?.status && bankAccount.status === FundingSourceStatus.WaitingForCustomerAction
  );

  const showUnlinkButton = useMemo(() => {
    return !!(bankAccount?.status && VALID_FUNDING_SOURCE_UNLINK_STATUSES.includes(bankAccount.status));
  }, [bankAccount?.status]);

  const { data: initialisingTokenData, loading: loadingInitialisingTokenData } = useFundingSourceInitialisingTokenQuery(
    {
      skip: showUnlinkButton && !showCompleteLinkButton,
    },
  );
  // This will look up the error code in Locize for the full message
  // If the code is not in Locize, it will use the default fallback
  const bankAccountErrorMessage =
    bankAccount?.status === FundingSourceStatus.Error
      ? t(bankAccount.errorCode || "", fallBackErrorMessage, {
          supportEmail,
        })
      : "";

  const initialisingToken = useMemo(
    () =>
      initialisingTokenData?.account?.linkedPaymentMethods?.find(
        (paymentMethod) => paymentMethod?.id === linkedPaymentMethod?.id,
      )?.initialisingToken,
    [initialisingTokenData, linkedPaymentMethod?.id],
  );

  const {
    plaidLinkError,
    disablePlaidModal,
    openPlaidModal,
    plaidLinkLoading: loading,
  } = usePlaidLinkModal({
    onLinkAccountSuccess: () => {},
    initialisingToken,
  });

  return {
    linkedPaymentMethod,
    loading: loading || loadingInitialisingTokenData,
    showUnlinkButton,
    showUnlinkModal,
    setShowUnlinkModal,
    removeFundingSource: doRemoveFundingSource,
    unlinkLoading,
    plaidLinkError,
    disablePlaidModal,
    openPlaidModal,
    fundingSourceStatusLabel,
    showCompleteLinkButton,
    bankAccountErrorMessage,
  };
};
