import { Box, Link, Text } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { getSupportEmail, SupportEmailTypes } from "@/app/lib/get-support-email";

export const LinkedFundingSourceTooltip: FC<{ message: string; supportEmailType?: SupportEmailTypes }> = ({
  message,
  supportEmailType,
}) => {
  const supportEmail = getSupportEmail(supportEmailType);
  const { t } = useTranslation();
  return (
    <Box sx={{ fontSize: 1, maxWidth: 300 }}>
      <Text>
        {t("{{message}}, or ", { message })}
        <Link href={`mailto:${supportEmail}`} sx={{ color: "text" }}>
          {t("contact our support team.")}
        </Link>
      </Text>
    </Box>
  );
};
