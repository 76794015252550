import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlaidLinkOnSuccess } from "react-plaid-link";

import notifications from "../../container/notifications";
import {
  AddFundingSourceResponse,
  PaymentMethodCode,
  useAddFundingSourceMutation,
} from "../../types/generated/graphql";

export const VERIFICATION_EXPIRED_MESSAGE = "Verification time period expired, please unlink account and try again";
export const VERIFICATION_FAILED_MESSAGE = "Exhausted all 3 verification attempts, please unlink account and try again";
export const UNSUPPORTED_ACCOUNT_ERROR_MESSAGE =
  "Unable to link bank account, unsupported account type selected, please try again";
export const GENERIC_ERROR_MESSAGE = "Something went wrong, could not link bank account";

const PLAID_VERIFICATION_STATUS_EXPIRED = "verification_expired";
const PLAID_VERIFICATION_STATUS_FAILED = "verification_failed";

export const usePlaidLinkOnSuccessHandler = ({
  onLinkAccountSuccess,
}: {
  onLinkAccountSuccess?: (response: AddFundingSourceResponse) => void;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [addFundingSource] = useAddFundingSourceMutation();
  const [error, setError] = useState<Error>();

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken, metadata) => {
      const account = metadata.accounts.length > 0 && metadata.accounts[0];
      if (account && account.verification_status === PLAID_VERIFICATION_STATUS_EXPIRED) {
        setError(new Error(t(VERIFICATION_EXPIRED_MESSAGE)));
      } else if (account && account.verification_status === PLAID_VERIFICATION_STATUS_FAILED) {
        setError(new Error(t(VERIFICATION_FAILED_MESSAGE)));
      } else if (account) {
        try {
          setLoading(true);

          const { data } = await addFundingSource({
            variables: {
              addFundingSourceInput: {
                paymentMethodCode: PaymentMethodCode.DwollaPlaid,
                token: publicToken,
                identifier: account.id || "",
              },
            },
          });

          onLinkAccountSuccess && onLinkAccountSuccess(data?.addFundingSource as AddFundingSourceResponse);
          setError(undefined);
        } catch (error: unknown) {
          error instanceof Error && setError(new Error(t(GENERIC_ERROR_MESSAGE)));
        } finally {
          setLoading(false);
        }
      } else {
        setError(new Error(t(UNSUPPORTED_ACCOUNT_ERROR_MESSAGE)));
      }
    },
    [addFundingSource, onLinkAccountSuccess, t],
  );
  useEffect(() => {
    if (error) {
      notifications.error({
        description: error.message,
      });
    }
  }, [error, t]);

  return {
    loading,
    error,
    onSuccess,
  };
};
