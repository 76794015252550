import { IconLoading, IconSave } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Flex, Text } from "theme-ui";

import { BeneficialOwners, NoBeneficialOwnersModal } from "@/app/components";
import { PersonalInformationForm } from "@/app/components/personal-information-form";
import { Company, ContactType } from "@/app/types/generated/graphql";

import { StepButton } from "../step-button";
import { StepLayout } from "../step-layout";
import { ApplicantBeneficialOwnersStepViewProps } from "./applicant-beneficial-owner-step.types";

export const ApplicantBeneficialOwnersStepView: React.FC<ApplicantBeneficialOwnersStepViewProps> = ({
  showForm,
  setShowForm,
  saveBo,
  dirty,
  submitting,
  goBack,
  goNext,
  boIdToRemove,
  handleOpenBOForm,
  company,
  loading,
  setBoIdToRemove,
  bosDisabled,
  handleDeleteBo,
  deleteBOSubmitting,
  showModal,
  setShowModal,
  onSuccessCallback,
}) => {
  const { t } = useTranslation();

  const beneficialOwnersForm = useMemo(() => {
    return (
      <>
        <PersonalInformationForm formId={ContactType.BeneficialOwner} />
        <Flex sx={{ alignItems: "center", justifyContent: "flex-start", mt: 4 }}>
          <Button
            variant="text"
            sx={{ mr: 4 }}
            onClick={() => setShowForm?.(false)}
            data-testid={"beneficial-owners-stage--cancel"}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={saveBo}
            data-testid={"beneficial-owners-stage--save"}
            disabled={!dirty || submitting}
          >
            <Flex sx={{ alignItems: "center", display: "inline-flex" }}>
              <IconSave size={3} noSvgPositioning sx={{ mr: 2 }} color="plain" />
              <Text>{t("Save")}</Text>
            </Flex>
          </Button>

          {submitting && <IconLoading sx={{ ml: 2 }} size="small" />}
        </Flex>
      </>
    );
  }, [dirty, setShowForm, saveBo, t, submitting]);
  return (
    <StepLayout
      id="BeneficialOwners"
      heading={t("Applicant Beneficial Owners")}
      disclaimer={t(
        "A beneficial owner is a natural person who directly or indirectly owns 25% or more of a company or has significant control or management responsibility. If there are no natural persons who own more than 25% or have significant control, then no information is required. A beneficial owner cannot be another company, corporate entity or nominee owner",
      )}
      actions={
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!showForm && (
            <>
              <StepButton back onClick={goBack} />
              <StepButton onClick={goNext} />
            </>
          )}
        </Flex>
      }
    >
      <>
        {showForm ? (
          beneficialOwnersForm
        ) : (
          <BeneficialOwners
            boIdToRemove={boIdToRemove}
            handleOpenForm={handleOpenBOForm}
            company={company as Company}
            loading={loading}
            setBoIdToRemove={setBoIdToRemove}
            disabledBos={bosDisabled}
            handleDeleteBo={handleDeleteBo}
            deleteBoLoading={deleteBOSubmitting}
          />
        )}
        <NoBeneficialOwnersModal
          visible={showModal}
          closeModal={() => setShowModal(false)}
          onSuccess={onSuccessCallback}
        />
      </>
    </StepLayout>
  );
};
