import { Modal } from "@powerledger/ui-component-lib";

import { BuyForm } from "./buy-form";
import { BuyOrderLifeCycle, BuyOrderModalProps } from "./buy-order-modal.types";
import { useBuyOrderModal } from "./use-buy-order-modal";

export const BuyOrderModal: React.FC<BuyOrderModalProps> = ({ defaultAsset, visible, closeModal }) => {
  const logic = useBuyOrderModal({ defaultAsset, closeModal });
  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      modalMaxWidth="1000px"
      sx={{
        ...(logic.currentStep === BuyOrderLifeCycle.Form
          ? {
              height: window.innerHeight < 800 ? `${window.innerHeight - 100}px !important` : "auto",
              overflow: "auto",
              maxHeight: "inherit",
            }
          : {}),
        color: logic.currentStep === BuyOrderLifeCycle.Success ? "background" : "text",
      }}
    >
      <BuyForm {...logic} />
    </Modal>
  );
};
