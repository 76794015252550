import { Box, Button, ChevronDownSVG, Flex, LegacySelect, Paragraph, Text } from "@powerledger/ui-component-lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { components as selectComponent, OnChangeValue, Options } from "react-select";

import { useOutSideBoundaryClick } from "@/app/hooks/use-outside-boundary-click";
import { Dimension, useResize } from "@/app/hooks/use-resize";
import { FormattedOption } from "@/app/lib/format-rec-options";
import { SearchSVG } from "@/assets/icons";

import { FilterDropdownProps } from "./filter.types";

const menuWidth = 272;
const buttonHeight = 44;
const menuButtonGap = 9;

const getMenuPosition = (menuHeight: number, offsetDomRect?: DOMRect, limitingBoxDimension?: Dimension) => {
  const left = (offsetDomRect?.left ?? 0) + menuWidth >= (limitingBoxDimension?.windowWidth ?? 0) ? -100 : 0;
  const menuPlacedBelowOffsetDom = -(menuHeight + menuButtonGap);

  const verticallyHiddenPortionSize =
    (offsetDomRect?.bottom ?? 0) + menuHeight - (limitingBoxDimension?.windowHeight ?? 0);

  if (verticallyHiddenPortionSize <= 0) {
    return { left, bottom: menuPlacedBelowOffsetDom };
  }

  const isHiddenPortionMoreThanHalfOfMenuHeight = verticallyHiddenPortionSize > menuHeight / 2;
  const menuPlacedAboveOffsetDom = buttonHeight + menuButtonGap;

  // Move above just enough to avoid menu to get hidden and have some extra space below the menu
  const menuPlacedTowardsCenter = verticallyHiddenPortionSize - (menuHeight - 3 * menuButtonGap);

  let bottom;
  if (isHiddenPortionMoreThanHalfOfMenuHeight) {
    bottom = menuPlacedAboveOffsetDom;
  } else {
    bottom = menuPlacedTowardsCenter;
  }

  return { left, bottom };
};

export default function FilterDropdown<T extends FormattedOption["0"] = FormattedOption["0"], K extends boolean = true>(
  props: FilterDropdownProps<T, K>,
) {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(props.menuOpen ?? false);
  const menuHeight = props.header ? 400 : 358;

  const { menuRef, buttonRef } = useOutSideBoundaryClick({ setShowMenu: setIsMenuOpen });
  const { dimensions } = useResize();
  const { left, bottom } = getMenuPosition(menuHeight, buttonRef.current?.getBoundingClientRect(), dimensions);

  return (
    <Box sx={{ position: "relative", ...props?.wrapperStyles?.containerStyles }}>
      <Button
        ref={buttonRef}
        disabled={props.disabled}
        sx={{
          color: "textDarker",
          border: "1px solid var(--theme-ui-colors-shadow)",
          bg: "transparent",
          "&:enabled:hover, &:enabled:focus": {
            bg: "transparent",
          },
          "&:disabled": {
            bg: "transparent",
            color: "gray.400",
          },
          display: "flex",
          height: 36,
          justifyContent: "space-between",
          opacity: props.disabled ? 0.9 : 1,
          svg: {
            transform: isMenuOpen ? "rotate(180deg)" : "",
            path: {
              fill: props.disabled ? "textDark" : "text",
            },
          },
          gap: 2,
        }}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        {...props.button}
      >
        {props.placeholder} <ChevronDownSVG color="text" width={20} />
      </Button>
      {isMenuOpen && (
        <Box
          ref={menuRef}
          sx={{
            position: "absolute",
            bottom,
            zIndex: 100,
            left,
            background: "pageBackground",
            boxShadow: "0 0 10px var(--theme-ui-colors-shadow)",
            overflow: "hidden",
            width: menuWidth,
            height: menuHeight,
            borderRadius: 4,
            ...props?.wrapperStyles?.menuStyles,
          }}
        >
          {props.render?.() ?? (
            <>
              <Box
                sx={{
                  px: 3,
                  pt: 2,
                  fontSize: 0,
                }}
              >
                {props.header}
              </Box>
              <Box
                sx={{
                  px: 3,
                }}
              >
                <Flex
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Paragraph color="text">{t("Search")}</Paragraph>
                  <Text
                    sx={{
                      fontSize: 0,
                    }}
                  >
                    {Array.isArray(props.value)
                      ? props.value.length
                        ? `(${t(`{{values}} Selected`, {
                            values: props.value.length,
                          })})`
                        : null
                      : null}
                  </Text>
                  {(Array.isArray(props.value) ? !!props.value?.length : !!props.value) && (
                    <Button
                      variant="text"
                      onClick={() => {
                        if (props.isMulti)
                          props.onChange?.([] as unknown as OnChangeValue<T, K>, {
                            action: "clear",
                            name: "select",
                            removedValues: props.value as Options<T>,
                          });
                        else {
                          props.onChange?.(null as OnChangeValue<T, K>, {
                            action: "clear",
                            name: "select",
                            removedValues: props.value as Options<T>,
                          });
                        }
                      }}
                    >
                      {t("Reset")}
                    </Button>
                  )}
                </Flex>
                <LegacySelect
                  {...props}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  controlShouldRenderValue={false}
                  isClearable={false}
                  blurInputOnSelect={false}
                  backspaceRemovesValue={false}
                  menuIsOpen
                  sx={{
                    width: "100%",
                  }}
                  maxMenuHeight={240}
                  inputStyles={{
                    dropdownIndicator: {
                      display: "none",
                    },
                    input: {
                      display: "flex",
                      width: "100%",
                    },
                    option: {
                      background: "plain",
                    },
                    ...props.inputStyles,
                  }}
                  components={{
                    ValueContainer: (props) => (
                      <selectComponent.ValueContainer {...props}>
                        <Flex sx={{ alignItems: "center" }}>
                          <SearchSVG />
                        </Flex>
                        {props.children}
                      </selectComponent.ValueContainer>
                    ),
                    ...props.components,
                  }}
                  closeMenuOnScroll
                  /**
                   * Close Menu if isMulti is false
                   * This preserves the menuIsOpen (true) in case of single selector
                   */
                  closeMenuOnSelect={!props.isMulti}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
