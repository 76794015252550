import { Button, Flex, IconLoading, Modal, Paragraph, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { Field, Formik, useFormikContext } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { getTotalMarketValue, ORDER_LOWER_LIMIT, ORDER_UPPER_LIMIT } from "@/app/lib/order-helpers";

import { AppCleaveTypes, CleaveInput } from "../cleave-input";
import { EligibilityValueFormatter } from "../eligibility-value-formatter";
import { SeparatorLayout } from "../separator-layout";
import {
  CounterSteps,
  CreateCounterOfferModalProps,
  FormValues,
  QuanityFieldProps,
} from "./create-counter-offer-modal.types";

const formFields = [
  {
    label: "Counter Volume",
    fieldName: "counterVolume",
    fieldType: AppCleaveTypes.Quantity,
    hasPrefix: false,
  },
  {
    label: "Counter Price",
    fieldName: "counterPrice",
    fieldType: AppCleaveTypes.Amount,
    hasPrefix: true,
  },
];

const QuanityField: FC<QuanityFieldProps> = ({ currentStep, field, currencySymbol }) => {
  const { t } = useTranslation();
  const { errors } = useFormikContext<FormValues>();
  const { fieldName, label, hasPrefix, fieldType } = field;
  return (
    <Flex
      sx={{
        width: "30%",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Text
        sx={{
          fontSize: 2,
          fontWeight: "bold",
          color: "textDark",
        }}
      >
        {t(`{{label}}`, { label })}
      </Text>
      <Field
        name={fieldName}
        component={CleaveInput}
        prefix={hasPrefix && currencySymbol}
        type={fieldType}
        disabled={currentStep === CounterSteps.COUNTER_SUBMIT}
        sx={{
          input: {
            fontSize: 2,
          },
        }}
      />
      <Paragraph
        sx={{
          fontSize: 0,
          color: "error.500",
        }}
      >
        {errors?.[fieldName as keyof FormValues]}
      </Paragraph>
    </Flex>
  );
};

export const CreateCounterOfferModalView = ({
  visible,
  closeModal,
  counteredTableData,
  currentStep,
  modalSubTitle,
  handleStepChange,
  currencySymbol,
  onCounterConfirm,
}: CreateCounterOfferModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal visible={visible} onCancel={closeModal} title="Counter Offer" subtitle={modalSubTitle}>
      <Flex
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        {currentStep === CounterSteps.COUNTER_SUCCESS ? (
          <Flex
            sx={{
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button variant="error" onClick={closeModal}>
              {t("Cancel")}
            </Button>
            <Button>{t("View Offer")}</Button>
          </Flex>
        ) : (
          <>
            <SeparatorLayout
              contentStyle={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "14%",
              }}
              data={{
                Vintage: counteredTableData.vintages,
                Eligibility: (
                  <EligibilityValueFormatter
                    certificationsAndEligibilities={counteredTableData.certificationsAndEligibilities}
                    position={counteredTableData.position}
                  />
                ),
                Project: counteredTableData.projects,
                "Fuel Sources": counteredTableData.fuelSources,
                "Ask Volume": counteredTableData.currentVolume,
                "Ask Price": currency(counteredTableData.price.amount || 0, {
                  separator: ",",
                  fromCents: true,
                }).format(),
              }}
            />
            <Formik
              initialValues={{
                counterVolume: counteredTableData.currentVolume,
                counterPrice: currency(counteredTableData.price.amount || 0, { fromCents: true }).value,
              }}
              validationSchema={Yup.object().shape({
                counterVolume: Yup.number()
                  .test(
                    "required-positive-min",
                    t("Enter valid volume"),
                    (value) => value !== undefined && value > 0 && value >= 1,
                  )
                  .max(counteredTableData.currentVolume || 0, t("Counter's volume cannot exceed the total ask volume")),
                counterPrice: Yup.number()
                  .test(
                    "required-positive-min",
                    t("Enter valid amount"),
                    (value) => value !== undefined && value > 0 && value >= 1,
                  )
                  .when("counterVolume", (counterVolume) => {
                    return Yup.number().test(
                      "required-positive-min",
                      t("Counter's must be between $50 and $200k"),
                      function (value = 1) {
                        const totalPrice = getTotalMarketValue(value, counterVolume).value;
                        return totalPrice >= ORDER_LOWER_LIMIT && totalPrice <= ORDER_UPPER_LIMIT;
                      },
                    );
                  }),
              })}
              onSubmit={async (values, action) => onCounterConfirm(values, action)}
            >
              {({ handleSubmit, isValid, isSubmitting }) => (
                <>
                  <Flex
                    sx={{
                      gap: 2,
                    }}
                  >
                    {formFields.map((field, index) => (
                      <QuanityField
                        currentStep={currentStep}
                        field={field}
                        key={`${field.fieldName}-${index}`}
                        currencySymbol={currencySymbol}
                      />
                    ))}
                  </Flex>
                  <Flex
                    sx={{
                      justifyContent: "flex-end",
                      gap: 2,
                    }}
                  >
                    {currentStep === CounterSteps.COUNTER_FORM ? (
                      <>
                        <Button variant="error" onClick={closeModal}>
                          {t("Cancel")}
                        </Button>
                        <Button
                          disabled={!isValid || isSubmitting}
                          onClick={() => {
                            handleStepChange(CounterSteps.COUNTER_SUBMIT);
                          }}
                        >
                          {t("Next")}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="text"
                          sx={{ color: "accentLight", textDecoration: "underline", textUnderlineOffset: 2 }}
                          onClick={() => handleStepChange && handleStepChange(CounterSteps.COUNTER_FORM)}
                        >
                          {t("Go back")}
                        </Button>
                        <Button disabled={!isValid || isSubmitting} type="submit" onClick={() => handleSubmit()}>
                          {t("Confirm")}
                        </Button>
                      </>
                    )}
                    {isSubmitting && <IconLoading size="small" />}
                  </Flex>
                </>
              )}
            </Formik>
          </>
        )}
      </Flex>
    </Modal>
  );
};
