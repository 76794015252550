import { Box, Flex, IconInfo } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { LoadingOverlayIndicator } from "@/app/components/loading-overlay-indicator";
import { Confirmation } from "@/app/components/order-modal";
import { HeaderText } from "@/app/components/page-header";

import RecActionSummary from "../common/rec-action-summary/rec-action-summary";
import RecActionTable from "../common/rec-action-table/rec-action-table";
import { ExportRecsFormValues, ExportRecsLifeCycle, ExportRecsViewProps } from "./export-recs.types";

const exportInfoText =
  "The RECs will be unencumbered and transferred from your TraceX account to your linked registry account.";

export const ExportRecsView: React.FC<ExportRecsViewProps> = ({
  tableData,
  recOptions,
  loading,
  onProceed,
  formikProps,
  onGoBack,
  currentStep,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return <LoadingOverlayIndicator />;
  }

  if (currentStep === ExportRecsLifeCycle.Summary) {
    return (
      <RecActionSummary
        infoText={exportInfoText}
        action="export"
        data={formikProps.initialValues as ExportRecsFormValues}
        recOptions={recOptions}
        onProceed={onProceed}
        onGoBack={onGoBack}
      />
    );
  }

  if (currentStep === ExportRecsLifeCycle.Success) {
    return (
      <Confirmation
        text={t("Your export request has been received.")}
        goBack={onGoBack}
        sx={{ p: 3 }}
        action={{
          content: "View your request here",
          onClick: () => {
            onProceed(formikProps.initialValues as ExportRecsFormValues);
          },
        }}
      />
    );
  }

  return (
    <Box as="main" sx={{ m: 4, position: "relative" }}>
      <HeaderText title={t("Export RECs")} sx={{ width: 350 }} />
      <Flex
        sx={{
          fontSize: 0,
          alignItems: "center",
          wordBreak: "break-all",
        }}
      >
        <IconInfo size={5} color="primary.500" />
        {t(exportInfoText)}
      </Flex>
      <Box sx={{ my: 2 }}>
        <Formik
          initialValues={formikProps.initialValues as ExportRecsFormValues}
          onSubmit={onProceed}
          validationSchema={Yup.object().shape({
            values: Yup.array().of(
              Yup.object().shape({
                actionVolume: Yup.number()
                  .required(t("Enter valid amount"))
                  .positive(t("Enter valid amount"))
                  .max(Yup.ref("availableBalance") || 0, t("You don't have enough RECs"))
                  .test("whole-number", t("Enter valid amount"), (value = 0) => Number.isInteger(value)),
              }),
            ),
          })}
          validateOnMount
          {...formikProps}
        >
          <RecActionTable recOptions={recOptions} tableData={tableData} loading={loading} onGoBack={onGoBack} />
        </Formik>
      </Box>
    </Box>
  );
};
