import { alpha, Box, Button, Flex, Modal, Text } from "@powerledger/ui-component-lib";
import { lazy, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingOverlayIndicator } from "@/app/components/loading-overlay-indicator";
import { TraceXLogo } from "@/app/components/tracex-logo";
import { ReactComponent as ResourcesSVG } from "@/assets/images/resources.svg";

import { HomePages } from "./home.types";

const UserResources = lazy(() => import("../../user-resources/user-resources"));

export const HomeLayout: React.FC<{ children: React.ReactNode; page?: HomePages }> = ({ children, page }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  return (
    <Flex
      sx={{
        minHeight: "100vh",
        overflow: "hidden",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          opacity: 0.1,
          left: 0,
          right: 0,
          top: 0,
          zIndex: -1,
          bottom: 0,
          background: "url('/assets/loginBackground.svg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          backgroundSize: ["auto", null, null, null, null, "cover"],
        }}
      ></Box>
      <Flex
        sx={{
          width: "90%",
          boxShadow: `0 0 5px var(--theme-ui-colors-shadow)`,
          maxWidth: "760px",
          borderRadius: "16px",
          backdropFilter: "blur(6px)",
          py: 4,
        }}
      >
        <Flex
          sx={{
            flexDirection: "column",
            flex: 1,
            flexBasis: "0px",
            position: "relative",
            justifyContent: "center",
            alignItems: "flex-start",
            borderRightWidth: `1px`,
            borderRightStyle: "solid",
            borderRightColor: alpha("text", 0.3),
            width: "100%",
            px: 4,
          }}
        >
          <Text sx={{ variant: "subheading", color: "text", fontSize: [2], fontWeight: 600, mb: 2 }}>
            {t("Powerful trades at your fingertips")}
          </Text>
          <Box sx={{ pb: 6 }}>
            <TraceXLogo width="90%" />
          </Box>

          <Button
            sx={{
              bg: "transparent",
              color: "text",
              position: "absolute",
              bottom: 0,
              py: 2,
              px: 3,
              boxShadow: "0px 0px 5px var(--theme-ui-colors-text)",
              opacity: 0.6,
              "&:enabled:hover, &:enabled:focus": {
                bg: "transparent",
                opacity: 1,
              },
              display: "flex",
              svg: {
                path: {
                  fill: "text",
                },
              },
            }}
            onClick={() => {
              setShowModal(!showModal);
            }}
          >
            <ResourcesSVG />
            <Box sx={{ ml: 2 }}>{t("Resources")}</Box>
          </Button>
        </Flex>
        <Flex
          sx={{
            px: 4,
            py: 4,
            flex: 1,
            flexBasis: "0px",
            flexDirection: "column",
            alignItems: "center",
            /**For Smooth Transitioning Define max and min height based on two pages */
            maxHeight: page === HomePages.login ? 550 : 400,
            minHeight: page === HomePages.login ? 530 : 400,
            overflow: "hidden",
          }}
        >
          {children}
        </Flex>
      </Flex>

      <Modal
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        title={t("User Resources")}
      >
        <Suspense fallback={<LoadingOverlayIndicator />}>
          <UserResources showHeader={false} />
        </Suspense>
      </Modal>
    </Flex>
  );
};
