import { Flex, getColor, LegacyRadio, LegacySelect, Text, useTheme } from "@powerledger/ui-component-lib";
import { Field, useFormikContext } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { Box, Grid } from "theme-ui";

import { FormFieldLabel } from "@/app/components";
import { AppCleaveTypes, CleaveInput } from "@/app/components/cleave-input";
import { HeaderText } from "@/app/components/page-header";
import { FieldSelectors } from "@/app/hooks";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";
import { RetirementCategory } from "@/app/types/generated/graphql";

import { RetirementFormValues, RetirementType } from "../retire.types";
import { retirementOptions } from "../use-retire";
import { RetirementFormBlockProps, RetirementNotesProps } from "./retirement-form-block.types";

const RetirementNotes = ({ notePrefix }: RetirementNotesProps) => {
  const { theme } = useTheme();
  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: 1,
        mb: 2,
        fontSize: 1,
      }}
    >
      <Trans>
        <Text
          sx={{
            fontWeight: "bold",
            color: "textDark",
          }}
        >
          Notes:
        </Text>
        <ol
          style={{
            margin: 0,
          }}
        >
          <li>
            Your retirement instructions will be sent to the Registry with the following note{" "}
            <b>&quot;{notePrefix}&quot;</b>, which has already been pre-populated below.
            <br />
            If you&apos;d like, you can add any additional retirement notes in the field below to be included on the
            retirement certificate.
          </li>
          <li>
            For a description of <b>Retirement Type</b> and <b>Reasons</b>, please refer to the{" "}
            <a
              href="https://www.mrets.org/retirement-reasons-training/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontWeight: "bold",
                color: getColor(theme, "accentLight"),
              }}
            >
              Registry documentation.
            </a>
          </li>
        </ol>
      </Trans>
    </Flex>
  );
};

export default function RetirementFormBlock({
  selectedRetirementType,
  notePrefix,
}: Readonly<RetirementFormBlockProps>) {
  const { setFieldValue, setFieldTouched, touched, errors, values } = useFormikContext<RetirementFormValues>();
  const { t } = useTranslation();
  return (
    <>
      <HeaderText sx={{ width: 350 }} title={t("Retirement Options")} />
      <Box
        sx={{
          p: 3,
        }}
      >
        <LegacyRadio label={t("Voluntary")} checked={values.category == RetirementCategory.Voluntary} />
        <Box sx={{ mt: 3 }}>
          <Grid
            sx={{
              mt: 3,
              alignItems: "end",
            }}
            columns={[1, 2, 2, 3]}
          >
            <FormFieldLabel hasErrorMessage={false} small label={t("Retirement Type *")}>
              <LegacySelect
                options={retirementOptions}
                menuPlacement="top"
                onChange={(option) => {
                  setFieldValue("type", option?.value);
                  setFieldValue("reason", null);
                  if (option?.value === RetirementType.BENEFICIAL_OWNERSHIP) {
                    setFieldValue("compliancePeriod", null);
                  }
                }}
                translation={getSelectTranslation(t)}
                value={retirementOptions.find((opt) => opt.value === values.type)}
              />
            </FormFieldLabel>
            {selectedRetirementType?.choices?.map((retirementSubOption) => {
              return (
                <FormFieldLabel
                  key={retirementSubOption.name}
                  hasErrorMessage={false}
                  small
                  label={t(retirementSubOption.name + "*")}
                  sx={{
                    ...(touched[retirementSubOption.key as keyof RetirementFormValues] &&
                    errors[retirementSubOption.key as keyof RetirementFormValues]
                      ? FieldSelectors["select"]
                      : {}),
                  }}
                >
                  <LegacySelect
                    menuPlacement="top"
                    options={retirementSubOption.options}
                    onChange={(option) => {
                      setFieldValue(retirementSubOption.key, option?.value);
                    }}
                    value={retirementSubOption.options.find(
                      (opt) => opt.value === values[retirementSubOption.key as keyof typeof values],
                    )}
                    onBlur={() => setFieldTouched(retirementSubOption.key, true, true)}
                    translation={getSelectTranslation(t)}
                  />
                </FormFieldLabel>
              );
            })}
          </Grid>
          <FormFieldLabel
            hasErrorMessage={false}
            small
            sx={{
              mt: 3,
            }}
          >
            <RetirementNotes notePrefix={notePrefix} />
            <Field
              name="notes"
              shouldDebounce
              sx={{
                input: { fontSize: 1, px: 2 },
              }}
              component={CleaveInput}
              // by default space ' ' is a delimiter hence it does not allow entering
              // text after the prefix, used `, Its barely used and can prohibit the use of ` in notes
              cleaveOptions={{ prefix: `${notePrefix}: `, delimiter: "`" }}
              type={AppCleaveTypes.Others}
              variant="input"
            />
          </FormFieldLabel>
        </Box>
      </Box>
    </>
  );
}
