import { IconLoading, Table } from "@powerledger/ui-component-lib";
import { Field, Form, FormikErrors, FormikTouched, useFormikContext } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";
import { Button, Flex, Paragraph, Text } from "theme-ui";

import { AppCleaveTypes, CleaveInput } from "@/app/components/cleave-input";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import { ExportRecsFormValues } from "../../export/export-recs.types";
import { RecHoldingsTableData } from "../../rec-holdings";
import { RetirementFormValues } from "../../retire/retire.types";
import { getCommonRecTableData } from "../rec-table-column";
import { RecActionTableProps } from "./rec-action-table.types";

type InputType = ExportRecsFormValues | RetirementFormValues;

function QuantityInput({ index = 0 }: Readonly<{ index?: number }>) {
  const { errors, touched } = useFormikContext<InputType>();
  const formikTouched = touched.values as FormikTouched<InputType["values"]>;
  const displayValue =
    formikTouched?.[index]?.actionVolume &&
    (errors.values?.[index] as FormikErrors<InputType["values"]["0"]>)?.actionVolume;

  return (
    <>
      <Field
        name={`values.${index}.actionVolume`}
        sx={{
          input: { fontSize: 3 },
        }}
        component={CleaveInput}
        type={AppCleaveTypes.Quantity}
        variant="input"
      />
      <Paragraph
        sx={{
          fontSize: 0,
          color: "error.500",
        }}
      >
        {displayValue}
      </Paragraph>
    </>
  );
}

export default function RecActionTable({
  recOptions,
  tableData,
  loading,
  onGoBack,
  additionalContent,
}: Readonly<RecActionTableProps>) {
  const { handleSubmit, isValid, isSubmitting } = useFormikContext();
  const { t } = useTranslation();

  const tableColumns: Column<RecHoldingsTableData>[] = useMemo(
    () => [
      ...getCommonRecTableData(t, recOptions),
      {
        Header: "Quantity",
        id: "quantity",
        Cell: ({ row }: { row: Row<RecHoldingsTableData> }) => <QuantityInput index={row.index} />,
      },
    ],
    [recOptions, t],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Flex
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
          py: 3,
        }}
      >
        <Button type="submit" variant="primary" disabled={!isValid || isSubmitting}>
          {t("Proceed")}
        </Button>
        {isSubmitting && <IconLoading />}
      </Flex>

      <Table
        disableCheckbox={(rows) => !rows.length || rows.some((row) => row.original.availableBalance <= 0)}
        columns={tableColumns}
        noHover
        pagination={false}
        dataSource={tableData}
        loading={loading}
        showScrollArrows
        translation={getTableTranslation(t)}
      />
      <Flex
        sx={{
          alignItems: "center",
          mt: 2,
          justifyContent: "flex-end",
        }}
      >
        <Text
          sx={{
            color: "textDarker",
            fontSize: 0,
            fontFamily: "MintGroteskV08",
          }}
        >
          {t("Can't see the RECS you're after?")}
        </Text>
        <Button
          variant="text"
          sx={{ ml: 3, color: "accentLight", fontSize: 1, textDecoration: "underline" }}
          onClick={onGoBack}
        >
          {t("View REC holdings")}
        </Button>
      </Flex>
      {additionalContent}
    </Form>
  );
}
