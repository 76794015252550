import { IconLoading, LegacyCheckbox, LegacySelect } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Flex, Grid } from "theme-ui";
import * as Yup from "yup";

import { Form } from "@/app/components/form/form";
import { FormFieldLabel } from "@/app/components/form/form-field-label";
import { FormInput } from "@/app/components/form/form-input";
import { FieldSelectors } from "@/app/hooks/use-formik-input-error";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";
import { checkIsValidEmail } from "@/app/pages/public/home/signup/use-signup";
import { TradingAccountUserType } from "@/app/types/generated/graphql";

import { AddEditNewUserFormProps } from "./add-edit-new-user-modal.types";

const typeOptions = Object.values(TradingAccountUserType).map((type) => ({ label: type, value: type }));

export const AddEditNewUserForm: React.FC<AddEditNewUserFormProps> = ({ onSubmit, values: passedValues, loading }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialTouched={passedValues && { email: true, position: true, tradingAccountUserType: true }}
      initialValues={{
        email: "",
        tradingAccountUserType: TradingAccountUserType["User"],
        ...passedValues,
        tnc: false,
      }}
      onSubmit={async (...args) => {
        const { tnc, ...values } = args[0];
        await onSubmit(values, args[1]);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required(t("Email is required"))
          .test("validEmail", t("Please enter a valid email address"), function (email) {
            return checkIsValidEmail(email || "");
          }),
        position: Yup.string(),
        tradingAccountUserType: Yup.string().required(t("Type is required")),
        ...(!passedValues ? { tnc: Yup.boolean().oneOf([true]) } : {}),
      })}
      validateOnMount
    >
      {({ touched, isValid, isSubmitting, values, errors, setFieldValue }) => (
        <Form>
          <Form.Item key="add-new-user-f-name" sx={{ justifyContent: "end" }}>
            <FormFieldLabel hasErrorMessage={false} small name="email" label={t("Email *")}>
              <FormInput debounce hasErrorIndicator={false} name="email" disabled={!!passedValues?.email} />
            </FormFieldLabel>
          </Form.Item>
          <Grid gap={3} columns={2} sx={{ alignItems: "baseline" }}>
            <Form.Item key="add-new-user-position" sx={{ justifyContent: "end" }}>
              <FormFieldLabel hasErrorMessage={false} small name="position" label={t("Position")}>
                <FormInput debounce hasErrorIndicator={false} name="position" disabled={!!passedValues?.position} />
              </FormFieldLabel>
            </Form.Item>
            <Form.Item key="add-new-user-type" sx={{ justifyContent: "end" }}>
              <FormFieldLabel
                hasErrorMessage={false}
                small
                name="tradingAccountUserType"
                sx={
                  touched["tradingAccountUserType"] && errors["tradingAccountUserType"] ? FieldSelectors["select"] : {}
                }
                label={t("User type *")}
              >
                <LegacySelect
                  options={typeOptions}
                  translation={getSelectTranslation(t)}
                  menuPlacement="top"
                  onChange={(opt) => setFieldValue("tradingAccountUserType", opt?.value)}
                  value={typeOptions.find((val) => val.value === values.tradingAccountUserType)}
                />
              </FormFieldLabel>
            </Form.Item>
          </Grid>
          {!passedValues && (
            <LegacyCheckbox
              label={t(
                "By adding new users, the Participant represents and warrants that all information provided about a user is complete and accurate. The Participant acknowledges and agrees that once a Representative is created, they become an Authorized Representative and will be given access to the Participant’s TraceX Marketplace account. The Participant is fully responsible for any acts or omissions by its Authorized Representatives.",
              )}
              checked={values.tnc}
              onChange={(e) => {
                setFieldValue("tnc", e.target.checked);
              }}
            />
          )}
          <Flex sx={{ mt: 2, justifyContent: "flex-end", gap: 2 }}>
            <Button variant="primary" disabled={!isValid || isSubmitting || loading} type="submit">
              {t("Submit")}
            </Button>
            {(isSubmitting || loading) && <IconLoading size="small" />}
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
