import { Box, IconLoading } from "@powerledger/ui-component-lib";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FundingSource, LinkedPaymentMethod } from "@/app/types/generated/graphql";

import { FundingSourceLinkingProps } from "./funding-source-linking.types";
import { LinkedFundingSource } from "./linked-funding-source";
import {
  FundingSourceChildTypes,
  FundingSourceComponentType,
} from "./linked-funding-source/linked-funding-source.types";
import { PlaidLink } from "./plaid-link";

export const getFundingSourceType = (fundingSource: FundingSourceChildTypes): FundingSourceComponentType => {
  return fundingSource.__typename as FundingSourceComponentType;
};

export const getLinkedFundingSourceElements = (linkedPaymentMethods: LinkedPaymentMethod[]): JSX.Element[] => {
  return linkedPaymentMethods.reduce((result, linkedPaymentMethod) => {
    if (linkedPaymentMethod.fundingSource !== null) {
      return result.concat(
        <LinkedFundingSource
          key={linkedPaymentMethod.id}
          typeName={getFundingSourceType(linkedPaymentMethod.fundingSource as FundingSource)}
          linkedPaymentMethod={linkedPaymentMethod}
        ></LinkedFundingSource>,
      );
    }

    return result;
  }, [] as JSX.Element[]);
};

export const FundingSourceLinking: FC<FundingSourceLinkingProps> = ({
  tipDisplayType,
  showTitle,
  account,
  accountLoading,
}) => {
  const { t } = useTranslation();

  const linkedFundingSources = useMemo(
    () => (accountLoading ? [] : getLinkedFundingSourceElements(account.linkedPaymentMethods)),
    [account, accountLoading],
  );

  return (
    <Box>
      {showTitle && <Box sx={{ fontSize: 4, mb: 2 }}>{t("Linked Bank Account")}</Box>}
      {accountLoading ? (
        <IconLoading />
      ) : linkedFundingSources.length > 0 ? (
        linkedFundingSources
      ) : (
        <PlaidLink displayMessage={tipDisplayType === "plain"} loading={accountLoading} account={account}></PlaidLink>
      )}
    </Box>
  );
};
