import { Flex } from "@powerledger/ui-component-lib";
import { Formik, FormikProps } from "formik";
import { ReactElement, Ref, Suspense } from "react";

import { OnboardingSider } from "../onboarding-sider/onboarding-sider";

export const OnboardingFormWrapper = ({
  currentStep,
  currentStage,
  initialFormValues,
  currentValidationSchema,
  getCurrentTouched,
  formRef,
  currentStageComponent,
}: {
  currentStep: number;
  currentStage: number;
  initialFormValues: any;
  currentValidationSchema: any;
  getCurrentTouched: any;
  formRef: Ref<FormikProps<any>>;
  currentStageComponent: ReactElement;
}) => {
  return (
    <Flex
      sx={{
        width: ["66%", "75%"],
      }}
    >
      <Formik
        key={`signup-stepper-step-${currentStep}-stage-${currentStage}`}
        initialValues={initialFormValues}
        validationSchema={currentValidationSchema}
        initialTouched={getCurrentTouched}
        onSubmit={() => {}}
        validateOnMount
        enableReinitialize
        innerRef={formRef}
        validateOnBlur={false}
      >
        <Suspense fallback={<OnboardingSider steps={[]} />}>{currentStageComponent}</Suspense>
      </Formik>
    </Flex>
  );
};
