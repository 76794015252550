import { CleaveOptions } from "cleave.js/options";

export enum AppCleaveTypes {
  Amount = "Amount",
  Quantity = "Quantity",
  EIN = "EIN",
  Others = "Others",
}

export const getCleaveOptions = (type: AppCleaveTypes, additionalOptions?: CleaveOptions) => {
  let cleaveOptions: CleaveOptions = {};
  if (type === AppCleaveTypes.Amount) {
    cleaveOptions = {
      numeral: true,
      numeralDecimalScale: 2,
      numeralPositiveOnly: true,
    };
  } else if (type === AppCleaveTypes.Quantity) {
    cleaveOptions = {
      numeral: true,
      numeralPositiveOnly: true,
      numericOnly: true,
      numeralDecimalMark: "",
      numeralDecimalScale: 0,
    };
  } else if (type === AppCleaveTypes.EIN) {
    cleaveOptions = { blocks: [2, 7], delimiter: "-", numericOnly: true };
  }
  return {
    ...cleaveOptions,
    ...additionalOptions,
  };
};
