import { Box } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { HeaderText } from "@/app/components/page-header";

import { ReceivedCounterOffersProps } from "./received-counter-offers.types";

export const ReceivedCounterOffersView = ({ showAllAttributes, setShowAllAttributes }: ReceivedCounterOffersProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const childPath = location.pathname.split("counter/")[1] as keyof typeof titles;

  const titles = {
    "received-offers": "Received Counter Offers",
    "my-offers": "My Counter Offers",
  };

  return (
    <Box as="main" sx={{ m: 4 }}>
      <HeaderText title={titles[childPath]} sx={{ width: "fit-content" }} />

      {/*
      -TODO List-
      RefreshIconButton
      Table with see counter offer action
      Navigate to see counter offer list with expandedData (subRows)

      */}
    </Box>
  );
};
