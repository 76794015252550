import { ContactType, useAccountQuery } from "@/app/types/generated/graphql";

export const useCompanyDetailsStatusIcon = () => {
  const { data, loading } = useAccountQuery();
  const company = data?.account?.company;
  if (loading) {
    return null;
  }
  if (!company || !company?.contacts?.find((contact) => contact.type === ContactType.Controller)) {
    return "Error";
  } else if (data?.account?.linkedPaymentMethods?.length) return "Lock";
  else return null;
};
