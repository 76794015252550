import { find, flatten, map } from "lodash";

import { OrderAttributes, OrderPosition, RecAssetAttributes, RecOrderAttributes } from "../types/generated/graphql";
import { FormattedOption, RECOrderAttributeOptions } from "./format-rec-options";
import { getVintageHalfText } from "./get-vintage-half-text";
import i18n from "./i18n";
import { getTextToShowOnValue } from "./order-helpers";

export type CertificationAndEliglibilityType = {
  label: string;
  value: string[];
};

export const CRS = "CRS";
export const CERTIFICATIONS = "certifications";
export const ELIGIBILITIES = "eligibilities";
export type ReturnTypeFormatAttributes = {
  certifications: string[];
  eligibilities: string[];
  fuelSources: string[];
  location: string;
  locations: string[];
  project: string;
  projects: string[];
  vintage: string;
  vintages: string[];
  certificationsAndEligibilities: CertificationAndEliglibilityType[];
};

/** OrderAttributes have multiple values but AssetAttributes dont */
const isOrderAttributes = (obj: any): obj is OrderAttributes =>
  Object.hasOwn(obj, "locations") || Object.hasOwn(obj, "projects") || Object.hasOwn(obj, "vintages");

export const noAssetAttributesFoundErrorMessage = "No asset attributes found!";

export const formatAttributes = ({
  attributes,
  filteredEligibility,
  position,
  options,
}: {
  attributes: RecOrderAttributes | RecAssetAttributes;
  filteredEligibility?: string;
  position?: OrderPosition;
  options?: RECOrderAttributeOptions;
}): ReturnTypeFormatAttributes => {
  if (!options) throw new Error(i18n.t(noAssetAttributesFoundErrorMessage));
  const certifications: string[] =
    map(attributes.certifications, (e) => find(options.certificationOptions, { value: e })?.label || "") || [];
  const defaultValue = position === OrderPosition.Bid ? "Any" : "-";
  const eligibilities: string[] =
    map(
      attributes.eligibilities?.slice().sort((x?: string) => (x === filteredEligibility ? -1 : 1)),
      (e) => find(options.eligibilityOptions, { value: e })?.label || "",
    ) || [];

  /** Location for RecAttributes (holdings)
   *  Locations for OrderAttributes (orders)
   */
  const [location, locations] = (() => {
    const locs: string[] = [];
    const loc: string[] = [];
    const innerOptions: FormattedOption[] = [];
    // Push the nested options outside (Only One level of nesting can occur for now)
    for (const option of options.locationOptions) {
      if (option.options) innerOptions.push(option.options);
    }

    //Flatten the option
    const flattenOption = flatten(innerOptions);

    if (isOrderAttributes(attributes)) {
      // Create the object for Values for O(1) retrieval
      const values: Record<string, boolean> = (attributes.locations || [])?.reduce(
        (acc, val) => ({ ...acc, [val]: true }),
        {},
      );
      for (const option of flattenOption) {
        //Check if pption is present in our Retrieval Object
        const opt = values[option.value];
        if (opt) {
          locs.push(option.label);
        }
      }
    } else {
      for (const option of flattenOption) {
        //Check if option value equals to provided location
        const opt = option.value === attributes.location;
        if (opt) {
          loc.push(option.label);
        }
      }
    }
    return [loc, locs];
  })();

  const projects = isOrderAttributes(attributes)
    ? options.projectOptions?.filter((p) => attributes.projects?.includes(p.value))?.map((l) => l?.label)
    : [];

  const vintage = (() => {
    const yearValue = options.vintageOptions.find((v) => v.value === attributes.vintage?.toString())?.label ?? "";
    return yearValue + getVintageHalfText(attributes.vintageHalf, yearValue ? "-" : "");
  })();

  const vintages = (() => {
    const values = isOrderAttributes(attributes) ? attributes.vintages ?? [] : [];
    return getTextToShowOnValue(values);
  })();

  const fuelSources: string[] =
    map(
      attributes.fuelSources?.slice().sort((x?: string) => (x === filteredEligibility ? -1 : 1)),
      (e) => find(options.fuelSourceOptions, { value: e })?.label ?? "",
    ) || [];

  return {
    vintage,
    vintages,
    eligibilities,
    location: location?.[0] ?? defaultValue,
    locations: locations?.length ? locations : [defaultValue],
    fuelSources: fuelSources?.length ? fuelSources : [],
    project: find(options.projectOptions, (p) => p.value === attributes.project)?.label ?? defaultValue,
    projects: projects?.length ? projects : [defaultValue],
    certifications,
    certificationsAndEligibilities: [
      {
        label: CERTIFICATIONS,
        value: certifications,
      },
      {
        label: ELIGIBILITIES,
        value: eligibilities,
      },
    ],
  };
};
