import { Box, Flex, IconLoading } from "@powerledger/ui-component-lib";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getCountry, getRegion } from "@/app/lib/get-country-region";
import { businessTypes } from "@/app/pages/public/signup/applicant-details-step/use-applicant-details-step";

import { CompanyInformationProps } from "./company-information.types";

export const CompanyInformation: FC<CompanyInformationProps> = ({ company, loading }) => {
  const { t } = useTranslation();

  const businessClassificationName = useMemo(() => {
    return company?.industryClassification?.businessClassificationName;
  }, [company?.industryClassification?.businessClassificationName]);

  const industryClassificationName = useMemo(() => {
    return company?.industryClassification?.name;
  }, [company?.industryClassification?.name]);

  const businessType = useMemo(() => {
    return businessTypes.find((bt) => {
      return bt.value === company?.businessType;
    });
  }, [company?.businessType]);

  return (
    <Flex>
      <Box sx={{ color: "textDarker", fontSize: 1, flex: 2 }}>{t("Applicant Details")}</Box>
      <Box sx={{ color: "textDarker", fontSize: 1, flex: 2 }}>
        {businessType?.label && businessClassificationName && industryClassificationName && (
          <Box>
            {t("{{businessLabel}} | {{businessClassificationName}} | {{industryClassificationName}}", {
              businessLabel: businessType?.label,
              businessClassificationName,
              industryClassificationName,
            })}
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          {loading && <IconLoading />}
          {company?.businessName && <Box>{t(company.businessName)}</Box>}
          {company?.physicalAddress?.line1 && <Box>{t(company.physicalAddress.line1)}</Box>}
          {company?.physicalAddress?.line2 && <Box>{t(company.physicalAddress.line2)}</Box>}
          <Box>
            {t("{{companyState}} {{companyPostalCode}}", {
              companyState: getRegion(
                company?.physicalAddress?.countryCode || "",
                company?.physicalAddress?.state || "",
              ),
              companyPostalCode: company?.physicalAddress?.postalCode,
            })}
          </Box>
          {company?.physicalAddress?.countryCode && (
            <Box>{t(getCountry(company.physicalAddress.countryCode) as string)}</Box>
          )}
        </Box>
      </Box>
    </Flex>
  );
};
