import { IconCircleTick, IconError, IconPending, IconProps } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { FundingSourceStatus } from "@/app/types/generated/graphql";

export const fundingSourceStatusIconsMap: Record<FundingSourceStatus, FC<IconProps>> = {
  ADDED: (props) => <IconPending color="warning.500" {...props} />,
  ERROR: (props) => <IconError color="error.500" {...props} />,
  REMOVED: (props) => <IconError color="error.500" {...props} />,
  VERIFIED: (props) => <IconCircleTick color="primary.500" {...props} />,
  VERIFYING: (props) => <IconPending color="warning.500" {...props} />,
  WAITING_FOR_CUSTOMER_ACTION: (props) => <IconPending color="warning.500" {...props} />,
};

export const fundingSourceStatusLabelMap: Record<FundingSourceStatus, string> = {
  ADDED: "Added",
  ERROR: "Error",
  REMOVED: "",
  VERIFIED: "Verified",
  VERIFYING: "Payment Provider Verifying",
  WAITING_FOR_CUSTOMER_ACTION: "Awaiting Your Action",
};

export const VALID_FUNDING_SOURCE_UNLINK_STATUSES = [
  FundingSourceStatus.Added,
  FundingSourceStatus.Error,
  FundingSourceStatus.Verified,
  FundingSourceStatus.WaitingForCustomerAction,
];
