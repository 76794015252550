import { FC } from "react";

import { FundingSourceComponentProps } from "../../linked-funding-source.types";
import { BankAccountView } from "./bank-account-view";
import { useBankAccount } from "./use-bank-account";

export const BankAccountV2: FC<FundingSourceComponentProps> = ({ linkedPaymentMethod }) => {
  const {
    linkedPaymentMethod: inLinkedPaymentMethod,
    loading,
    showUnlinkButton,
    showUnlinkModal,
    setShowUnlinkModal,
    removeFundingSource,
    unlinkLoading,
    plaidLinkError,
    disablePlaidModal,
    openPlaidModal,
    fundingSourceStatusLabel,
    showCompleteLinkButton,
    bankAccountErrorMessage,
  } = useBankAccount({ linkedPaymentMethod });

  return (
    <BankAccountView
      linkedPaymentMethod={inLinkedPaymentMethod}
      loading={loading}
      showUnlinkButton={showUnlinkButton}
      showUnlinkModal={showUnlinkModal}
      setShowUnlinkModal={setShowUnlinkModal}
      removeFundingSource={removeFundingSource}
      unlinkLoading={unlinkLoading}
      plaidLinkError={plaidLinkError}
      disablePlaidModal={disablePlaidModal}
      openPlaidModal={openPlaidModal}
      fundingSourceStatusLabel={fundingSourceStatusLabel}
      showCompleteLinkButton={showCompleteLinkButton}
      bankAccountErrorMessage={bankAccountErrorMessage}
    />
  );
};
