import { useOktaAuth } from "@okta/okta-react";

import { useOktaRoles } from "@/app/hooks/use-okta-roles";
import { UserRoles } from "@/app/lib/role-based-access-control";
import { UserResourcesProps } from "@/app/pages/user-resources/user-resources.types";

export const useUserResources = (): UserResourcesProps => {
  const { authState } = useOktaAuth();
  const { userRoles } = useOktaRoles();

  return {
    isAuthenticated: authState?.isAuthenticated,
    isAdmin: userRoles.includes(UserRoles.RoleAdmin),
  };
};
