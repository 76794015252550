import { Flex, IconCircleTick, IconWarning, useTheme } from "@powerledger/ui-component-lib";
import Cleave from "cleave.js/react";

import { AccountNumberInputProps } from "./account-number-input.types";

const cleaveOption = (registryCode = "") => {
  switch (registryCode) {
    case "MRETS":
      return {
        prefix: "MM",
        // noImmediatePrefix:false will strangely not take in field.value in cleave
        noImmediatePrefix: true,
        numericOnly: true,
      };
    default:
      return { blocks: [8, 4, 4, 4, 12], delimiter: "-" };
  }
};

export const AccountNumberInput: React.FC<AccountNumberInputProps> = ({ field, form, registryCode }) => {
  const { theme } = useTheme();
  return (
    <Flex
      sx={{
        alignItems: "center",
        position: "relative",
        width: "100%",
        input: {
          ...theme.forms?.input,
          fontSize: 4,
          fontFamily: "IBMPlexMono",
          letterSpacing: 4,
          px: 3,
          py: 4,
          color: form.status?.error ? "yellow" : form.status?.success ? "positive" : "inherit",
          position: "relative",
        },
      }}
    >
      <Cleave
        options={cleaveOption(registryCode)}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        // Default value MM as due to noImmediatePrefix:true we wont have a prefix when the input is not autofocused
        // Adding autoFocus will give eslint error
        value={field.value || "MM"}
      />
      <Flex
        sx={{
          position: "absolute",
          right: 2,
          color: "textDarker",
          fontWeight: "light",
        }}
      >
        {form.status?.error && <IconWarning color="yellow" size={4} noSvgPositioning />}
        {form.status?.success && <IconCircleTick color="positive" size={5} noSvgPositioning sx={{ mt: 2 }} />}
      </Flex>
    </Flex>
  );
};
