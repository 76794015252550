import { Box } from "@powerledger/ui-component-lib";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications";

import { useOnboardingContext } from "../onboarding/context";
import { StepType } from "../onboarding/onboarding.types";
import { Step } from "./step";

export const SignupStepper: FC<{
  steps: StepType[];
}> = ({ steps }) => {
  const { t } = useTranslation();
  const { goTo, saveCurrentStage, addToTouched, currentStage, currentStep } = useOnboardingContext();
  const [loading, setLoading] = useState(false);
  return (
    <Box sx={{ position: "relative" }}>
      {steps.map((step, index) => (
        <Box key={step.title}>
          <Step
            step={steps[index]}
            loading={loading}
            stepIndex={index}
            goToStage={async (stageIndex) => {
              try {
                setLoading(true);
                await saveCurrentStage();
                addToTouched(steps[currentStep].stages[currentStage].name);
                goTo([index, stageIndex]);
              } catch (error: unknown) {
                if (error instanceof Error) {
                  notifications.error({
                    description: t(error?.message),
                  });
                }
              } finally {
                setLoading(false);
              }
            }}
          />
        </Box>
      ))}
    </Box>
  );
};
