import { useTranslation } from "react-i18next";
import { Flex } from "theme-ui";

import { LocalDatePicker } from "@/app/components";
import { StackedFilterState } from "@/app/components/page-header/filter/filter.types";

export default function DateRangeFilter({
  filterValues,
  setFilterValues,
}: Readonly<{
  setFilterValues: React.Dispatch<React.SetStateAction<StackedFilterState>>;
  filterValues: StackedFilterState;
}>) {
  const { t } = useTranslation();
  return (
    <LocalDatePicker
      selectsRange
      open
      maxDate={new Date()}
      endDate={filterValues.dateRange[1] ? new Date(filterValues.dateRange?.[1]?.value) : null}
      startDate={filterValues.dateRange[0] ? new Date(filterValues.dateRange?.[0]?.value) : null}
      customInput={
        <Flex sx={{ mt: -3, fontSize: 1, textAlign: "center" }}>
          {t("Select the start date first and then the end date")}
        </Flex>
      }
      onChange={(dates) => {
        const datesToSet = (Array.isArray(dates) ? dates : [dates]).filter(Boolean);
        if (datesToSet.length > 1) {
          const [start, end] = dates;
          setFilterValues((prev) => ({
            ...prev,
            dateRange: [
              { key: new Date(), value: start },
              { key: new Date(), value: end },
            ],
          }));
        } else {
          setFilterValues((prev) => ({
            ...prev,
            dateRange: [{ key: new Date(), value: dates }],
          }));
        }
      }}
    />
  );
}
