import { Button, Flex, Modal, Text } from "@powerledger/ui-component-lib";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { NoBeneficialOwnersModalProps } from "./beneficial-owners.types";

export const NoBeneficialOwnersModal: FC<NoBeneficialOwnersModalProps> = ({ visible, closeModal, onSuccess }) => {
  const { t } = useTranslation();

  const confirm = useCallback(() => {
    if (onSuccess) onSuccess();
    closeModal();
  }, [onSuccess, closeModal]);

  return (
    <Modal visible={visible} maskClosable onCancel={closeModal} hideCloseButton>
      <Text sx={{ fontSize: 1, color: "textDark" }}>
        {t(
          "A beneficial owner is a natural person who, directly or indirectly owns 25% or more of a company or has significant control or management responsibility. If there are no natural persons who owns more than 25% or have significant control, then no information is required. A beneficial owner cannot be another company, corporate entity or nominee owner.",
        )}
      </Text>
      <br />
      <br />
      <Text sx={{ fontWeight: "bold" }}>
        {t("For an account to be established successfully in TraceX, all beneficial owners must be added here.")}
      </Text>
      <br />
      <br />
      <Text>{t("Are you sure you want to proceed without adding a beneficial owner? ")}</Text>
      <Flex sx={{ justifyContent: "flex-end", mt: 4 }}>
        <Button variant="error" onClick={closeModal} sx={{ mr: 3, minWidth: 80 }}>
          {t("Cancel")}
        </Button>
        <Button variant="primary" onClick={confirm}>
          {t("Proceed")}
        </Button>
      </Flex>
    </Modal>
  );
};
