import { IconError, IconPending } from "@powerledger/ui-component-lib";

import { checkNestedApprovedStatus, hasMissingDetails } from "@/app/lib/account-service-helpers";
import { Account, AccountStatus, useAccountQuery } from "@/app/types/generated/graphql";

export function usePaymentServicesStatusIcon() {
  const { data: accountData, loading: loadingAccountQuery } = useAccountQuery();

  if (loadingAccountQuery) return null;
  if (accountData?.account?.status === AccountStatus.Active) {
    if (!checkNestedApprovedStatus(accountData?.account as Account)) {
      return IconPending;
    }
    return null;
  } else if (hasMissingDetails(accountData?.account as Account)) {
    return IconError;
  } else return IconPending;

  return null;
}
