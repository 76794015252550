import { Box, IconBank, IconInfo, IconLoading, SquareBlock, Text } from "@powerledger/ui-component-lib";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { usePlaidLinkModal } from "@/app/hooks";
import {
  Account,
  AddFundingSourceResponse,
  PaymentMethodCode,
  useFundingSourceInitialisingTokenQuery,
} from "@/app/types/generated/graphql";

export const PlaidLink: FC<{
  loading?: boolean;
  displayMessage?: boolean;
  onLinkAccount?: (response: AddFundingSourceResponse) => void;
  account: Account;
}> = ({ onLinkAccount, loading, displayMessage = false, account }) => {
  const { t } = useTranslation();

  const { data: initialisingTokenData, loading: loadingInitialisingTokenData } =
    useFundingSourceInitialisingTokenQuery();
  const plaidPaymentMethod = useMemo(() => {
    return account.linkedPaymentMethods.find(
      (paymentMethod) => paymentMethod.paymentMethod.code === PaymentMethodCode.DwollaPlaid,
    );
  }, [account]);

  const initialisingToken = useMemo(
    () =>
      initialisingTokenData?.account?.linkedPaymentMethods?.find(
        (paymentMethod) => paymentMethod.id === plaidPaymentMethod?.id,
      )?.initialisingToken,
    [initialisingTokenData?.account?.linkedPaymentMethods, plaidPaymentMethod],
  );

  const { plaidLinkError, plaidLinkLoading, openPlaidModal } = usePlaidLinkModal({
    onLinkAccountSuccess: onLinkAccount,
    initialisingToken,
  });

  const isDisabled = useMemo(() => {
    return loading || plaidLinkLoading || loadingInitialisingTokenData;
  }, [loading, plaidLinkLoading, loadingInitialisingTokenData]);

  const openLinkModal = useCallback(async () => {
    if (isDisabled) return;
    await openPlaidModal();
  }, [openPlaidModal, isDisabled]);

  return (
    <Box>
      {displayMessage && (
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            mb: 3,
            py: 1,
            pr: 3,
            bg: "shadow",
            borderRadius: 4,
          }}
        >
          <IconInfo size={5} color={"text"} />
          <Text sx={{ fontSize: 1 }}>
            {t("Please have the phone that is linked to the bank account handy for multi-factor authentication")}
          </Text>
        </Box>
      )}
      <SquareBlock
        isEmpty
        sx={{ width: ["100%", null, null, "50%"], ml: -2, px: 2, maxWidth: 300 }}
        onClick={openLinkModal}
      >
        <IconBank size={128} stroke={!isDisabled ? "primary.500" : "textDark"} color={"transparent"} />
        <Box sx={{ pb: 3 }} data-testid={"plaid-link--add-new"}>
          {isDisabled ? <IconLoading /> : t("Add New")}
        </Box>
      </SquareBlock>
      {plaidLinkError && <Box sx={{ color: "negative", fontSize: 0, mt: 3 }}>{t(plaidLinkError.message)}</Box>}
    </Box>
  );
};
