import { LegacyCheckbox } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "theme-ui";

import { PageHeader } from "@/app/components/page-header/page-header";
import { OrderPosition } from "@/app/types/generated/graphql";

import { MarketTable } from "./components/market-table";
import { MarketViewProps } from "./market.types";

export const MarketView: FC<MarketViewProps> = ({
  reloadMarketOrders,
  fetchData,
  pageInfo,
  recOptions,
  loading,
  applyFilters,
  assetCounts,
  tableData,
  hideSellButton,
  typeFilter,
  handleChangeTypeFilter,
  hideBuyButton,
  showAllAttributes,
  setShowAllAttributes,
  accountData,
}) => {
  const { t } = useTranslation();
  return (
    <Box as="main" sx={{ m: 4 }}>
      <PageHeader
        title={t("Current Market")}
        filterProps={{
          applyFilters,
          shouldSelectVintage: true,
          hideStatusFilter: true,
        }}
        actions={{
          buyAction: {
            hide: false,
          },
        }}
      />
      <Flex
        sx={{
          fontWeight: 700,
          fontSize: 2,
          gap: 2,
          color: "textDark",
          mt: 2,
        }}
      >
        {!hideSellButton && <Box>{t("RECs Listed as Bids: {{bidCount}}", { bidCount: assetCounts?.bid })}</Box>}
        {!hideBuyButton && !hideSellButton && <Box>|</Box>}
        {!hideBuyButton && <Box>{t("RECs Listed as Asks: {{askCount}}", { askCount: assetCounts?.ask })}</Box>}
      </Flex>
      <Flex
        sx={{
          fontSize: 1,
          fontWeight: 700,
          gap: 3,
          mt: 2,
        }}
      >
        <LegacyCheckbox
          rounded
          type="radio"
          role="radiogroup"
          sx={{
            color: "textDarker",
          }}
          checked={typeFilter === OrderPosition.Ask}
          label={t("Show Ask")}
          onChange={() => handleChangeTypeFilter(OrderPosition.Ask)}
        />
        <LegacyCheckbox
          type="radio"
          rounded
          role="radiogroup"
          sx={{
            color: "textDarker",
          }}
          checked={typeFilter === OrderPosition.Bid}
          label={t("Show Bid")}
          onChange={() => {
            handleChangeTypeFilter(OrderPosition.Bid);
          }}
        />
        <LegacyCheckbox
          checked={!typeFilter}
          type="radio"
          rounded
          role="radiogroup"
          sx={{
            color: "textDarker",
          }}
          label={t("Show Both Ask and Bid")}
          onChange={() => handleChangeTypeFilter(null)}
        />
        <LegacyCheckbox
          checked={showAllAttributes}
          sx={{
            color: "textDarker",
          }}
          label={t("Show All Attributes")}
          onChange={() => setShowAllAttributes((prev) => !prev)}
        />
      </Flex>

      <MarketTable
        recOptions={recOptions}
        reloadMarketOrders={reloadMarketOrders}
        fetchData={fetchData}
        pageInfo={pageInfo}
        tableData={tableData}
        loading={loading}
        showAllAttributes={showAllAttributes}
        accountData={accountData}
      />
    </Box>
  );
};
