import { Box, Button, Flex, Heading, ThemeUIStyleObject } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SellFormValues } from "../../pages/sell-order/sell-order.types";
import { SellOrderSummaryTable } from "./sell-order-summary-table";

export const Confirmation = ({
  text = "",
  data,
  action,
  goBack,
  sx,
}: {
  data?: {
    fee: number;
    failedOrders: SellFormValues["values"];
  };
  sx?: ThemeUIStyleObject;
  text?: string;
  action: { content: React.ReactNode; onClick: () => void };
  goBack?: () => void;
}) => {
  const { t } = useTranslation();
  const renderFailedOrders = useMemo(
    () =>
      !!data?.failedOrders?.length && (
        <>
          <Heading sx={{ variant: "texts.tracexDefaultHeading" }}>{t("Failed Orders")}</Heading>
          <SellOrderSummaryTable
            data={{
              commonPrice: 0,
              commonVolume: 0,
              fee: data?.fee,
              values: data?.failedOrders,
            }}
          />
        </>
      ),
    [data, t],
  );
  return (
    <Box as="main" sx={sx}>
      {!data?.failedOrders?.length && <Box sx={{ fontSize: 3, color: "text", fontWeight: "light" }}>{text}</Box>}
      {renderFailedOrders}
      <Flex
        sx={{
          alignItems: "center",
          mt: 3,
          justifyContent: "flex-end",
        }}
      >
        <Button variant="primary" onClick={action.onClick}>
          {action.content}
        </Button>
        {goBack && (
          <Button
            sx={{
              m: 3,
              bg: "transparent",
              color: "text",
              borderRadius: 0,
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: "primary",
              ":enabled:hover": {
                bg: "transparent",
              },
            }}
            onClick={goBack}
          >
            {t("Go Back")}
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default Confirmation;
