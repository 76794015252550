import axios, { CanceledError, CancelTokenSource } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";
import { blobToDataURI } from "@/app/lib/blob-to-data-uri";
import { DOCUMENT_DECRYPTION_URL } from "@/app/lib/env-helpers";

import { ShowDoc, UseViewDocumentFn } from "./view-document.types";

export const useViewDocument: UseViewDocumentFn = (document) => {
  const { t } = useTranslation();

  const [instance, setInstance] = useState<Window>();
  const [showDoc, setShowDoc] = useState<ShowDoc | null>(null);
  const source = useRef<CancelTokenSource>();
  const [viewLoading, setViewLoading] = useState("");

  const onView = useCallback(async () => {
    try {
      if (source.current) {
        source.current.cancel();
        source.current = undefined;
      }
      setViewLoading(document?.id || "");

      if (!document || !document?.signedUrl) {
        throw new Error();
      }

      source.current = axios.CancelToken.source();

      const { data: response } = await axios.get(DOCUMENT_DECRYPTION_URL, {
        params: {
          url: document.signedUrl,
        },
        responseType: "blob",
        cancelToken: source.current.token,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      /** If Success set view loading to false
       * Not Done in finally block since we have to conditionally set this in catch block too
       */

      setViewLoading("");

      const dataURL = await blobToDataURI(new Blob([response], { type: document.mediaType ?? "application/png" }));

      setShowDoc({
        image: dataURL ?? "",
        imageName: document.fileName ?? "",
      });
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        /** Only When the response is not cancelled set the view loading to false
         * If the previous request was cancelled, that means another request has taken place and viewLoading is already overwritten in try block
         */
        setViewLoading("");
        notifications.error({
          description: t("Cannot fetch the image"),
        });
      }
    } finally {
      source.current = undefined;
    }
  }, [t, document]);

  useEffect(() => {
    return () => {
      source.current?.cancel();
    };
  }, []);

  useEffect(() => {
    const closeInstance = () => {
      instance?.close();
    };
    window.addEventListener("beforeunload", closeInstance);
    return () => {
      if (instance) instance.close();
      window.removeEventListener("beforeunload", closeInstance);
    };
  }, [instance]);

  const getWindowInstance = useCallback((window) => {
    setInstance(window);
  }, []);

  const closePopup = useCallback(() => {
    setShowDoc(null);
  }, []);

  return {
    viewLoading,
    onView,
    showDoc,
    closePopup,
    getWindowInstance,
  };
};
