import { Box, Flex, Tooltip } from "@powerledger/ui-component-lib";
import React, { FC, Fragment, isValidElement } from "react";
import { useTranslation } from "react-i18next";

import { TableValueWrapper } from "../table-value-wrapper";
import { SeparatorLayoutProps } from "./separator-layout.types";

export const SeparatorLayout: FC<SeparatorLayoutProps> = ({
  data,
  headingStyle,
  contentStyle,
  descriptionStyle,
  showSeparator = true,
  wrapperStyle,
  reverse = false,
  headerTooltips,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        flexWrap: "wrap",
        ...wrapperStyle,
      }}
    >
      {Object.entries(data).map((entry, index) => {
        const header = reverse ? (!isValidElement(entry[1]) ? t(String(entry[1])) : entry[1]) : t(entry[0]);
        const value = reverse ? entry[0] : entry[1];
        const description = !isValidElement(value) ? (
          <TableValueWrapper title={header as string} value={value as string} containerSx={descriptionStyle} csv />
        ) : (
          value
        );
        const toolTipForIndex = headerTooltips?.find((tooltip) => index === tooltip.index);

        return (
          <Fragment key={index}>
            {showSeparator && (
              <Box
                sx={{
                  borderWidth: "0.5px",
                  borderStyle: "solid",
                  borderColor: "textDarker",
                  mr: "10px",
                }}
              />
            )}
            <Box
              sx={{
                width: "25%",
                mr: 2,
                overflow: "hidden",
                textWrap: "wrap",
                ...contentStyle,
              }}
            >
              <Flex sx={{ fontWeight: "bold", fontSize: 2, flexWrap: "wrap", ...headingStyle }}>
                {header}
                {toolTipForIndex && (
                  <Tooltip
                    id="separatorLayoutTooltip"
                    content={
                      <Box
                        sx={{
                          fontSize: 1,
                          fontWeight: 50,
                        }}
                      >
                        {toolTipForIndex.content}
                      </Box>
                    }
                  />
                )}
              </Flex>
              <Box sx={{ fontSize: 1, overflowWrap: "break-word", ...descriptionStyle }}>{description}</Box>
            </Box>
          </Fragment>
        );
      })}
    </Flex>
  );
};
