import { useState } from "react";

import { LocationTexts, useGetLocationTexts } from "@/app/hooks/use-get-locations-text";
import { useRecOptions } from "@/app/hooks/use-rec-options";

import { UseLocationChipsFn } from "./location-chips.types";

export const getCountriesWithLocationCount = (locationTextWithCountry: LocationTexts[]) => {
  return locationTextWithCountry.reduce((countries, locationWithCountry) => {
    if (!countries[locationWithCountry.country]) {
      countries[locationWithCountry.country] = 1;
    } else {
      countries[locationWithCountry.country] += 1;
    }
    return countries;
  }, {} as Record<string, number>);
};

export const useLocationChips: UseLocationChipsFn = ({ locations }) => {
  const [activeCountry, setActiveCountry] = useState("");
  const { recOptions } = useRecOptions();
  const { getLocationTextsWithCountry } = useGetLocationTexts({ options: recOptions });
  const locationTextWithCountry = getLocationTextsWithCountry(locations?.map((location) => location.value) ?? [], true);

  return {
    activeCountry,
    setActiveCountry,
    locationTextWithCountry,
  };
};
