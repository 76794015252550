import { useCallback, useEffect, useRef } from "react";

export const useOutSideBoundaryClick = ({ setShowMenu = (val: boolean) => {} }) => {
  const ref = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (
        e.target &&
        ref.current &&
        buttonRef.current &&
        !ref.current?.contains(e.target as Node) &&
        !buttonRef.current?.contains(e.target as Node)
      ) {
        setShowMenu(false);
      }
    },
    [setShowMenu],
  );

  useEffect(() => {
    window.addEventListener("mousedown", handleOutsideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return {
    menuRef: ref,
    buttonRef,
  };
};
