import { BlockButton, Flex, Text } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { PaymentMethodIconsMap, PaymentProviderIconsMap } from "@/app/lib/payment-content-maps";
import { PaymentMethodLinkStatus } from "@/app/types/generated/graphql";

import { PaymentMethodBlockButtonProps } from "./payment-method-block-button.types";

export const PaymentMethodBlockButton: FC<PaymentMethodBlockButtonProps> = ({
  state,
  paymentMethod,
  active,
  onClick,
}) => {
  const { t } = useTranslation();

  const error = state ? state === PaymentMethodLinkStatus.Error || state === PaymentMethodLinkStatus.LinkRetry : false;
  return (
    <Flex
      sx={{
        flexDirection: "column",
      }}
    >
      <BlockButton
        sx={{
          borderColor: error ? "error.500" : active ? "primary.500" : "",
        }}
        label={paymentMethod.code}
        id={paymentMethod.code}
        active={active}
        onClick={() => onClick()}
      >
        <Flex sx={{ mb: 1, alignItems: "center" }}>{PaymentProviderIconsMap[paymentMethod.paymentProvider?.name]}</Flex>
        <Text sx={{ fontWeight: "bold", color: "textDarker" }}>+</Text>
        <Flex sx={{ mt: 1, alignItems: "center" }}>{PaymentMethodIconsMap[paymentMethod.code]}</Flex>
      </BlockButton>
      {error && (
        <Text sx={{ fontSize: 0, color: "error.500" }}>
          {t("Status: {{state}} ", { state: startCase(state?.toLowerCase()) })}
        </Text>
      )}
    </Flex>
  );
};
