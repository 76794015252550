import { Box, Button, Flex, Option, Text } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { getSelectTranslation } from "@/app/lib/get-translations-for-components";

import { FilterProps, SelectTypeOnChange } from "./filter.types";
import FilterDropdown from "./filter-dropdown";

export const Filter: React.FC<FilterProps> = ({
  buttonDisabled,
  someValueFilled,
  filters,
  resetFilter,
  applyFilter,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: 2,
        position: "relative",
      }}
    >
      <Flex
        sx={{
          gap: 2,
          flex: 1,
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {filters.map(
          ({
            name,
            options,
            values,
            header,
            styles,
            disabled,
            wrapperStyles,
            components,
            render,
            isMulti,
            disableOptions,
            isRequired,
            onChange,
            isDropdown = true,
            isMenuOpen,
          }) => {
            const selectValues = Array.isArray(values)
              ? options[0]?.options
                ? options.flatMap((o) => o.options?.filter((so) => values?.includes(so.value)) ?? [])
                : options.filter((o) => values.includes(o.value))
              : options.find((opt) => opt.value === values);
            const placeholder = (
              <>
                {t(name)} {isRequired && <Text sx={{ color: "error.500" }}>*</Text>}
              </>
            );
            if (!isDropdown) return <Box key={name}>{render?.()}</Box>;
            return (
              <FilterDropdown
                key={name}
                options={options}
                header={header}
                placeholder={placeholder}
                inputStyles={styles}
                disabled={disabled}
                disableOptions={disableOptions}
                value={selectValues}
                onChange={onChange as SelectTypeOnChange<Option, typeof isMulti>}
                translation={getSelectTranslation(t)}
                isMulti={isMulti}
                wrapperStyles={wrapperStyles}
                components={components}
                render={render}
                menuOpen={isMenuOpen}
              />
            );
          },
        )}
        <Flex
          sx={{
            ml: "auto",
            gap: 2,
          }}
        >
          <Button
            variant="text"
            sx={{
              color: "primary.600",
              opacity: someValueFilled ? "1" : "0.4",
            }}
            disabled={!someValueFilled}
            onClick={() => {
              resetFilter();
            }}
          >
            {t("Reset")}
          </Button>
          <Button
            variant="buttons.secondary"
            disabled={buttonDisabled}
            onClick={() => {
              applyFilter();
            }}
          >
            {t("Apply")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
