import { useMemo } from "react";

import { Tenant, useAccountTenantIdQuery, useTenantsQuery } from "../../types/generated/graphql";
import { AccountTenant } from "./use-account-tenant.types";

export const useAccountTenant = (): AccountTenant => {
  const { data: accountData, loading: accountLoading, error: accountError } = useAccountTenantIdQuery();
  const { data: tenantData, loading: tenantLoading, error: tenantError } = useTenantsQuery();

  const loading = useMemo(() => tenantLoading || accountLoading, [tenantLoading, accountLoading]);
  const error = useMemo(() => accountError || tenantError, [accountError, tenantError]);
  const data = useMemo(
    () =>
      tenantData?.tenants && accountData?.account?.tenantId
        ? (tenantData?.tenants.find((tenant) => tenant.id === accountData?.account?.tenantId) as Tenant)
        : undefined,
    [tenantData, accountData],
  );

  return {
    loading,
    error,
    data,
  };
};
