import { isOnboardingOrRetryUser } from "@/app/lib/account-service-helpers";
import { grantAccess } from "@/app/lib/role-based-access-control";
import { AccountStatus, useFewAccountDetailsQuery } from "@/app/types/generated/graphql";

import { FeatureToggleKey } from "../use-feature-toggle";
import { useAllFeatureToggleValues } from "../use-feature-toggle/use-all-feature-toggle-values";
import { useOktaRoles } from "../use-okta-roles";
import { PermissionGuard, UseHasPermissions } from "./use-has-permission.types";

export const useHasPermissions: UseHasPermissions = (args) => {
  const {
    data: accountData,
    loading: accountQueryLoading,
    error,
  } = useFewAccountDetailsQuery({
    skip: args?.skipQuery ?? false,
  });
  const { userRoles } = useOktaRoles();
  const { toggles, loading: featureToggleLoading } = useAllFeatureToggleValues();

  const hasPermissions = (guard: PermissionGuard) => {
    if (accountQueryLoading) return false;
    const isRoleAllowed = hasRolePermissions(guard.allowedRoles);
    const isStatusAllowed = hasStatusPermissions(guard.allowedStatuses);
    const isTypeAllowed = hasTypePermissions(guard.allowedAccountTypes);
    const isFeatureTogglesEnabled = isRequiredFeatureTogglesEnabled(guard.requiredFeatureToggles);
    return isRoleAllowed && isStatusAllowed && isTypeAllowed && isFeatureTogglesEnabled;
  };

  const hasTypePermissions = (allowedAccountTypes: PermissionGuard["allowedAccountTypes"]) => {
    if (accountQueryLoading || !accountData?.account?.type) return false;

    return allowedAccountTypes ? allowedAccountTypes.includes(accountData?.account?.type) : true;
  };

  const hasStatusPermissions = (allowedStatuses: PermissionGuard["allowedStatuses"]) => {
    if (accountQueryLoading || !accountData?.account?.status) return false;
    return allowedStatuses ? allowedStatuses.includes(accountData.account.status) : true;
  };

  const hasRolePermissions = (allowedRoles: PermissionGuard["allowedRoles"]) => {
    return grantAccess(userRoles, allowedRoles);
  };

  const isRequiredFeatureTogglesEnabled = (requiredFeatureToggles?: FeatureToggleKey[]) =>
    requiredFeatureToggles ? requiredFeatureToggles.every((toggle) => !!toggles[toggle]) : true;

  const isActorOnboarding = isOnboardingOrRetryUser(accountData?.account?.status);

  const isActorVerified = accountData?.account?.status === AccountStatus.Active;

  if (error) {
    throw error;
  }

  const loading = accountQueryLoading || featureToggleLoading;

  return {
    hasPermissions,
    hasRolePermissions,
    hasStatusPermissions,
    hasTypePermissions,
    isRequiredFeatureTogglesEnabled,
    loading,
    isActorOnboarding,
    isActorVerified,
  };
};
