import "react-toastify/dist/ReactToastify.css";

import { Box, ColorModes, Modal, useColorMode } from "@powerledger/ui-component-lib";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Registry, useRegistriesQuery } from "@/app/types/generated/graphql";
import { ReactComponent as IrecLogo } from "@/assets/images/irecLogo.svg";
import { ReactComponent as MretsLogo } from "@/assets/images/mretsLogo.svg";

import { RegistryForm } from "./registry-form";

const MRETSLogo = () => {
  const [colorMode] = useColorMode();
  return (
    <Box
      sx={{
        svg: {
          image: {
            filter: colorMode === ColorModes.Dark ? "brightness(1)" : "brightness(0)",
          },
        },
      }}
    >
      <MretsLogo />
    </Box>
  );
};

export const registryLogoMap: Record<string, any> = {
  MRETS: (
    <Box
      sx={{
        width: "100%",
        svg: {
          image: {
            filter: "brightness(0)",
          },
        },
      }}
    >
      <MRETSLogo />
    </Box>
  ),
  IREC: <IrecLogo />,
};

export const RegistryModal: FC<{
  visible: boolean;
  closeModal: () => void;
}> = ({ visible, closeModal }) => {
  const { t } = useTranslation();
  const [selectedRegistry, setSelectedRegistry] = useState<Registry | null>();

  const { data: registriesData } = useRegistriesQuery();

  const registryOptions = useMemo(() => registriesData?.registries || [], [registriesData?.registries]);

  const modalTitle = useMemo(() => {
    if (!selectedRegistry) return t("Select a registry to link");
    return t("Enter your {{registryName}} registry ID", {
      registryName: registryOptions?.find((ro) => ro?.id === selectedRegistry.id)?.shortName || "",
    });
  }, [registryOptions, selectedRegistry, t]);

  return (
    <Modal visible={visible} maskClosable onCancel={closeModal} sx={{ maxHeight: "inherit" }} title={modalTitle}>
      <RegistryForm
        closeModal={closeModal}
        selectedRegistry={selectedRegistry}
        setSelectedRegistry={setSelectedRegistry}
      />
    </Modal>
  );
};
