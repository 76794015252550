import * as Yup from "yup";

import { approvalStatusSchema } from "./approvalStatus";
import { physicalAddressSchema } from "./physical-address";

/**
 * Validation Schema for Company Details
 */
export const companySchema = Yup.object()
  .shape({
    businessName: Yup.string().nullable().hasMaxLengthAndNoSpecialChars(255).required("Required"),
    businessType: Yup.string().nullable().required("Required"),
    businessClassification: Yup.string().nullable().required("Required"),
    industryClassificationUid: Yup.string().nullable().required("Required"),
    employerIdentificationNumber: Yup.string().nullable().required("Required"),
    approvalStatus: approvalStatusSchema,
  })
  .concat(physicalAddressSchema);
