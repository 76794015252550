import { Box } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { LoadingOverlayIndicator } from "@/app/components";
import { Confirmation } from "@/app/components/order-modal";
import { HeaderText } from "@/app/components/page-header";

import RecActionSummary from "../common/rec-action-summary/rec-action-summary";
import RecActionTable from "../common/rec-action-table/rec-action-table";
import RetirementFormBlock from "./form/retirement-form-block";
import { RetirementFormValues, RetirementLifeCycle, RetirementType, RetireViewProps } from "./retire.types";
import { retirementChoices } from "./use-retire";

export const RetireView: React.FC<RetireViewProps> = ({
  tableData,
  recOptions,
  loading,
  notePrefix,
  onProceed,
  formikProps,
  onGoBack,
  currentStep,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return <LoadingOverlayIndicator />;
  }

  if (currentStep === RetirementLifeCycle.Summary) {
    return (
      <RecActionSummary
        action="retire"
        data={formikProps.initialValues as RetirementFormValues}
        recOptions={recOptions}
        onProceed={onProceed}
        onGoBack={onGoBack}
        buttonActionText="retirement"
      />
    );
  }

  if (currentStep === RetirementLifeCycle.Success) {
    return (
      <Confirmation
        text={t("Your retirement request has been received.")}
        goBack={onGoBack}
        sx={{ p: 3 }}
        action={{
          content: "View your retirement requests here",
          onClick: () => {
            onProceed(formikProps.initialValues as RetirementFormValues);
          },
        }}
      />
    );
  }

  return (
    <Box as="main" sx={{ m: 4, position: "relative" }}>
      <HeaderText title={t("Retire RECs")} sx={{ width: 350 }} />
      <Box sx={{ my: 2 }}>
        <Formik
          initialValues={formikProps.initialValues as RetirementFormValues}
          onSubmit={onProceed}
          validationSchema={Yup.object().shape({
            type: Yup.string().nullable().required("Required"),
            reason: Yup.string().nullable().required("Required"),
            compliancePeriod: Yup.string().when("retirementType", {
              is: (retirementType: string) => retirementType == RetirementType.GREEN_E_CERTIFIED_VOLUNTARY_MARKET,
              then: Yup.string().nullable().required("Required"),
              otherwise: Yup.string().nullable(),
            }),
            //notes is an optional field
            values: Yup.array().of(
              Yup.object().shape({
                actionVolume: Yup.number()
                  .required(t("Enter valid amount"))
                  .positive(t("Enter valid amount"))
                  .max(Yup.ref("availableBalance") || 0, t("You don't have enough RECs"))
                  .test("whole-number", t("Enter valid amount"), (value = 0) => Number.isInteger(value)),
              }),
            ),
          })}
          validateOnMount
          {...formikProps}
        >
          {({ values }) => {
            const selectedRetirementType = retirementChoices.find((type) => type.name === values.type);
            return (
              <RecActionTable
                recOptions={recOptions}
                tableData={tableData}
                loading={loading}
                onGoBack={onGoBack}
                additionalContent={
                  <Box sx={{ mt: 3 }}>
                    <RetirementFormBlock selectedRetirementType={selectedRetirementType} notePrefix={notePrefix} />
                  </Box>
                }
              />
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};
