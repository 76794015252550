import { Modal } from "@powerledger/ui-component-lib";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";
import { AddTradingAccountUserInput } from "@/app/types/generated/graphql";

import { AddEditNewUserForm } from "./add-edit-new-user-form";
import { AddEditNewUserModalProps } from "./add-edit-new-user-modal.types";

export const AddEditNewUserModal: React.FC<AddEditNewUserModalProps> = ({ closeModal, onSubmit, values, visible }) => {
  const { t } = useTranslation();

  const submitForm = async (
    formValues: AddTradingAccountUserInput,
    actions: FormikHelpers<AddTradingAccountUserInput & { tnc: boolean }>,
  ) => {
    try {
      await onSubmit(formValues);
      closeModal();
      notifications.success({
        description: values?.email ? t("User Updated!") : t("New User Added!"),
      });
    } catch (e) {
      e instanceof Error &&
        notifications.error({
          description: e?.message,
        });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal visible={visible} onCancel={closeModal} title={values ? t("Edit User") : t("Add User")}>
      <AddEditNewUserForm values={values} onSubmit={submitForm} />
    </Modal>
  );
};
