import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";

import notifications from "../../container/notifications";
import { PLAID_LINK_ENV } from "../../lib/env-helpers";
import { AddFundingSourceResponse } from "../../types/generated/graphql";
import { usePlaidLinkOnExitHandler } from "./use-plaid-link-on-exit-handler";
import { usePlaidLinkOnSuccessHandler } from "./use-plaid-link-on-success-handler";

export const KEY_PLAID_LINK_TOKEN = "plaid_link_token";

export const usePlaidLinkModal = ({
  onLinkAccountSuccess,
  receivedRedirectUri,
  initialisingToken,
}: {
  onLinkAccountSuccess?: (response: AddFundingSourceResponse) => void;
  receivedRedirectUri?: string;
  initialisingToken?: string | null;
}) => {
  const { t } = useTranslation();

  const { onExit: plaidLinkOnExit, loading: isPlaidLinkOnExitLoading, disable } = usePlaidLinkOnExitHandler();
  const { onSuccess: plaidLinkOnSuccess, loading: isPlaidLinkOnSuccessLoading } = usePlaidLinkOnSuccessHandler({
    onLinkAccountSuccess,
  });

  const loading = isPlaidLinkOnSuccessLoading || isPlaidLinkOnExitLoading;

  useEffect(() => {
    if (!receivedRedirectUri && initialisingToken) {
      localStorage.setItem(KEY_PLAID_LINK_TOKEN, initialisingToken);
    }
  }, [initialisingToken, receivedRedirectUri]);

  const linkToken = useMemo(
    () => (receivedRedirectUri ? localStorage.getItem(KEY_PLAID_LINK_TOKEN) : initialisingToken),
    [initialisingToken, receivedRedirectUri],
  );

  const config = useMemo<PlaidLinkOptions>(
    () => ({
      token: linkToken || null,
      onSuccess: plaidLinkOnSuccess,
      onExit: (error, metadata) => !receivedRedirectUri && plaidLinkOnExit(error, metadata),
      receivedRedirectUri,
      env: PLAID_LINK_ENV,
    }),
    [linkToken, plaidLinkOnSuccess, plaidLinkOnExit, receivedRedirectUri],
  );

  const { open: plaidLinkOpen, ready: plaidLinkReady, error: plaidLinkError } = usePlaidLink(config);

  const openPlaidModal = () => {
    if (plaidLinkReady) {
      try {
        plaidLinkOpen();
      } catch (e) {
        notifications.error({
          description: t("Something went wrong when opening Plaid Link"),
        });
      }
    }
  };

  useEffect(() => {
    if (receivedRedirectUri && plaidLinkError) {
      notifications.error({
        description: plaidLinkError.message,
      });
    }
  }, [plaidLinkError, receivedRedirectUri, t]);

  return {
    plaidLinkOpen,
    plaidLinkReady,
    plaidLinkError,
    plaidLinkLoading: loading,
    disablePlaidModal: disable,
    openPlaidModal,
  };
};
