import { useFormikContext } from "formik";
import { useCallback } from "react";

import { useOnboardingContext } from "../onboarding/context";
import { useApplicantControllerStepFn } from "./applicant-controller-step.types";

export const useApplicantControllerStep: useApplicantControllerStepFn = () => {
  const { goBack: moveBackward, goNext: moveForward, saveCurrentStage } = useOnboardingContext();

  const { isSubmitting } = useFormikContext();

  const goBack = useCallback(async () => {
    await saveCurrentStage();
    moveBackward();
  }, [saveCurrentStage, moveBackward]);

  const goNext = useCallback(async () => {
    await saveCurrentStage();
    moveForward();
  }, [saveCurrentStage, moveForward]);

  return {
    goBack,
    goNext,
    isSubmitting,
  };
};
