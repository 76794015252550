import { flatten } from "lodash";
import { useMemo, useState } from "react";

import { AccountStatus, CompanyContact, ContactType, Document, useAccountQuery } from "@/app/types/generated/graphql";

import { DocumentTypeLabelMap } from "./required-documents.types";

export const documentTypeLabelMap = {
  companyControllerBlock: "Company and Controller Documents",
  beneficialOwnerBlock: "Beneficial Owners Documents",
};

export const getContactName = (contact?: CompanyContact) => {
  return `${contact?.firstName || ""} ${contact?.lastName || ""}`;
};

export const useRequiredDocuments = () => {
  const [isUSPerson, setIsUSPerson] = useState(false);
  const [selectedRequiredDocument, setSelectedRequiredDocument] = useState<Document | undefined | null>(undefined);
  const [uploadBlocksToggleState, setUploadBlocksToggleState] = useState<Record<keyof DocumentTypeLabelMap, boolean>>({
    companyControllerBlock: false,
    beneficialOwnerBlock: false,
  });
  const { data: accountsData, loading: loadingAccountData } = useAccountQuery({
    notifyOnNetworkStatusChange: true,
  });

  const company = accountsData?.account?.company;

  const controller = useMemo(
    () => accountsData?.account?.company?.contacts?.find((contact) => contact.type === ContactType.Controller),
    [accountsData?.account?.company?.contacts],
  );

  const beneficialOwners = useMemo(
    () => accountsData?.account?.company?.contacts?.filter((contact) => contact.type === ContactType.BeneficialOwner),
    [accountsData?.account?.company?.contacts],
  );

  const requiredCompanyDocuments: Document[] = useMemo(() => {
    return accountsData?.account?.company?.documents || [];
  }, [accountsData?.account?.company?.documents]);

  const requiredControllerDocuments: Document[] = useMemo(() => {
    return flatten(
      accountsData?.account?.company?.contacts
        ?.filter((contact) => contact.type === ContactType.Controller)
        .map((controller) => controller.documents || []),
    );
  }, [accountsData?.account?.company?.contacts]);

  const showStatusText = accountsData?.account?.status !== AccountStatus.Onboarding;

  const toggleUploadBlocksState = (key: keyof DocumentTypeLabelMap) => () => {
    setUploadBlocksToggleState((state) => ({
      ...state,
      [key]: !state[key],
    }));
  };

  return {
    beneficialOwners,
    setIsUSPerson,
    selectedRequiredDocument,
    setSelectedRequiredDocument,
    requiredCompanyDocuments,
    isUSPerson,
    showStatusText,
    company,
    requiredControllerDocuments,
    controller,
    hasBeneficialOwners: !!beneficialOwners?.length,
    uploadBlocksToggleState,
    loading: loadingAccountData,
    toggleUploadBlocksState,
  };
};
