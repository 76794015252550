import { Box, Flex, getColor, IconError, Text, Themed, useTheme } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import i18n from "@/app/lib/i18n";

import { UnlinkFundingSourceTextProps } from "./unlink-funding-source.types";

export const UnlinkFundingSourceModalText: FC<UnlinkFundingSourceTextProps> = ({ isActive }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <Flex
      sx={{
        flexDirection: "column",
        color: "textDark",
        p: 3,
        mt: 3,
        justifyContent: "center",
        boxShadow: `0 0 5px ${getColor(theme, "yellow")}`,
        borderRadius: 4,
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        {isActive ? (
          t("Unlinking your bank account will result in the following changes:")
        ) : (
          <Flex
            sx={{
              alignItems: "center",
            }}
          >
            <IconError color={"yellow"} size={6} />
            {t("Unlinking your bank account may lead to delays in our verification process")}
          </Flex>
        )}
      </Flex>
      {isActive && (
        <>
          <Flex
            sx={{
              alignItems: "center",
            }}
          >
            <IconError color={"yellow"} size={10} />

            <Box>
              <Themed.ul>
                <Themed.li>
                  <Trans i18n={i18n}>
                    Your account will be moved to an{" "}
                    <Text sx={{ color: "yellow", fontWeight: "bold" }}>UNVERIFIED</Text> state
                  </Trans>
                </Themed.li>
                <Themed.li>
                  <Trans i18n={i18n}>
                    Your account will only have access to the{" "}
                    <Text sx={{ color: "yellow", fontWeight: "bold" }}>SETTINGS</Text> and{" "}
                    <Text sx={{ color: "yellow", fontWeight: "bold" }}>USER RESOURCES</Text> pages
                  </Trans>
                </Themed.li>
                <Themed.li>
                  {t("Your account will be re-verified by the TraceX team within 3-5 business days")}
                </Themed.li>
                <Themed.li>
                  <Trans i18n={i18n}>
                    All your <Text sx={{ color: "yellow", fontWeight: "bold" }}>ACTIVE</Text> orders on the market will
                    be removed
                  </Trans>
                </Themed.li>
              </Themed.ul>
            </Box>
          </Flex>
          <Box sx={{ fontWeight: "bold" }}>
            {t("By ‘Confirming’, you acknowledge the above changes to take place on your account.")}
          </Box>
        </>
      )}
    </Flex>
  );
};
