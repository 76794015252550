import { Box, Chip, Option } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { LocationChips } from "@/app/components/location-chips/location-chips";
import { FiltersType, SelectTypeOnChange, UseFilterReturn } from "@/app/components/page-header/filter/filter.types";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";

import { FormFieldLabel } from "../../../form/form-field-label";
import FilterDropdown from "../../../page-header/filter/filter-dropdown";

export const BuyFormFilter = ({
  filter,
  stackedValues,
  removeValue,
  disabled,
}: {
  disabled?: boolean;
  filter: FiltersType[0];
  removeValue: UseFilterReturn["removeValue"];
  stackedValues?: UseFilterReturn["stackedValues"];
}) => {
  const { t } = useTranslation();
  const {
    name,
    options,
    values,
    styles,
    disabled: filterDisabled,
    components,
    isMulti,
    disableOptions,
    onChange,
  } = filter;
  const currentFilterSelectedValues = stackedValues?.filter((item) => filter.keys?.includes(item.type));
  const selectValues = Array.isArray(values)
    ? options[0]?.options
      ? options.flatMap((o) => o.options?.filter((so) => values?.includes(so.value)) ?? [])
      : options.filter((o) => values.includes(o.value))
    : options.find((opt) => opt.value === values);

  return (
    <Box sx={{ mb: 3 }}>
      <FormFieldLabel
        small
        hasErrorMessage={false}
        label={filter.label ?? t(name)}
        info={filter.info}
        name={name.replace(/\s/g, "-").toLowerCase()}
        sx={{ height: 24, mb: 1 }}
      />
      <FilterDropdown
        button={{
          type: "button", // Otherwise acts like a submit button and it's impact can be seen if used inside a form
        }}
        placeholder={currentFilterSelectedValues?.length ? t("Select") : t("Any")}
        options={options}
        inputStyles={styles}
        disabled={disabled || filterDisabled}
        disableOptions={disableOptions}
        value={selectValues}
        onChange={onChange as SelectTypeOnChange<Option, typeof isMulti>}
        translation={getSelectTranslation(t)}
        isMulti={isMulti}
        components={components}
        wrapperStyles={{
          containerStyles: {
            "> button": {
              backgroundColor: "pageBackground",
              height: 44,
              width: "100%",
              fontWeight: "normal",
            },
            "> div": {
              width: "100%",
            },
          },
        }}
      />

      {currentFilterSelectedValues?.length ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            mt: 3,
          }}
        >
          {filter.keys?.includes("locations") ? (
            <LocationChips
              locations={currentFilterSelectedValues}
              removeValue={!filter.disabled ? removeValue : undefined}
            />
          ) : (
            currentFilterSelectedValues?.map((item) => (
              <Chip
                key={item.value}
                label={item.label}
                onRemove={!filter.disabled ? () => removeValue(item.type, item.value) : undefined}
              />
            ))
          )}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
