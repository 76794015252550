import { Flex, Grid, LegacySelect } from "@powerledger/ui-component-lib";
import { Field, FormikErrors } from "formik";
import { useTranslation } from "react-i18next";

import { AddressFormFields } from "@/app/components/address-form-section";
import { AppCleaveTypes, CleaveInput } from "@/app/components/cleave-input";
import { Form, FormFieldLabel, FormInput } from "@/app/components/form";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";
import { validateEIN } from "@/app/lib/validate-ein";
import { StepButton, StepLayout } from "@/app/pages/public";
import { AddressInput, BusinessClassification, IndustryClassification } from "@/app/types/generated/graphql";

import { ApplicantDetailsStepViewProps } from "./applicant-details-step.types";
import { businessTypes } from "./use-applicant-details-step";

export const ApplicantDetailsStepView: React.FC<ApplicantDetailsStepViewProps> = ({
  fieldsDisabled,
  handleSubmit,
  errors,
  getInputVariant,
  getInputStyles,
  setFieldTouched,
  setFieldValue,
  values,
  setIndustryClassificationDropdownOptions,
  loadingBusinessClassifications,
  businessClassificationsOptions,
  industryClassificationsOptions,
  getRequiredFieldsMessage,
  isSubmitting,
  goNext,
  goBack,
}) => {
  const { t } = useTranslation();

  return (
    <StepLayout
      id="CompanyDetails"
      heading={t("Applicant Details")}
      disclaimer={t("Please provide the following details for the Applicant")}
      actions={
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StepButton back disabled={isSubmitting} onClick={goBack} />
          <StepButton disabled={isSubmitting} onClick={goNext} />
        </Flex>
      }
    >
      <Form id="companyInformation" onSubmit={handleSubmit}>
        <>
          <Grid gap={3} columns={[2, 2, 3]} sx={{ alignItems: "baseline" }}>
            <Form.Item sx={{ justifyContent: "end" }}>
              <FormFieldLabel
                hasErrorMessage={errors?.businessName !== "Required"}
                small
                name="businessName"
                label={t("Entity Legal Name *")}
              >
                <FormInput
                  debounce
                  hasErrorIndicator={false}
                  variant={getInputVariant("")}
                  name="businessName"
                  disabled={fieldsDisabled}
                />
              </FormFieldLabel>
            </Form.Item>
            <Form.Item sx={{ justifyContent: "end" }}>
              <FormFieldLabel
                small
                hasErrorMessage={errors?.employerIdentificationNumber !== "Required"}
                name="employerIdentificationNumber"
                label={t("EIN *")}
                info={t("Employer Identification Number, in the format of 12-3456789")}
              >
                <Field
                  name="employerIdentificationNumber"
                  validate={(value: string) => validateEIN(value)}
                  component={CleaveInput}
                  rawValue={false}
                  type={AppCleaveTypes.EIN}
                  variant={getInputVariant("employerIdentificationNumber")}
                  disabled={fieldsDisabled}
                />
              </FormFieldLabel>
            </Form.Item>
            <Form.Item sx={{ justifyContent: "end" }}>
              <FormFieldLabel
                hasErrorMessage={false}
                sx={getInputStyles("businessType", "select")}
                small
                name="businessType"
                label={t("Entity Type *")}
              >
                <LegacySelect
                  translation={getSelectTranslation(t)}
                  onBlur={() => {
                    setFieldTouched("businessType", true, true);
                  }}
                  disabled={fieldsDisabled}
                  value={businessTypes.find((bt) => bt.value === values.businessType)}
                  options={businessTypes}
                  onChange={async (option) => {
                    await setFieldValue("businessType", option?.value);
                  }}
                />
              </FormFieldLabel>
            </Form.Item>
            <Form.Item sx={{ justifyContent: "end" }}>
              <FormFieldLabel
                hasErrorMessage={false}
                small
                sx={getInputStyles("businessClassification", "select")}
                name="businessClassification"
                label={t("Entity Classification *")}
              >
                <LegacySelect
                  translation={getSelectTranslation(t)}
                  disabled={fieldsDisabled}
                  value={businessClassificationsOptions.find((c) => c.name === values.businessClassification)}
                  getOptionValue={(opt: BusinessClassification) => opt.id}
                  getOptionLabel={(opt: BusinessClassification) => opt.name}
                  isLoading={loadingBusinessClassifications}
                  options={businessClassificationsOptions}
                  onBlur={() => setFieldTouched("businessClassification", true, true)}
                  onChange={async (option) => {
                    await setFieldValue("businessClassification", option?.name);
                    setFieldValue("industryClassificationUid", "");
                    setIndustryClassificationDropdownOptions(option?.name || "");
                  }}
                />
              </FormFieldLabel>
            </Form.Item>
            <Form.Item sx={{ justifyContent: "end" }}>
              <FormFieldLabel
                hasErrorMessage={false}
                sx={getInputStyles("industryClassificationUid", "select")}
                small
                name="industryClassificationUid"
                label={t("Entity Sub Classification *")}
              >
                <LegacySelect
                  translation={getSelectTranslation(t)}
                  value={industryClassificationsOptions.find((c) => c.id === values.industryClassificationUid) || null}
                  isLoading={loadingBusinessClassifications}
                  disabled={!values.businessClassification || fieldsDisabled}
                  getOptionValue={(opt: IndustryClassification) => opt.id}
                  getOptionLabel={(opt: IndustryClassification) => opt.name}
                  options={industryClassificationsOptions}
                  onBlur={() => {
                    setFieldTouched("industryClassificationUid", true, true);
                  }}
                  onChange={async (option) => {
                    await setFieldValue("industryClassificationUid", option?.id);
                  }}
                />
              </FormFieldLabel>
            </Form.Item>
          </Grid>
          <AddressFormFields
            errors={(errors.physicalAddress ?? {}) as FormikErrors<AddressInput>}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            disableFields={fieldsDisabled}
            values={values.physicalAddress as AddressInput}
            formType="company"
            getInputStyles={getInputStyles}
            getInputVariant={getInputVariant}
          />
          {getRequiredFieldsMessage()}
        </>
      </Form>
    </StepLayout>
  );
};
