import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { subYears } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { defaultFilter, mapStackedFilterStateToFilterState } from "@/app/components/page-header/filter";
import { getDatePart } from "@/app/lib/date-helpers";

import { FilterState } from "../../components/page-header/filter/filter.types";
import { useMountIndicator, useRecOptions } from "../../hooks";
import { formatAttributes, ReturnTypeFormatAttributes } from "../../lib/format-attributes";
import {
  AssetType,
  OrderBookSortOrderInput,
  OrderPosition,
  OrderStatus,
  RecOrderAttributes,
  useOrderHistoryV2Query,
} from "../../types/generated/graphql";
import { OrderHistoryProps, OrderHistoryTableData } from "./order-history.types";

export const getOrderIsCancellable = (order: OrderHistoryTableData): boolean => {
  switch (order.status) {
    case OrderStatus.Partial:
    case OrderStatus.Open:
    case OrderStatus.PendingPartial:
      return true;
    default:
      return false;
  }
};

export const getCanDownloadTransactionSummary = (order: OrderHistoryTableData): boolean => {
  switch (order.status) {
    case OrderStatus.PartialWithdrawn:
    case OrderStatus.Withdrawn:
    case OrderStatus.Complete:
    case OrderStatus.PartialFailure:
      return true;
    default:
      return false;
  }
};

export const useOrderHistory = (): OrderHistoryProps => {
  const { mounted } = useMountIndicator();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeFilters, setActiveFilters] = useState<FilterState>(defaultFilter);

  const [showAllAttributes, setShowAllAttributes] = useState(false);
  const [showOrderCancelModal, setShowOrderCancelModal] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState("");

  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const [typeFilter, setTypeFilter] = useState(OrderPosition.Bid);

  const typeFilterChanged = (value: boolean) => {
    updateTypeFilter(value, "type", setTypeFilter);
    resetPage();
  };

  const search = useMemo(() => new URLSearchParams(location.search), [location]);

  useEffect(() => {
    const searchType = search.get("type");
    if (searchType) setTypeFilter(OrderPosition[searchType as keyof typeof OrderPosition]);
  }, [search, mounted]);

  const { pageInfo, offset, fetchData, sort, resetPage, setTotalItems } = useTablePaginationManager<
    OrderHistoryTableData,
    OrderBookSortOrderInput[]
  >({});

  const { data, loading, refetch, previousData } = useOrderHistoryV2Query({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          assetType: AssetType.Rec,
          position: typeFilter,
          statuses: activeFilters.statuses as OrderStatus[],
          recAttributes: {
            eligibilities: activeFilters.eligibilities,
            locations: activeFilters.locations,
            fuelSources: activeFilters.fuelSources,
            vintages: activeFilters.vintages,
            projects: activeFilters.projects,
            certifications: activeFilters.certifications,
            commencementDate: activeFilters.cod?.length
              ? getDatePart(subYears(new Date(), +activeFilters.cod?.[0]))
              : undefined,
          },
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data?.orderHistoryV2?.offsetInfo?.total ?? 0);
    },
  });

  const currentData = useMemo(() => data ?? previousData, [data, previousData]);

  const reloadOrderHistory = async () => {
    await refetch();
  };

  const tableData = useMemo(() => {
    const orderHistoryTableData: OrderHistoryTableData[] = [];

    if (!currentData?.orderHistoryV2?.orders) return orderHistoryTableData;
    for (const order of currentData.orderHistoryV2.orders) {
      const attributes = order.attributes as RecOrderAttributes;
      const formattedAttributes = recOptions
        ? formatAttributes({
            attributes,
            position: order?.position,
            options: recOptions,
          })
        : ({} as ReturnTypeFormatAttributes);
      orderHistoryTableData.push({
        ...formattedAttributes,
        ...order,
        attributes,
      });
    }
    return orderHistoryTableData;
  }, [recOptions, currentData?.orderHistoryV2.orders]);

  const initOrderCancellation = useCallback((orderId: string) => {
    setShowOrderCancelModal(true);
    setCancelOrderId(orderId);
  }, []);

  const updateTypeFilter = (value: boolean, filter: string, setState: (stateValue: any) => void) => {
    const result = value ? "Ask" : "Bid";

    search.delete(filter);
    search.append(filter, result);

    navigate({
      pathname: location.pathname,
      search: search.toString(),
    });

    return setState(OrderPosition[result as keyof typeof OrderPosition]);
  };

  const handleFilterChange = useCallback(
    (arg) => {
      setActiveFilters((activeFilters) => ({ ...activeFilters, ...mapStackedFilterStateToFilterState(arg) }));
      resetPage();
    },
    [resetPage],
  );

  return {
    tableData,
    loading: loading || recOptionsLoading,
    reloadOrderHistory,
    fetchData,
    recOptions,
    pageInfo,
    cancelOrderId,
    getOrderIsCancellable,
    initOrderCancellation,
    showOrderCancelModal,
    setShowOrderCancelModal,
    getCanDownloadTransactionSummary,
    handleFilterChange,
    typeFilter,
    typeFilterChanged,
    showAllAttributes,
    setShowAllAttributes,
  };
};
