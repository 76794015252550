import { Button, Flex, IconLoading, Table } from "@powerledger/ui-component-lib";
import { ErrorBoundary } from "@sentry/react";
import { startCase } from "lodash";
import { lazy, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { ActionStatusChip } from "@/app/components/action-status-chip";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { volumeFormatter } from "@/app/lib/volume-formatter";
import { ActionInitiator, ExportRequestStatus, RetirementStatus } from "@/app/types/generated/graphql";

import { ExportHistoryTableData, ExportHistoryTableProps } from "../export-history.types";

const CommodityActionConfirmationDownloadButton = lazy(
  () =>
    import(
      "@/app/components/download-report-components/commodity-action-confirmation-download-button/commodity-action-confirmation-download-button"
    ),
);

export default function ExportHistoryTable({
  data,
  loading,
  pageInfo,
  fetchData,
  onSeeDetails,
}: Readonly<ExportHistoryTableProps>) {
  const { t } = useTranslation();
  const columns: Column<ExportHistoryTableData>[] = useMemo(
    () => [
      {
        Header: t("Export Number"),
        disableSortBy: true,
        accessor: "exportNumber",
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      },
      {
        Header: t("Requested At"),
        accessor: "requestedOn",
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper
            value={formatDate(value, { formatStyle: AppDateFormats.AbbreviatedMonthFormatWithTime })}
            containerSx={{ minWidth: 80 }}
          />
        ),
      },
      {
        Header: t("Quantity"),
        accessor: "totalVolume",
        Cell: ({ value }: { value: number }) => <TableValueWrapper value={volumeFormatter(value)} />,
      },
      {
        Header: t("Initiated By"),
        accessor: "initiatedBy",
        Cell: ({ value }: { value: ActionInitiator }) => <TableValueWrapper value={startCase(value.toLowerCase())} />,
      },
      {
        Header: t("Status"),
        accessor: "status",
        Cell: ({ value, row }: { row: Row<ExportHistoryTableData>; value: RetirementStatus }) => (
          <ActionStatusChip id={row.id} status={value} statusMessage={row.original.statusMessage} />
        ),
      },
      {
        Header: t("Action"),
        id: "action",
        Cell: ({ row }: { row: Row<ExportHistoryTableData> }) => (
          <Flex>
            {row.original.status === ExportRequestStatus.Success ? (
              <ErrorBoundary fallback={<div>{t("Something went wrong")}</div>}>
                <Suspense fallback={<IconLoading sx={{ ml: 2 }} size="small" />}>
                  <CommodityActionConfirmationDownloadButton actionId={row.original.id} action="export" />
                </Suspense>
              </ErrorBoundary>
            ) : (
              <Button
                variant="pill.compactSecondary"
                sx={{ py: 1, mr: 2 }}
                aria-label={t("See details for export request {{id}}", { id: row.original.exportNumber })}
                onClick={() => {
                  onSeeDetails(row.original.id);
                }}
              >
                {t("See Details")}
              </Button>
            )}
          </Flex>
        ),
      },
    ],
    [t, onSeeDetails],
  );

  return (
    <Table
      loading={loading}
      showScrollArrows
      manualPagination
      columns={columns}
      dataSource={data}
      fetchData={fetchData}
      pageInfo={pageInfo}
      translation={getTableTranslation(t)}
    />
  );
}
