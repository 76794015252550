import { Box, Button, Divider, Flex, Menu, ThemeToggle } from "@powerledger/ui-component-lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { useLogout } from "@/app/hooks/use-logout";
import { useOutSideBoundaryClick } from "@/app/hooks/use-outside-boundary-click";
import { APOLLO_GRAPH_VARIANT } from "@/app/lib/env-helpers";
import { RegistrySVG, UserSVG } from "@/assets/icons";

import { NavButton } from "../../nav-button";
import { NotificationBadge } from "../../notification-badge";
import { LayoutHeaderProps } from "./layout-header.types";

const RegistryBadge = () => (
  <NavButton as="nav" path="/settings?tab=registry">
    <NavLink to="/settings?tab=registry" style={{ flex: 1 }}>
      <Box as="div" sx={{ marginTop: "5px" }}>
        <RegistrySVG />
      </Box>
    </NavLink>
  </NavButton>
);

const navLinks = [
  {
    name: "Settings",
    component: function Settings() {
      const { t } = useTranslation();

      return (
        <NavLink
          to="/settings"
          style={({ isActive }) => {
            return {
              background: isActive ? "var(--theme-ui-colors-shadow)" : "",
              borderRadius: "8px",
            };
          }}
        >
          <Flex
            sx={{
              alignItems: "center",
              width: "100%",
              height: "100%",
              ml: 2,
            }}
          >
            {t("Settings")}
          </Flex>
        </NavLink>
      );
    },
  },
  {
    name: "logout",
    component: function Logout() {
      const { t } = useTranslation();
      const { logout, loggingOut } = useLogout();
      return (
        <Button
          sx={{
            color: "text",
            bg: "transparent",
            justifyContent: "flex-start",
            p: 0,
            width: "100%",
            ml: 2,
            height: "100%",
            "&:enabled:hover, &:enabled:hover, &:enabled:focus": {
              bg: "transparent",
            },
          }}
          onClick={() => {
            logout();
          }}
          disabled={loggingOut}
        >
          {t("Log Out")}
        </Button>
      );
    },
  },
];

const ProfileDropdown = ({ userInfo }: { userInfo: LayoutHeaderProps["userInfo"] }) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { menuRef, buttonRef } = useOutSideBoundaryClick({ setShowMenu: setShowProfileMenu });

  return (
    <Box sx={{ position: "relative" }}>
      <NavButton
        ref={buttonRef}
        onClick={() => {
          setShowProfileMenu((state) => !state);
        }}
      >
        <UserSVG />
      </NavButton>
      {showProfileMenu && (
        <Box
          ref={menuRef}
          sx={{
            zIndex: 10,
            mt: 2,
            background: "pageBackground",
            borderRadius: 4,
            position: "absolute",
            width: "240px",
            fontSize: "14px",
            left: "-200px",
            pt: 3,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "shadow",
            ul: {
              gap: 0,
            },
          }}
        >
          <Flex
            sx={{
              height: 8,
              px: 2,
              gap: 2,
              alignItems: "center",
            }}
          >
            <Button
              variant="avatar"
              as="div"
              sx={{
                bg: "transparent",
                borderColor: "shadow",
                color: "primary.500",
              }}
            >
              {userInfo.name.charAt(0)}
            </Button>
            <Flex sx={{ flexDirection: "column", width: "80%" }}>
              <Box sx={{ fontWeight: "bold" }}>{userInfo.name}</Box>
              <Box sx={{ color: "textDark", fontSize: 0, wordWrap: "break-word" }}>{userInfo.email}</Box>
            </Flex>
          </Flex>
          <Divider color="textDark" sx={{ opacity: 0.5 }} />
          <Menu>
            {navLinks.map((link) => (
              <Menu.Item
                key={link.name}
                handleOnClick={() => {
                  setShowProfileMenu(false);
                }}
                sx={{
                  width: "90%",
                  borderRadius: 4,
                  my: 1,
                  a: {
                    textDecoration: "none",
                    height: "inherit",
                    width: "inherit",
                    color: "text",
                    fontWeight: "bold",
                  },
                  listStyle: "none",
                  fontWeight: "bold",
                  fontSize: "14px",
                  "&:hover": {
                    background: "shadow",
                  },
                }}
              >
                <Flex sx={{ width: "100%", height: "100%", alignItems: "center" }}>
                  <link.component />
                </Flex>
              </Menu.Item>
            ))}
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export const LayoutHeader: React.FC<LayoutHeaderProps> = ({ header, userInfo }) => {
  const { t } = useTranslation();

  return (
    header || (
      <Box>
        <Flex
          sx={{
            alignItems: "center",
            height: "100%",
            gap: 3,
            mx: 3,
            mr: 4,
          }}
        >
          {userInfo.canViewRegistry && <RegistryBadge />}
          <NotificationBadge />
          <ProfileDropdown userInfo={userInfo} />
          {APOLLO_GRAPH_VARIANT === "development" && (
            <ThemeToggle checkedLabel={t("Dark Mode")} uncheckedLabel={t("Light Mode")} />
          )}
        </Flex>
      </Box>
    )
  );
};
