import { Box, Link, Text } from "@powerledger/ui-component-lib";
import { Trans } from "react-i18next";

export const DwollaLegalLabel = () => (
  <Trans>
    <Box sx={{ fontSize: 0, color: "textDarker" }}>
      <Text>
        By selecting Dwolla as the Payment Services Provider and to use the payments functionality on the TraceX
        Marketplace, the Applicant agrees to be bound by the Dwolla
      </Text>
      &nbsp;
      <Link
        href="https://www.dwolla.com/legal/dwolla-account-terms-of-service/#legal-content"
        target="_blank"
        sx={{
          color: "text",
          fontFamily: "inter",
          textAlign: "left",
        }}
      >
        Terms of Service
      </Link>
      &nbsp;
      <Text>and</Text>
      &nbsp;
      <Link
        href="https://www.dwolla.com/legal/privacy/#legal-content"
        target="_blank"
        sx={{
          color: "text",
          fontFamily: "inter",
          textAlign: "left",
        }}
      >
        Privacy Policy
      </Link>
      &nbsp;
      <Text>
        and authorises the Market Operator, Power Ledger, to create an account with Dwolla on their behalf. Any funds
        held in or transferred through the Applicant&apos;s Dwolla Account are held or transferred by Dwolla&apos;s
        financial institution partners. The Applicant authorizes the Market Operator, Power Ledger, to collect and share
        with Dwolla personal information the Applicant provides including full name, date of birth, social security
        number, physical address, email address and financial information, and the Applicant is responsible for the
        accuracy and completeness of that data. The Applicant understands that it will access and manage its Dwolla
        Account through the TraceX Marketplace, and Dwolla account notifications will be sent by the Market Operator,
        Power Ledger, and not Dwolla.
      </Text>
    </Box>
  </Trans>
);
