import { Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";
import { FactorProvider, FactorType, useResetFactorMutation } from "@/app/types/generated/graphql";

export const ResetFactor = ({
  factorProvider,
  factorType,
  visible,
  closeModal,
}: {
  factorProvider: FactorProvider;
  factorType: FactorType;
  visible: boolean;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();

  const [resetFactor, { loading: isSubmitting }] = useResetFactorMutation({
    refetchQueries: ["AccountEnrolledFactor"],
  });

  const resetFactorCallback = useCallback(async () => {
    try {
      await resetFactor({
        variables: {
          reset: {
            factorType,
            factorProvider,
          },
        },
      });
    } finally {
      notifications.success({
        description: t("Google Authenticator has been successfully reset"),
      });
      closeModal();
    }
  }, [closeModal, factorProvider, factorType, resetFactor, t]);

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      title={t("Are you sure you want to reset this factor?")}
      subtitle={t("This will remove this authentication factor from your account.")}
    >
      <Flex sx={{ justifyContent: "flex-end" }}>
        <Button variant="error" onClick={closeModal} sx={{ mr: 3, minWidth: 80 }} disabled={isSubmitting}>
          {t("Cancel")}
        </Button>
        <Button variant="primary" onClick={resetFactorCallback} disabled={isSubmitting}>
          {t("Proceed")}
        </Button>
        {isSubmitting && <IconLoading size="small" sx={{ ml: 2 }} />}
      </Flex>
    </Modal>
  );
};

export default ResetFactor;
