import { Flex, InputProps, useTheme } from "@powerledger/ui-component-lib";
import Cleave from "cleave.js/react";
import { FieldProps } from "formik";
import { FC } from "react";

import { useDebouncedInput } from "@/app/hooks";

const optionWithPrefix = {
  blocks: [11],
  prefix: "XXX-XX-",
  noImmediatePrefix: true,
  numericOnly: true,
};

const optionWithoutPrefix = { blocks: [3, 2, 4], delimiter: "-", numericOnly: true };
export const getValue = (withPrefix: boolean, value: string) => {
  return withPrefix ? `XXX-XX-${value}` || "XXX-XX-" : value || "";
};

export const SSNInput: FC<FieldProps & InputProps & { withPrefix?: boolean }> = ({
  withPrefix = false,
  field,
  form,
  variant,
  ...props
}) => {
  const { theme } = useTheme();
  const { handleFieldChange, localState, handleFieldBlur } = useDebouncedInput({
    onChange: field.onChange,
    name: field.name,
    value: field.value,
    onBlur: field.onBlur,
    shouldDebounce: true,
  });

  return (
    <Flex
      sx={{
        alignItems: "center",
        position: "relative",
        width: "100%",
        input: { ...theme.forms?.[variant ? variant : "input"], pl: 3, pr: 3 },
      }}
    >
      <Cleave
        options={withPrefix ? optionWithPrefix : optionWithoutPrefix}
        name={field.name}
        onChange={(args) => {
          if (form.values.passport.number) {
            form.setFieldValue("passport.number", null);
          }
          if (form.values.passport.countryCode) {
            form.setFieldValue("passport.countryCode", null);
          }
          form.setFieldValue("passport.number", null);
          const setValue = withPrefix ? args.target.value.substring(7) : args.target.value;
          handleFieldChange({
            ...args,
            target: {
              ...args.target,
              value: setValue,
            },
          });
        }}
        onBlur={handleFieldBlur}
        value={getValue(withPrefix, localState)}
        {...props}
        ref={null}
      />
    </Flex>
  );
};
