import { Box, Button, Flex, Link, useTheme, WarningLargeSVG } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useResize } from "@/app/hooks";
import { MOBILE_REDIRECT_URL } from "@/app/lib/env-helpers";

export const MobileRestrictedModal = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const { dimensions } = useResize();

  const marginPixelValue: number = useMemo(() => {
    if (!theme.space) return 0;

    const spaceValue = theme.space[4];

    return spaceValue as number;
  }, [theme.space]);

  const showMobileRestrictedModal = useMemo(
    () => (dimensions.windowWidth ? dimensions.windowWidth < 768 : false),
    [dimensions],
  );

  if (!showMobileRestrictedModal) return null;

  return (
    <Box
      sx={{
        p: 3,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        nt: "center",
        display: "flex",
        position: "fixed",
        background: "background",
      }}
    >
      <Flex
        sx={{
          m: 4,
          width: "100%",
          maxWidth: "1140px",
          textAlign: "center",
          alignItems: "center",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          maxHeight: marginPixelValue ? `calc(100vh - ${marginPixelValue}px)` : "720px",
        }}
      >
        <Box
          sx={{
            svg: {
              width: 58,
              height: 59,
            },
          }}
        >
          <WarningLargeSVG />
        </Box>
        <Box sx={{ fontWeight: "bold", mt: 22.0 }}>
          {t("Sorry, TraceX doesn't support mobile browsing at the moment.")}
        </Box>
        <Box sx={{ fontWeight: "bold", my: 3 }}>
          {t("You can still use TraceX from your desktop computer or laptop.")}
        </Box>
        <Link href={MOBILE_REDIRECT_URL} sx={{ textDecoration: "none" }}>
          <Button variant="primary">{t("Visit Powerledger.io")}</Button>
        </Link>
      </Flex>
    </Box>
  );
};
