import { FC } from "react";

import { LocationChipsProps } from "./location-chips.types";
import { LocationChipsView } from "./location-chips-view";
import { useLocationChips } from "./use-location-chips";

export const LocationChips: FC<LocationChipsProps> = ({ locations, removeValue }) => {
  const logic = useLocationChips({
    locations,
  });
  return <LocationChipsView {...logic} removeValue={removeValue} />;
};
