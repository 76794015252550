import { Flex, IconError } from "@powerledger/ui-component-lib";
import { format } from "date-fns";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { TradingHaltNotifierViewProps } from "./trading-halt-notifier.types";

export const TradingHaltNotifierView: FC<TradingHaltNotifierViewProps> = ({ tradingHaltEndDateTime }) => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{ color: "error.500", alignItems: "center", bg: tradingHaltEndDateTime ? "shadow" : "", p: 3, flexGrow: 1 }}
    >
      {tradingHaltEndDateTime && (
        <>
          <IconError size={5} color="error.500" sx={{ mr: 2 }} />
          {t("Trading is currently halted and is expected to last until {{endDateTime}} {{timeZone}}.", {
            endDateTime: format(Date.parse(tradingHaltEndDateTime), "yyyy/MM/dd p z"),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })}
        </>
      )}
    </Flex>
  );
};
