import { PrivateRoutes } from "../navigation/routes/routes.type";

export const flattenRoutes = (routes: PrivateRoutes[], parentPath = "") => {
  let flattenedRoutes: PrivateRoutes[] = [];

  for (const route of routes) {
    /** If no subroutes append the route to the array and continue */
    if (!route.subRoutes) {
      flattenedRoutes.push({
        ...route,
        /** Get full path from the root parent */
        path: parentPath + route.path,
      });
      continue;
    }
    /** Remove subRoutes from the base route */
    const { subRoutes, ...restRoute } = route;

    /** Change path to contain the parent path and append to flattenedRoutes */
    flattenedRoutes.push({ ...restRoute, path: parentPath + route.path });

    /** Perform recursively for all nested subroutes */
    if (route.subRoutes) {
      const flattenedSubRoutes = flattenRoutes(route.subRoutes, parentPath + route.path);
      flattenedRoutes = [...flattenedRoutes, ...flattenedSubRoutes];
    }
  }
  return flattenedRoutes;
};
