import { Flex, LegacyCheckbox } from "@powerledger/ui-component-lib";
import { isEqual } from "lodash";
import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { useRecOptions } from "@/app/hooks";

import { VintageHalf } from "../../types/generated/graphql";
import { VintageHalfSelectorProps } from "./vintage-half-selector.types";

export const VintageHalfSelector: FC<VintageHalfSelectorProps> = memo(
  function HalfSelector({ filterState = [], onChange, disabled = false, vintageFor = "", options }) {
    const { t } = useTranslation();
    const { recOptions } = useRecOptions();

    const opts = (options || recOptions?.vintageHalfOptions) ?? [];

    return (
      <Flex sx={{ gap: 3, ml: 1, my: 2, flexWrap: "wrap" }}>
        {opts.map((option) => (
          <LegacyCheckbox
            disabled={disabled}
            sx={{
              opacity: disabled ? 0.5 : 1,
              variant: "forms.traceXCheckbox",
            }}
            aria-label={t(`${vintageFor} ${option?.value}`)}
            key={option.value}
            label={t(option?.value || "")}
            checked={filterState?.includes(option.value as VintageHalf) || false}
            onChange={() => onChange(option.value as VintageHalf)}
          />
        ))}
      </Flex>
    );
  },
  (prev, next) => isEqual(prev, next),
);
