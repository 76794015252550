import { useFormikContext } from "formik";
import { useCallback, useMemo, useState } from "react";

import notifications from "@/app/container/notifications";
import {
  Company,
  CompanyContact,
  CompanyContactInput,
  ContactType,
  useAccountQuery,
  useDeleteCompanyContactMutation,
} from "@/app/types/generated/graphql";

import { useOnboardingContext } from "../onboarding/context";
import { UseApplicantBeneficialOwnerStepFn } from "./applicant-beneficial-owner-step.types";

export const useApplicantBeneficialOwnerStep: UseApplicantBeneficialOwnerStepFn = () => {
  const { data: accountData, loading } = useAccountQuery();
  const [submitting, setSubmitting] = useState(false);
  const [boIdToRemove, setBoIdToRemove] = useState("");
  const {
    goBack: moveBackward,
    goNext: moveForward,
    saveCurrentStage,
    setSelectedBeneficialOwner,
    stepsDisabledFieldsMap,
  } = useOnboardingContext();

  const [deleteBeneficialOwner, { loading: deleteBOSubmitting }] = useDeleteCompanyContactMutation({
    refetchQueries: ["Account"],
  });

  const company = useMemo<Company | null | undefined>(() => accountData?.account?.company, [accountData]);
  const beneficialOwners = useMemo<CompanyContact[] | undefined>(
    () => company?.contacts?.filter((contact) => contact.type === ContactType.BeneficialOwner),
    [company?.contacts],
  );
  const { resetForm, dirty } = useFormikContext<CompanyContactInput>();
  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [onSuccessCallback, setOnSuccessCallback] = useState<() => void>();

  const handleOpenBOForm = (bo?: CompanyContact) => {
    setSelectedBeneficialOwner(bo?.id);
    setShowForm(true);
  };

  const handleDeleteBo = useCallback(async () => {
    try {
      await deleteBeneficialOwner({
        variables: {
          deleteCompanyContactId: boIdToRemove,
        },
      });
    } catch (e) {
      e instanceof Error &&
        notifications?.error({
          description: e?.message,
        });
    }
  }, [boIdToRemove, deleteBeneficialOwner]);

  const saveBo = useCallback(async () => {
    try {
      setSubmitting(true);
      await saveCurrentStage();
      setShowForm?.(false);
      resetForm();
    } catch (e) {
      e instanceof Error &&
        notifications.error({
          description: e?.message,
        });
    } finally {
      setSubmitting(false);
    }
  }, [saveCurrentStage, resetForm]);

  const bosDisabled = stepsDisabledFieldsMap.Applicant_Beneficial_Owners;

  const checkBeneficialOwners = useCallback(
    (callback?: () => void) => {
      if (
        (!beneficialOwners || beneficialOwners?.length < 1) &&
        !stepsDisabledFieldsMap.Applicant_Beneficial_Owners.length
      ) {
        setShowModal(true);
        setOnSuccessCallback(() => callback);
      } else if (callback) callback();
    },
    [beneficialOwners, stepsDisabledFieldsMap.Applicant_Beneficial_Owners.length],
  );

  const goBack = useCallback(() => {
    checkBeneficialOwners(moveBackward);
  }, [checkBeneficialOwners, moveBackward]);

  const goNext = useCallback(() => {
    checkBeneficialOwners(moveForward);
  }, [checkBeneficialOwners, moveForward]);

  return {
    showForm,
    setShowForm,
    saveBo,
    dirty,
    submitting,
    goBack,
    goNext,
    boIdToRemove,
    handleOpenBOForm,
    company,
    loading,
    setBoIdToRemove,
    bosDisabled,
    handleDeleteBo,
    deleteBOSubmitting,
    showModal,
    setShowModal,
    onSuccessCallback,
  };
};
