import { Flex } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { RequiredDocuments } from "@/app/pages/settings/payment-services-tab/required-documents";

import { useOnboardingContext } from "../onboarding/context";
import { StepButton } from "../step-button";
import { StepLayout } from "../step-layout";

export default function RequiredDocumentationStep() {
  const { goBack, goNext, stepsDisabledFieldsMap } = useOnboardingContext();
  const { t } = useTranslation();

  return (
    <StepLayout
      heading={t("Required Documentation")}
      disclaimer={t("Please upload the following supporting documentation to process the application.")}
      id="RequiredDocuments"
      actions={
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StepButton back onClick={() => goBack()} />
          <StepButton onClick={() => goNext()} />
        </Flex>
      }
    >
      <RequiredDocuments disableReUpload={stepsDisabledFieldsMap.Required_Documentation} />
    </StepLayout>
  );
}
