import { Box, Button, Flex, IconDownload, Modal } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export type LegalDocumentModalProps = {
  visible: boolean;
  content?: JSX.Element;
  closeModal: () => void;
  accept?: () => void;
  download?: string;
};

export const LegalDocumentModal: FC<LegalDocumentModalProps> = ({ visible, content, closeModal, accept, download }) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible} maskClosable onCancel={closeModal} sx={{ p: 4 }}>
      {download && (
        <Flex sx={{ justifyContent: "flex-end", mt: 4 }}>
          <a href={download} download target="_blank" rel="noreferrer">
            <Flex
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
                color: "accent",
                textDecoration: "underline",
                textDecorationColor: "primary",
                "&:visited": { color: "accent", textDecorationColor: "accent" },
              }}
            >
              <IconDownload size={40} />
              {t("Download")}
            </Flex>
          </a>
        </Flex>
      )}
      <Box
        sx={{
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        {content}
      </Box>
      {accept && (
        <Flex sx={{ justifyContent: "flex-end", mt: 4 }}>
          <Button variant="primary" onClick={accept}>
            {t("Accept")}
          </Button>
        </Flex>
      )}
    </Modal>
  );
};
