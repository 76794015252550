import { Box, Heading } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { ResourceGrid } from "./components";
import { getExpiredResources, getResources } from "./helpers";
import { UserResourcesProps } from "./user-resources.types";

export const UserResourcesView: React.FC<UserResourcesProps> = ({ showHeader, isAuthenticated = false, isAdmin }) => {
  const { t } = useTranslation();

  return (
    <Box as="main" sx={{ m: 4 }}>
      {showHeader && <Heading sx={{ variant: "texts.tracexDefaultHeading", mb: 4 }}>{t("User Resources")}</Heading>}
      <ResourceGrid
        data={getResources({
          authenticated: isAuthenticated,
        })}
      />

      {/* Expired Resources */}
      {isAuthenticated && isAdmin && (
        <>
          <Box sx={{ mb: 4 }}>
            <Heading sx={{ variant: "texts.tracexDefaultHeading" }}>{t("Superceded")}</Heading>
          </Box>
          <ResourceGrid data={getExpiredResources()} />
        </>
      )}
    </Box>
  );
};
