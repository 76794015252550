import { useMemo, useState } from "react";

import { SignupStepperStages } from "@/app/lib/account-service-helpers";
import { requiredDocTypeNameMap } from "@/app/pages/settings/payment-services-tab/required-documents/document-block/document-block-helpers";
import { getContactName } from "@/app/pages/settings/payment-services-tab/required-documents/use-required-documents";
import {
  AccountStatus,
  ApprovalStatus,
  ContactType,
  DocumentStatus,
  useAccountQuery,
} from "@/app/types/generated/graphql";

import { ErrorDisplayPopperViewProps, ErrorMap, ErrorsMapValue, UseErrorDisplayFn } from "./error-display-popper.types";

export const isErrorMap = (val: ErrorsMapValue): val is ErrorMap => typeof val !== "string" && !!val.errors;

/**
 * Checks if the error map or nested objects has a value or not
 */
export const checkNestedErrorsInErrorMap = (errorsMapValue: ErrorsMapValue): boolean => {
  if (typeof errorsMapValue === "string") {
    return !!errorsMapValue;
  }
  if (isErrorMap(errorsMapValue)) {
    return !!errorsMapValue.errors;
  }
  for (const ob in errorsMapValue) {
    if (errorsMapValue[ob]) {
      if (typeof errorsMapValue[ob] === "string") return true;
      const value = checkNestedErrorsInErrorMap(errorsMapValue[ob]);
      if (value) return value;
    }
  }
  return false;
};

const isStepEdited = (
  approvalStatus?: ApprovalStatus,
  documentStatus: DocumentStatus | null = DocumentStatus.Uploaded,
) => documentStatus === DocumentStatus.Uploaded && approvalStatus === ApprovalStatus.NotRequested;

export const useErrorDisplayPopper: UseErrorDisplayFn = () => {
  const { data: accountData } = useAccountQuery();

  const [showMenu, setShowMenu] = useState(false);

  const controller = useMemo(
    () => accountData?.account?.company?.contacts?.find((contact) => contact.type === ContactType.Controller),
    [accountData?.account?.company?.contacts],
  );
  const beneficialOwners = useMemo(
    () => accountData?.account?.company?.contacts?.filter((contact) => contact.type === ContactType.BeneficialOwner),
    [accountData?.account?.company?.contacts],
  );

  const companyDocs = useMemo(
    () => accountData?.account?.company?.documents,
    [accountData?.account?.company?.documents],
  );

  const controllerDocs = useMemo(() => controller?.documents, [controller?.documents]);

  const beneficialOwnerDocs = useMemo(
    () => beneficialOwners?.flatMap((bo) => bo.documents?.map((doc) => ({ ...doc, holder: bo })))?.flat(1) ?? [],
    [beneficialOwners],
  );

  const errorsMap: ErrorDisplayPopperViewProps["errorsMap"] = {
    [SignupStepperStages.ApplicantDetails]: {
      errors: accountData?.account?.company?.approvalState?.errorMessage ?? "",
      edited: isStepEdited(accountData?.account?.company?.approvalState?.status),
    },
    [SignupStepperStages.ApplicantControllerDetails]: {
      errors: controller?.approvalState?.errorMessage ?? "",
      edited: isStepEdited(controller?.approvalState?.status),
    },
    [SignupStepperStages.ApplicantBeneficialOwners]:
      beneficialOwners?.reduce((acc, bo) => {
        const errors = bo.approvalState?.errorMessage ?? "";
        if (errors)
          acc[getContactName(bo)] = {
            errors,
            edited: isStepEdited(bo.approvalState?.status),
          };
        return acc;
      }, {} as Record<string, ErrorMap>) ?? {},
    [SignupStepperStages.RequiredDocumentation]: {
      ["Company Documents"]:
        companyDocs?.reduce((acc, doc) => {
          const errors = doc.approvalState?.errorMessage ?? "";
          if (errors)
            acc[`${doc.fileName} (${doc.type})`] = {
              errors,
              edited: isStepEdited(doc.approvalState?.status, doc.status),
            };
          return acc;
        }, {} as Record<string, ErrorMap>) ?? "",
      ["Controller Documents"]:
        controllerDocs?.reduce((acc, doc) => {
          const errors = doc.approvalState?.errorMessage ?? "";
          if (errors)
            acc[`${getContactName(controller)}'s ${requiredDocTypeNameMap[doc.type]?.toUpperCase()}`] = {
              errors,
              edited: isStepEdited(doc.approvalState?.status, doc.status),
            };
          return acc;
        }, {} as Record<string, ErrorMap>) ?? "",
      ["Beneficial Owner Documents"]:
        beneficialOwnerDocs?.reduce((acc, doc) => {
          const errors = doc?.approvalState?.errorMessage ?? "";
          if (errors)
            acc[
              `BENEFICIAL OWNER ${getContactName(doc?.holder)}'s ${requiredDocTypeNameMap[
                doc?.type ?? "ID_CARD"
              ]?.toUpperCase()}`
            ] = {
              errors,
              edited: isStepEdited(doc?.approvalState?.status, doc?.status),
            };
          return acc;
        }, {} as Record<string, ErrorMap>) ?? "",
    },
  };

  const showErrors = accountData?.account?.status === AccountStatus.Retry;

  return {
    showMenu,
    setShowMenu,
    errorsMap,
    showErrors,
  };
};
