/** Fed in by our Kubernetes configmap */
export interface TraceXKubernetesConfigMapAppConfig {
  OKTA_CLIENT_ID: string;
  OKTA_ISSUER: string;
  OKTA_BASE_URL: string;
  APOLLO_GRAPH_VARIANT: string;
  FEDERATED_GATEWAY_URL: string;
  USE_GRAPHQL_DEMO_DATA: string;
  PLAID_LINK_ENV: string;
  USER_GUIDE_URL: string;
  PRIVACY_POLICY_URL: string;
  TERMS_OF_ACCESS_URL: string;
  TRACEX_RULES_URL: string;
  NOTIFICATION_URL_EXPIRED: string;
  TRACEX_RULES_URL_EXPIRED: string;
  AUTHORIZED_REPRESENTATIVE_APPLICATION_FORM_URL: string;
  AUTHORIZED_REPRESENTATIVE_APPLICATION_FORM_URL_EXPIRED: string;
  NOTIFICATION_URL: string;
  MOBILE_REDIRECT_URL: string;
  CONFIGCAT_SDK_KEY: string;
  CONFIGCAT_OVERRIDES: string;
  CONFIGCAT_CACHE_TTL: string;
  LOCIZE_PROJECTID: string;
  LOCIZE_APIKEY: string;
  LOCIZE_REFLNG: string;
  LOCIZE_VERSION: string;
  FAQ_URL: string;
  MAPBOX_ACCESS_TOKEN: string;
  ENABLE_WDYR: string;
  ENABLE_HOTJAR_TRACKING: string;
  HOTJAR_ID: string;
  HOTJAR_SNIPPET_VERSION: string;
  SENTRY_DSN: string;
  ENABLE_SENTRY_TRACKING: string;
  DOCUMENT_DECRYPTION_URL: string;
  ENABLE_GA_TRACKING: string;
  GA_MEASUREMENT_ID: string;
}

declare global {
  interface Window {
    appConfig: TraceXKubernetesConfigMapAppConfig;
  }
}

/**
 * Convert environment variables with strings 'true' or 'false' to their respective booleans
 * @param val an environment variable
 */
const parseBoolEnvVar = (val: string | undefined) => {
  if (val) {
    if (val.toLocaleLowerCase() === "true") return true;
    if (val.toLocaleLowerCase() === "false") return false;
  }
  return undefined;
};

const DEFAULT_JSON = "{}";
/**
 * Convert environment variables with strings in JSON format into an object
 * If the variable is undefined (not in .env) or not valid JSON, an empty object is returned
 * @param val
 */
const parseJsonEnvVar = (val: string | undefined) => {
  if (!val) {
    val = DEFAULT_JSON;
  }

  try {
    return JSON.parse(String(val));
  } catch (e) {
    return JSON.parse(DEFAULT_JSON);
  }
};

const envVarPrefix = import.meta.env.STORYBOOK ? "STORYBOOK" : "VITE";
// Pull in the environment variable from the build config (.env file)
const buildConfig = {
  AUTH_SERVER_URL: import.meta.env[`${envVarPrefix}_AUTH_SERVER_URL`],
  CLIENT_ID: import.meta.env[`${envVarPrefix}_CLIENT_ID`],
  CLIENT_SECRET: import.meta.env[`${envVarPrefix}_CLIENT_SECRET`],
  OKTA_ISSUER: import.meta.env[`${envVarPrefix}_OKTA_ISSUER`],
  OKTA_CLIENT_ID: import.meta.env[`${envVarPrefix}_OKTA_CLIENT_ID`],
  OKTA_BASE_URL: import.meta.env[`${envVarPrefix}_OKTA_BASE_URL`],
  FEDERATED_GATEWAY_URL: import.meta.env[`${envVarPrefix}_FEDERATED_GATEWAY_URL`],
  PLAID_LINK_ENV: import.meta.env[`${envVarPrefix}_PLAID_LINK_ENV`],
  USER_GUIDE_URL: import.meta.env[`${envVarPrefix}_USER_GUIDE_URL`],
  FAQ_URL: import.meta.env[`${envVarPrefix}_FAQ_URL`],
  PRIVACY_POLICY_URL: import.meta.env[`${envVarPrefix}_PRIVACY_POLICY_URL`],
  TERMS_OF_ACCESS_URL: import.meta.env[`${envVarPrefix}_TERMS_OF_ACCESS_URL`],
  TRACEX_RULES_URL: import.meta.env[`${envVarPrefix}_RULES_URL`],
  TRACEX_RULES_URL_EXPIRED: import.meta.env[`${envVarPrefix}_RULES_URL_EXPIRED`],
  NOTIFICATION_URL: import.meta.env[`${envVarPrefix}_NOTIFICATION_URL`],
  NOTIFICATION_URL_EXPIRED: import.meta.env[`${envVarPrefix}_NOTIFICATION_URL_EXPIRED`],
  AUTHORIZED_REPRESENTATIVE_APPLICATION_FORM_URL: import.meta.env[
    `${envVarPrefix}_AUTHORIZED_REPRESENTATIVE_APPLICATION_FORM_URL`
  ],
  DOCUMENT_DECRYPTION_URL: import.meta.env[`${envVarPrefix}_DOCUMENT_DECRYPTION_URL`],
  AUTHORIZED_REPRESENTATIVE_APPLICATION_FORM_URL_EXPIRED: import.meta.env[
    `${envVarPrefix}_AUTHORIZED_REPRESENTATIVE_APPLICATION_FORM_URL_EXPIRED`
  ],
  MOBILE_REDIRECT_URL: import.meta.env[`${envVarPrefix}_MOBILE_REDIRECT_URL`],
  CONFIGCAT_SDK_KEY: import.meta.env[`${envVarPrefix}_CONFIGCAT_SDK_KEY`],
  CONFIGCAT_OVERRIDES: parseJsonEnvVar(import.meta.env[`${envVarPrefix}_CONFIGCAT_OVERRIDES`]),
  CONFIGCAT_CACHE_TTL: import.meta.env[`${envVarPrefix}_CONFIGCAT_CACHE_TTL`],
  LOCIZE_PROJECTID: import.meta.env[`${envVarPrefix}_LOCIZE_PROJECTID`],
  LOCIZE_APIKEY: import.meta.env[`${envVarPrefix}_LOCIZE_APIKEY`],
  MAPBOX_ACCESS_TOKEN: import.meta.env[`${envVarPrefix}_MAPBOX_ACCESS_TOKEN`],
  LOCIZE_REFLNG: import.meta.env[`${envVarPrefix}_LOCIZE_REFLNG`],
  LOCIZE_VERSION: import.meta.env[`${envVarPrefix}_LOCIZE_VERSION`],
  HOTJAR_ID: import.meta.env[`${envVarPrefix}_HOTJAR_ID`],
  HOTJAR_SNIPPET_VERSION: import.meta.env[`${envVarPrefix}_HOTJAR_SNIPPET_VERSION`],
  SENTRY_DSN: import.meta.env[`${envVarPrefix}_SENTRY_DSN`],
  APOLLO_GRAPH_VARIANT: import.meta.env[`${envVarPrefix}_APOLLO_GRAPH_VARIANT`],
  GA_MEASUREMENT_ID: import.meta.env[`${envVarPrefix}_GA_MEASUREMENT_ID`],
  ENABLE_SENTRY_TRACKING: parseJsonEnvVar(import.meta.env[`${envVarPrefix}_ENABLE_SENTRY_TRACKING`]),

  NODE_ENV: import.meta.env.NODE_ENV,

  ENABLE_WDYR: parseBoolEnvVar(import.meta.env[`${envVarPrefix}_ENABLE_WDYR`]),
  USE_GRAPHQL_DEMO_DATA: parseBoolEnvVar(import.meta.env[`${envVarPrefix}_USE_GRAPHQL_DEMO_DATA`]),
  IS_TEST: parseBoolEnvVar(import.meta.env[`${envVarPrefix}_IS_TEST`]),
  ENABLE_HOTJAR_TRACKING: parseBoolEnvVar(import.meta.env[`${envVarPrefix}_ENABLE_HOTJAR_TRACKING`]),
  ENABLE_GA_TRACKING: parseBoolEnvVar(import.meta.env[`${envVarPrefix}_ENABLE_GA_TRACKING`]),

  secret: "#P0w3r_L3dg3r53124",
};

// Swap out any build environment variables with what is in the Kubernetes configmap.
// Variables are defined and found in the tracex-kubernetes repo's patch-configmap.yaml files.
if (window.appConfig) {
  Object.assign(buildConfig, Object.fromEntries(Object.entries(window.appConfig).filter(([_, value]) => !!value)));
}

export const AUTH_SERVER_URL = buildConfig.AUTH_SERVER_URL;
export const CLIENT_SECRET = buildConfig.CLIENT_SECRET;
export const SECRET = buildConfig.secret;
export const OKTA_ISSUER = buildConfig.OKTA_ISSUER;
export const OKTA_CLIENT_ID = buildConfig.OKTA_CLIENT_ID;
export const OKTA_BASE_URL = buildConfig.OKTA_BASE_URL;
export const FEDERATED_GATEWAY_URL = buildConfig.FEDERATED_GATEWAY_URL;
export const USE_GRAPHQL_DEMO_DATA = buildConfig.USE_GRAPHQL_DEMO_DATA;
export const PLAID_LINK_ENV = buildConfig.PLAID_LINK_ENV;
export const USER_GUIDE_URL = buildConfig.USER_GUIDE_URL;
export const FAQ_URL = buildConfig.FAQ_URL;
export const PRIVACY_POLICY_URL = buildConfig.PRIVACY_POLICY_URL;
export const TERMS_OF_ACCESS_URL = buildConfig.TERMS_OF_ACCESS_URL;
export const NOTIFICATION_URL = buildConfig.NOTIFICATION_URL;
export const TRACEX_RULES_URL = buildConfig.TRACEX_RULES_URL;
export const TRACEX_RULES_URL_EXPIRED = buildConfig.TRACEX_RULES_URL_EXPIRED;
export const NOTIFICATION_URL_EXPIRED = buildConfig.NOTIFICATION_URL_EXPIRED;
export const AUTHORIZED_REPRESENTATIVE_APPLICATION_FORM_URL =
  buildConfig.AUTHORIZED_REPRESENTATIVE_APPLICATION_FORM_URL;
export const AUTHORIZED_REPRESENTATIVE_APPLICATION_FORM_URL_EXPIRED =
  buildConfig.AUTHORIZED_REPRESENTATIVE_APPLICATION_FORM_URL_EXPIRED;
export const MOBILE_REDIRECT_URL = buildConfig.MOBILE_REDIRECT_URL;
export const CONFIGCAT_SDK_KEY = String(buildConfig.CONFIGCAT_SDK_KEY);
export const CONFIGCAT_OVERRIDES = buildConfig.CONFIGCAT_OVERRIDES;
export const CONFIGCAT_CACHE_TTL = buildConfig.CONFIGCAT_CACHE_TTL;
export const LOCIZE_PROJECTID = buildConfig.LOCIZE_PROJECTID;
export const LOCIZE_APIKEY = buildConfig.LOCIZE_APIKEY;
export const LOCIZE_REFLNG = buildConfig.LOCIZE_REFLNG;
export const LOCIZE_VERSION = buildConfig.LOCIZE_VERSION;
export const NODE_ENV = buildConfig.NODE_ENV;
export const IS_TEST = buildConfig.IS_TEST;
export const ENABLE_WDYR = buildConfig.ENABLE_WDYR;
export const MAPBOX_ACCESS_TOKEN = buildConfig.MAPBOX_ACCESS_TOKEN;
export const ENABLE_HOTJAR_TRACKING = buildConfig.ENABLE_HOTJAR_TRACKING;
export const HOTJAR_ID = buildConfig.HOTJAR_ID;
export const HOTJAR_SNIPPET_VERSION = buildConfig.HOTJAR_SNIPPET_VERSION;
export const SENTRY_DSN = buildConfig.SENTRY_DSN;
export const ENABLE_SENTRY_TRACKING = buildConfig.ENABLE_SENTRY_TRACKING;
export const APOLLO_GRAPH_VARIANT = buildConfig.APOLLO_GRAPH_VARIANT;
export const DOCUMENT_DECRYPTION_URL = buildConfig.DOCUMENT_DECRYPTION_URL;
export const ENABLE_GA_TRACKING = buildConfig.ENABLE_GA_TRACKING;
export const GA_MEASUREMENT_ID = buildConfig.GA_MEASUREMENT_ID;
