import { Flex, IconCircleTick, IconLoadingOutlined, IconPending, Text } from "@powerledger/ui-component-lib";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { checkNestedApprovedStatus, hasMissingDetails } from "@/app/lib/account-service-helpers";
import { Account, AccountStatus, useAccountQuery } from "@/app/types/generated/graphql";

import { ApplicationStatusStepIcon } from "./application-status-step-icon";

export const ApplicationStatus = ({ refetchQuery = false }: { refetchQuery?: boolean }) => {
  const { t } = useTranslation();
  const { data, loading, refetch } = useAccountQuery();
  useEffect(() => {
    refetchQuery && refetch && refetch();
  }, [refetchQuery, refetch]);
  const collectingDetailsIcon = useMemo(
    () =>
      hasMissingDetails(data?.account as Account) ? (
        <IconPending size={5} color="warning.500" sx={{ flexShrink: 0 }} />
      ) : (
        <IconCircleTick size={5} color="primary.500" sx={{ flexShrink: 0 }} />
      ),
    [data?.account],
  );

  const processingIcon = useMemo(() => {
    if (data?.account?.status === AccountStatus.Active)
      return <IconCircleTick size={5} color="primary.500" sx={{ flexShrink: 0 }} />;

    if (
      !hasMissingDetails(data?.account as Account) &&
      (data?.account?.status === AccountStatus.AwaitingReverification ||
        data?.account?.status === AccountStatus.AwaitingVerification)
    )
      return <IconPending size={5} color="warning.500" sx={{ flexShrink: 0 }} />;

    return <ApplicationStatusStepIcon label={t("2")} />;
  }, [t, data?.account]);

  const verifiedIcon = useMemo(() => {
    if (data?.account?.status === AccountStatus.Active) {
      if (checkNestedApprovedStatus(data?.account as Account))
        return <IconCircleTick size={5} color="primary.500" sx={{ flexShrink: 0 }} />;
      if (!hasMissingDetails(data?.account as Account))
        return <IconPending size={5} color="warning.500" sx={{ flexShrink: 0 }} />;
    }
    return <ApplicationStatusStepIcon label={t("3")} />;
  }, [t, data?.account]);

  return (
    <Flex
      sx={{
        alignItems: "center",
        fontSize: 1,
        color: "textDarker",
        width: "100%",
        ml: -1,
      }}
    >
      {loading ? (
        <IconLoadingOutlined />
      ) : (
        <>
          <Flex sx={{ alignItems: "center" }}>
            {collectingDetailsIcon}
            <Text>{t("Collecting details")}</Text>
          </Flex>
          <Flex sx={{ alignItems: "center", pl: 5 }}>
            {processingIcon}
            <Text>{t("Processing")}</Text>
          </Flex>
          <Flex sx={{ alignItems: "center", pl: 5 }}>
            {verifiedIcon}
            <Text>{t("Verified")}</Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};
