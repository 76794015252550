import { useOktaAuth } from "@okta/okta-react";
import { useFormikContext } from "formik";
import { startCase } from "lodash";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { ModalContext } from "@/app/modal-provider";
import { AccountDetailsInput, AccountType, useAccountQuery } from "@/app/types/generated/graphql";

import { useOnboardingContext } from "../onboarding/context";
import { UseAccountTypeSelectionFn } from "./account-type-selection.types";

export const useAccountTypeSelection: UseAccountTypeSelectionFn = () => {
  const { goNext, saveCurrentStage } = useOnboardingContext();
  const { t } = useTranslation();
  const { oktaAuth } = useOktaAuth();
  const { data: accountData, loading } = useAccountQuery();

  const { showConfirmationModal } = useContext(ModalContext);

  const { setFieldValue, values } = useFormikContext<AccountDetailsInput>();

  const handleAccountTypeSelect = useCallback(
    (type: AccountType) => {
      setFieldValue("type", type);

      // Handles the case where the user first selects 'Non-Registry', checks the terms, and then switches to 'Registry'
      if (type === AccountType.Registry) {
        setFieldValue("nrmTermsAccepted", false);
      }
    },
    [setFieldValue],
  );

  const handleAccountTypeCheck = useCallback(
    (termsAccepted: boolean) => {
      setFieldValue("nrmTermsAccepted", termsAccepted);
    },
    [setFieldValue],
  );

  const canSelect = !accountData?.account?.type;
  const canToggleCheck = !accountData?.account?.nrmTermsAcceptedDate;

  const goForward = () => {
    if (!canSelect) {
      goNext();
      return;
    }
    showConfirmationModal({
      onSubmit: async () => {
        await saveCurrentStage();
        goNext();
        oktaAuth.tokenManager.renew("accessToken");
      },
      title: t("Confirm account type selection"),
      subtitle: t(`Please note that you have selected {{type}} account type and cannot be changed later.`, {
        type: startCase(values.type?.toLowerCase()),
      }),
      messages: {
        successMessage: t("Selected account type {{type}}", { type: startCase(values.type?.toLowerCase()) }),
      },
    });
  };

  return {
    goForward,
    selected: values.type ?? accountData?.account?.type,
    handleAccountTypeSelect,
    canSelect,
    loading,
    checked: values.nrmTermsAccepted ?? Boolean(accountData?.account?.nrmTermsAcceptedDate),
    handleAccountTypeCheck,
    canToggleCheck,
  };
};
