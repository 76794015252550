import { FormikHelpers } from "formik";

import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { RetirementCategory } from "@/app/types/generated/graphql";

import { RecHoldingsTableData } from "../../rec-holdings";
import { RetirementType } from "../../retire/retire.types";

export type ActionValues = { values: Array<RecHoldingsTableData & { actionVolume: number }> };

type RetireActionValues = {
  category: RetirementCategory;
  type: RetirementType;
  compliancePeriod: string | null;
  reason: string | null;
  notes: string | null;
} & ActionValues;

export type RecActionValues<T extends "retire" | "export"> = T extends "retire" ? RetireActionValues : ActionValues;

export const isRetireValues = (value: any): value is RetireActionValues => !!value?.category;

export type RecActionSummaryProps<T extends "retire" | "export"> = {
  action: T;
  data: RecActionValues<T>;
  recOptions?: RECOrderAttributeOptions;
  onProceed: (values: RecActionValues<T>, helpers?: FormikHelpers<RecActionValues<T>>) => Promise<void>;
  onGoBack: () => void;
  infoText?: string;
  buttonActionText?: string;
};
