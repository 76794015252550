import { AutofillRetrieveResponse } from "@mapbox/search-js-core/dist";
import { AddressAutofill } from "@mapbox/search-js-react";
import { Box, getColor, Theme, useTheme } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { APOLLO_GRAPH_VARIANT, MAPBOX_ACCESS_TOKEN } from "../../lib/env-helpers";
import { AddressAutoCompleteProps } from "./address-form-section.type";

export const mapBoxThemeVariable = (theme: Theme) => ({
  colorSecondary: getColor(theme, "background"),
  colorText: getColor(theme, "text"),
  colorBackgroundHover: getColor(theme, "textLight"),
  colorBackground: getColor(theme, "background"),
});
const allowSuggestion = APOLLO_GRAPH_VARIANT === "production" || APOLLO_GRAPH_VARIANT === "sandbox";
export const AddressAutoComplete: FC<AddressAutoCompleteProps> = ({ children, receiveAddress, country }) => {
  const { theme } = useTheme();
  return (
    <Box>
      <AddressAutofill
        accessToken={MAPBOX_ACCESS_TOKEN!}
        onRetrieve={(e: AutofillRetrieveResponse) => {
          receiveAddress(e?.features[0]?.properties);
        }}
        options={{
          country: country || "US",
        }}
        theme={{
          variables: mapBoxThemeVariable(theme),
        }}
        interceptSearch={(text) => {
          if (!allowSuggestion) return "";
          return text;
        }}
      >
        {children}
      </AddressAutofill>
    </Box>
  );
};
