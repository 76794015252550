import { ReactElement } from "react";
import { Box } from "theme-ui";

import { ReactComponent as DwollaLogo } from "../../assets/images/dwollaLogo.svg";
import { ReactComponent as PlaidLogoHorizontal } from "../../assets/images/plaidLogoHorizontal.svg";
import { DwollaLegalLabel } from "../pages/settings/payment-services-tab/select-provider/dwolla-legal-label";
import { PaymentMethodCode } from "../types/generated/graphql";

export const PaymentMethodLegalContentMap: Record<PaymentMethodCode, JSX.Element> = {
  DWOLLA_PLAID: <DwollaLegalLabel />,
  ICX: <></>,
};

export const PaymentProviderIconsMap: Record<string, ReactElement> = {
  DWOLLA: (
    <Box
      sx={{
        "svg>path:not(:nth-child(3))": {
          fill: "text",
        },
      }}
    >
      <DwollaLogo />
    </Box>
  ),
};
export const PaymentMethodIconsMap: Record<PaymentMethodCode, ReactElement> = {
  DWOLLA_PLAID: (
    <Box
      sx={{
        "svg>path:not(:nth-child(3))": {
          fill: "text",
        },
      }}
    >
      <PlaidLogoHorizontal />
    </Box>
  ),
  ICX: <></>,
};
