import { FormikHelpers, FormikProps } from "formik";

import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";

import { RecActionValues } from "../common/rec-action-summary/rec-action-summary.types";
import { RecHoldingsTableData } from "../rec-holdings";

export type useExportRecsFn = () => {
  loading: boolean;
  recOptions?: RECOrderAttributeOptions;
  tableData: RecHoldingsTableData[];
  formikProps: Partial<FormikProps<ExportRecsFormValues>>;
  onProceed: (values: ExportRecsFormValues, helpers?: FormikHelpers<ExportRecsFormValues>) => Promise<void>;
  onGoBack: () => void;
  currentStep: ExportRecsLifeCycle;
};

export enum ExportRecsLifeCycle {
  Form = "FORM",
  Summary = "SUMMARY",
  Success = "SUCCESS",
}

export type ExportRecsFormValues = RecActionValues<"export">;

export type ExportRecsViewProps = ReturnType<useExportRecsFn>;
