import { Company, CompanyContact, Document } from "@/app/types/generated/graphql";

export enum MinDocumentStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Error = "ERROR",
}

export type DocumentBlockProps = {
  requiredDocument?: Document | null;
  documentOf?: Company | CompanyContact | null;
  titlePrefix?: string;
  onUpload?: () => void;
  showStatusText?: boolean;
  noEdit?: boolean;
};
