/**
 * Props to offset datepicker popper
 * Helps in proper popper positioning in Modals due to the zIndex properties in modals
 */
export const popperModifierProps = {
  popperModifiers: [
    {
      name: "offset",
      options: {
        offset: [5, 10],
      },
    },
    {
      name: "preventOverflow",
      options: {
        rootBoundary: "viewport",
        tether: false,
        altAxis: true,
      },
    },
  ],
};
