import { Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";

import { ConfirmRemoveUserModalProps } from "./confirm-remove-user-modal.types";

export const ConfirmRemoveUserModal: React.FC<ConfirmRemoveUserModalProps> = ({ visible, closeModal, onConfirm }) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const confirmSubmit = async () => {
    try {
      setSubmitting(true);
      await onConfirm();
      closeModal();
    } catch (e) {
      e instanceof Error &&
        notifications.error({
          description: e?.message,
        });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      visible={visible}
      hideCloseButton
      sx={{
        fontSize: 2,
      }}
      title={t("Are you sure you want to remove this user?")}
    >
      <Flex sx={{ justifyContent: "flex-end", mt: 4 }}>
        <Button variant="error" onClick={closeModal} sx={{ mr: 3, minWidth: 80 }} disabled={submitting}>
          {t("Cancel")}
        </Button>
        <Button variant="primary" onClick={confirmSubmit} disabled={submitting}>
          {t("Proceed")}
        </Button>
        {submitting && <IconLoading size="small" sx={{ ml: 2 }} />}
      </Flex>
    </Modal>
  );
};
