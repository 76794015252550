import { Button, Flex, IconLoading, Modal, Text } from "@powerledger/ui-component-lib";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import notificationsDisplay from "../../container/notifications";
import { RemoveBeneficialOwnerModalProps } from "./beneficial-owners.types";

export const RemoveBeneficialOwnerModal: FC<RemoveBeneficialOwnerModalProps> = ({
  visible,
  handleDeleteBo,
  loading = false,
  closeModal,
}) => {
  const { t } = useTranslation();

  const confirm = useCallback(async () => {
    try {
      await handleDeleteBo();
      closeModal();
    } catch (error: unknown) {
      if (error instanceof Error) {
        notificationsDisplay.error({
          description: t(error?.message),
        });
      }
    }
  }, [closeModal, handleDeleteBo, t]);

  return (
    <Modal visible={visible} maskClosable onCancel={closeModal} hideCloseButton sx={{ p: 4 }}>
      <Text>{t("Are you sure you want to remove this beneficial owner?")}</Text>
      <Flex sx={{ justifyContent: "flex-end", mt: 4 }}>
        <Button variant="error" onClick={closeModal} sx={{ mr: 3, minWidth: 80 }} disabled={loading}>
          {t("Cancel")}
        </Button>
        <Button variant="primary" onClick={confirm} disabled={loading}>
          {t("Proceed")}
        </Button>
        {loading && <IconLoading size="small" sx={{ ml: 2 }} />}
      </Flex>
    </Modal>
  );
};
