import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as FundingSourceComponents from "./funding-source-components";
import { LinkedFundingSourceProps } from "./linked-funding-source.types";

export const LinkedFundingSource: FC<LinkedFundingSourceProps> = ({ typeName, linkedPaymentMethod }) => {
  const { t } = useTranslation();
  const ResolvedComponent = useMemo(() => {
    if (typeName) {
      return FundingSourceComponents[typeName];
    }
    return null;
  }, [typeName]);

  return (
    <>
      {ResolvedComponent ? (
        <ResolvedComponent linkedPaymentMethod={linkedPaymentMethod}></ResolvedComponent>
      ) : (
        t("Error loading funding source")
      )}
    </>
  );
};
