import { IconProps } from "@powerledger/ui-component-lib";

import { AccountType } from "@/app/types/generated/graphql";

export enum SettingsTabs {
  details = "details",
  paymentServices = "paymentServices",
  company = "company",
  registry = "registry",
}
export type SettingTab = {
  title: string;
  IconComponent?: React.FC<IconProps> | null;
  key: string;
  component: JSX.Element;
};

export type UseSettingsFn = () => {
  switchTab: (tab: string) => void;
  currentTab: SettingsTabs;
  paymentServicesIcon: React.FC<IconProps> | null;
  registryIcon: React.FC<IconProps> | null;
  companyDetailsIconName: string | null;
  isAdmin: boolean;
  accountType?: AccountType | null;
};

export type SettingsViewProps = ReturnType<UseSettingsFn>;
