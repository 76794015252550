import * as Yup from "yup";

import { DocumentStatus } from "@/app/types/generated/graphql";

import { approvalStatusSchema } from "./approvalStatus";

/**
 * Validation Schema for Document Status
 */
export const documentSchema = Yup.object().shape({
  status: Yup.string().oneOf([DocumentStatus.Uploaded]),
  approvalStatus: approvalStatusSchema,
});
