import { ApolloError } from "@apollo/client";
import { useState } from "react";

import { formatRecOptions, RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import {
  AccountStatus,
  useFewAccountDetailsQuery,
  useRecOrderAttributesOptionsQuery,
} from "@/app/types/generated/graphql";

export const useRecOptions = (args?: { skipQuery?: boolean }) => {
  const [formattedRecOptions, setFormattedRecOptions] = useState<RECOrderAttributeOptions>();

  const {
    data: accountData,
    loading: accountLoading,
    error: accountFetchError,
  } = useFewAccountDetailsQuery({
    skip: args?.skipQuery ?? false,
  });
  const [accumulatedErrors, setAccumulatedErrors] = useState<ApolloError | Error | undefined>(accountFetchError);

  const { loading: assetsLoading } = useRecOrderAttributesOptionsQuery({
    skip: !accountData?.account?.tenantId || accountData?.account?.status !== AccountStatus.Active,
    variables: {
      tenantId: accountData?.account?.tenantId,
    },
    onCompleted: (data) => {
      try {
        const formattedOptions = formatRecOptions(data?.assets);
        setFormattedRecOptions(formattedOptions);
      } catch (error) {
        if (error instanceof Error) {
          setAccumulatedErrors(error);
        }
      }
    },
    onError: (error) => {
      setAccumulatedErrors(error);
    },
  });

  if (accumulatedErrors) {
    throw accumulatedErrors;
  }
  return {
    accountData,
    recOptions: formattedRecOptions,
    loading: assetsLoading || accountLoading,
    error: accumulatedErrors,
  };
};
