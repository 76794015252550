import { Box } from "@powerledger/ui-component-lib";

import { SignupStepper } from "../../stepper";
import { StepType } from "../onboarding.types";

export const OnboardingSider = ({ steps }: { steps: StepType[] }) => {
  return (
    <Box
      sx={{
        width: ["33%", "25%"],
        pt: 5,
        pl: [3, null, 4],
      }}
    >
      <SignupStepper steps={steps} />
    </Box>
  );
};
