import { Box, Flex, Heading, IconLoading } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { Account, PaymentMethodLinkStatus, useAccountPaymentDetailsQuery } from "@/app/types/generated/graphql";
import { ReactComponent as DwollaLogoLarge } from "@/assets/images/dwollaLogoLarge.svg";

import { ApplicationStatus } from "./application-status";
import { FundingSourceLinking } from "./funding-source-linking";
import { RequiredDocuments } from "./required-documents";
import { SelectPaymentMethod } from "./select-provider";

export const PaymentServicesTab = () => {
  const { t } = useTranslation();

  const {
    data: accountData,
    loading: accountDataLoading,
    error: accountDataError,
    refetch,
  } = useAccountPaymentDetailsQuery();
  const account = accountData?.account as Account;

  const showPaymentMethodSelection =
    accountData?.account?.linkedPaymentMethods?.length === 0 ||
    accountData?.account?.linkedPaymentMethods[0]?.status === PaymentMethodLinkStatus.LinkRetry;

  const showPaymentMethodError =
    accountData?.account?.linkedPaymentMethods[0]?.status === PaymentMethodLinkStatus.Error || accountDataError;

  if (accountDataLoading) {
    return (
      <Flex sx={{ justifyContent: "center" }}>
        <IconLoading />
      </Flex>
    );
  }

  if (showPaymentMethodError) {
    return (
      <Box sx={{ color: "error.500", fontSize: 1, mt: 3 }}>
        {t(
          "Failed to load payment details, please refresh the page and try again. If issue persists, please contact support.",
        )}
      </Box>
    );
  }

  return showPaymentMethodSelection ? (
    <SelectPaymentMethod onSelectPaymentMethod={() => refetch()} />
  ) : (
    <>
      <Flex sx={{ alignItems: "center" }}>
        <Heading as="h2" sx={{ width: "33%" }}>
          {t("Service Details")}
        </Heading>
        <Box
          sx={{
            "svg>path:not(:nth-child(3))": {
              fill: "text",
            },
          }}
        >
          <DwollaLogoLarge />
        </Box>
      </Flex>
      <Flex sx={{ alignItems: "center", py: 5 }}>
        <Box
          sx={{
            width: "33%",
            color: "textDarker",
            flexShrink: 0,
          }}
        >
          {t("Application status")}
        </Box>
        <ApplicationStatus />
      </Flex>
      <Box
        sx={{
          py: 5,
          borderTopStyle: "solid",
          borderTopWidth: 1,
          borderTopColor: "shadow",
        }}
      >
        <FundingSourceLinking
          tipDisplayType="tooltip"
          showTitle
          account={account}
          accountLoading={accountDataLoading}
        />
      </Box>
      <Box
        sx={{
          pt: 5,
          borderTopStyle: "solid",
          borderTopWidth: 1,
          borderTopColor: "foregroundLight",
        }}
      >
        <RequiredDocuments disableReUpload={[]} noEdit />
      </Box>
    </>
  );
};
