import { demoAccount } from "@/app/container/apollo-client/demo-data";
import { AccountTenantIdDocument } from "@/app/types/generated/graphql";

export const mockAccountTenantId = {
  request: {
    query: AccountTenantIdDocument,
    result: {
      data: {
        account: {
          id: demoAccount.id,
          tenantId: demoAccount.tenantId,
        },
      },
    },
  },
};
