import { FC } from "react";

import { LinkedRegistryBlockProps } from ".";
import { LinkedRegistryBlockView } from "./linked-registry-block-view";
import { useLinkedRegistryBlock } from "./use-linked-registry-block";

export const LinkedRegistryBlock: FC<LinkedRegistryBlockProps> = ({ registry, refetchRegistries, loading }) => {
  const { loadingRegistries, LinkedAccountStateIcon, registryData, linkingStatusText, showRegistryModal } =
    useLinkedRegistryBlock(registry);
  return (
    <LinkedRegistryBlockView
      loadingRegistries={loadingRegistries}
      loading={loading}
      LinkedAccountStateIcon={LinkedAccountStateIcon}
      registryData={registryData}
      linkingStatusText={linkingStatusText}
      registry={registry}
      showRegistryModal={showRegistryModal}
      refetchRegistries={refetchRegistries}
    />
  );
};
