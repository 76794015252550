import { Flex, Label, Text, ThemeUIStyleObject, Tooltip } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import FieldErrorMessage from "./error-message";

export type FormFieldLabelProps = {
  name?: string;
  hasErrorMessage?: boolean;
  label?: string;
  wrapLabel?: boolean;
  small?: boolean;
  sx?: ThemeUIStyleObject;
  info?: string;
};

export const FormFieldLabel: FC<FormFieldLabelProps> = ({
  name = "",
  hasErrorMessage = true,
  label,
  wrapLabel = false,
  info,
  sx = {},
  children,
}) => {
  const { t } = useTranslation();
  const labelSplit = label?.split("*");
  return (
    <Label id={name} sx={sx}>
      <Flex sx={{ alignItems: "center", flexShrink: wrapLabel ? 1 : 0, mb: 1 }}>
        {labelSplit?.[0]}
        <Text sx={{ color: "negative", ml: 1 }}>{label?.includes("*") ? "*" : ""}</Text>
        {info && (
          <Tooltip
            id={`form-field-label-${label}-tooltip`}
            content={<Text>{t(info)}</Text>}
            sx={{
              ml: 1,
            }}
          />
        )}
      </Flex>
      {children}
      {name && hasErrorMessage && <FieldErrorMessage name={name} sx={{ textAlign: "left", mt: 3 }} />}
    </Label>
  );
};

export default FormFieldLabel;
