import { FormikHelpers, FormikProps } from "formik";

import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";

import { RecActionValues } from "../common/rec-action-summary/rec-action-summary.types";
import { RecHoldingsTableData } from "../rec-holdings";

export type UseRetireFn = () => {
  loading: boolean;
  recOptions?: RECOrderAttributeOptions;
  tableData: RecHoldingsTableData[];
  notePrefix: string;
  formikProps: Partial<FormikProps<RetirementFormValues>>;
  onProceed: (values: RetirementFormValues, helpers?: FormikHelpers<RetirementFormValues>) => Promise<void>;
  onGoBack: () => void;
  currentStep: RetirementLifeCycle;
};

export enum RetirementLifeCycle {
  Form = "FORM",
  Summary = "SUMMARY",
  Success = "SUCCESS",
}

export type RetirementFormValues = RecActionValues<"retire">;

export type RetireViewProps = ReturnType<UseRetireFn>;

export enum RetirementType {
  BENEFICIAL_OWNERSHIP = "Beneficial Ownership",
  GREEN_E_CERTIFIED_VOLUNTARY_MARKET = "Green-e Energy Certified Voluntary Market Sale",
}
