import { Box, Flex, IconLoading } from "@powerledger/ui-component-lib";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ContactType } from "../../types/generated/graphql";
import { NominatedControllerProps } from "./nominated-controller.types";

export const NominatedController: FC<NominatedControllerProps> = ({ company, loading }) => {
  const { t } = useTranslation();

  const controllerContact = useMemo(
    () => company?.contacts?.find((contact) => contact.type === ContactType.Controller),
    [company?.contacts],
  );
  return (
    <Flex>
      <Box sx={{ color: "textDarker", fontSize: 1, flex: 2 }}>{t("Applicant Controller Details")}</Box>

      <Box sx={{ color: "textDarker", fontSize: 1, flex: 2 }}>
        {loading && <IconLoading />}
        <Box>
          {t("{{companyControllerName}} {{companyControllerTitle}}", {
            companyControllerName: `${controllerContact?.firstName || ""} ${controllerContact?.lastName || ""}`,
            companyControllerTitle: controllerContact?.jobTitle ? ` - ${controllerContact?.jobTitle}` : "",
          })}
        </Box>
      </Box>
    </Flex>
  );
};
