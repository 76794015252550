import {
  IconBuildings,
  IconCircleTick,
  IconError,
  IconIdentity,
  IconPending,
  IconProps,
} from "@powerledger/ui-component-lib";
import { FC, ReactNode } from "react";

import { UnsetIconWrapperStyle } from "@/app/components/unset-icon-wrapper-style/unset-icon-wrapper-style";
import {
  ApprovalStatus,
  Company,
  CompanyContact,
  Document,
  DocumentStatus,
  DocumentType,
} from "@/app/types/generated/graphql";

import { MinDocumentStatus } from "./document-block.types";

export const DocumentStatusIconMap: Record<MinDocumentStatus, FC<IconProps>> = {
  PENDING: IconPending,
  APPROVED: IconCircleTick,
  ERROR: IconError,
};

export const mapDocumentState = (document?: Document | null, documentOf?: CompanyContact | Company | null) => {
  if (document?.status === DocumentStatus.Uploaded) {
    if (document?.approvalState?.status === ApprovalStatus.Pending) {
      return MinDocumentStatus.Pending;
    }
    if (document?.approvalState?.status === ApprovalStatus.Approved) {
      return MinDocumentStatus.Approved;
    }
    if (document?.approvalState?.status === ApprovalStatus.NotRequested) {
      if (documentOf?.approvalState?.status === ApprovalStatus.Approved) {
        return MinDocumentStatus.Approved;
      }
      return MinDocumentStatus.Pending;
    }
    return MinDocumentStatus.Error;
  }
  return MinDocumentStatus.Pending;
};

export const getStatusText = (document?: Document, documentOf?: Company | CompanyContact | null) => {
  const getDocumentState = mapDocumentState(document, documentOf);
  if (getDocumentState === MinDocumentStatus.Approved) {
    return `Your document ${document?.fileName} is approved.`;
  }
  if (getDocumentState === MinDocumentStatus.Pending) {
    return `Your document ${document?.fileName || ""} is pending approval.`;
  }
  if (getDocumentState === MinDocumentStatus.Error) {
    return `${document?.approvalState?.errorMessage || `Your document ${document?.fileName} has failed to upload`}`;
  }
  return "";
};

export const requiredDocTypeNameMap: Record<DocumentType, string> = {
  ID_CARD: "Identity",
  OTHER: "Business Registration",
  LICENSE: "Licence",
  PASSPORT: "Passport",
};

export const requiredDocTypeIconMap: Record<DocumentType, ReactNode> = {
  ID_CARD: <UnsetIconWrapperStyle Icon={IconIdentity} />,
  OTHER: (
    <UnsetIconWrapperStyle
      Icon={IconBuildings}
      sx={{
        width: 50,
      }}
    />
  ),
  LICENSE: <UnsetIconWrapperStyle Icon={IconIdentity} />,
  PASSPORT: <UnsetIconWrapperStyle Icon={IconIdentity} />,
};
