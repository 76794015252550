import { Box, Flex, Heading } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { StepLayoutProps } from ".";

export const StepLayout: FC<StepLayoutProps> = ({
  heading,
  description,
  disclaimer,
  label,
  actions,
  fullWidthContent,
  children,
  id,
}) => {
  return (
    <Flex
      sx={{
        position: "relative",
        flexDirection: "column",
        py: [4, null, 5],
        px: 4,
        flexGrow: 1,
      }}
    >
      <Box sx={{ width: fullWidthContent ? "90%" : ["100%", null, null, "80%", "66%"] }}>
        {label && (
          <Box
            sx={{
              color: "textDarker",
              fontSize: 0,
              pb: 5,
              textTransform: "uppercase",
              letterSpacing: 2,
            }}
          >
            {label}
          </Box>
        )}
        <Flex sx={{ flexDirection: "column", pb: 3 }}>
          <Heading
            as="h2"
            sx={{
              fontWeight: "bold",
              fontSize: 6,
              fontFamily: "MintGroteskV08",
              width: "100%",
            }}
            id={id}
          >
            {heading}
          </Heading>
          {description && (
            <Box sx={{ color: "textDarker", fontSize: 1, pt: 3, pb: disclaimer ? 0 : 3 }}>{description}</Box>
          )}
          {disclaimer && <Box sx={{ color: "textDarker", fontSize: 1, py: 3 }}>{disclaimer}</Box>}
        </Flex>
      </Box>
      <Box
        sx={{
          width: fullWidthContent ? "90%" : ["100%", null, null, "80%", "66%"],
        }}
      >
        {children}
      </Box>

      {actions && (
        <Box
          sx={{
            width: ["100%", "80%", "66%"],
            pt: 4,
          }}
        >
          {actions}
        </Box>
      )}
    </Flex>
  );
};
