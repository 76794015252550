import { Button, Flex, IconLoading } from "@powerledger/ui-component-lib";
import { Formik, FormikErrors } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";

import { ErrorMessage, Form, FormFieldLabel, FormInput } from "@/app/components";
import { FactorProvider, FactorType, useActivateMutation } from "@/app/types/generated/graphql";

export const ActivateFactorForm = ({
  factorProvider,
  factorType,
  onSubmit,
}: {
  factorProvider: FactorProvider;
  factorType: FactorType;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation();

  const [activateFactor] = useActivateMutation({
    refetchQueries: ["AccountEnrolledFactor"],
  });

  const submitCallback = useCallback(
    async (
      passCode: string,
      setErrors: (
        errors: FormikErrors<{
          passCode: string;
        }>,
      ) => void,
    ) => {
      try {
        await activateFactor({
          variables: {
            activate: {
              activateString: passCode,
              factor: {
                factorType,
                factorProvider,
              },
            },
          },
        });
        onSubmit();
      } catch (err: any) {
        setErrors({
          passCode: "Invalid passcode provided, please try again",
        });
      }
    },
    [activateFactor, onSubmit, factorProvider, factorType],
  );

  return (
    <Formik
      initialValues={{
        passCode: "",
      }}
      validationSchema={object().shape({
        passCode: string()
          .matches(/^[0-9]*$/, "The code should only contain numbers")
          .min(6, "The code should only contain 6 numbers")
          .max(6, "The code should only contain 6 numbers")
          .required("A code is required"),
      })}
      onSubmit={({ passCode }, { setErrors }) => submitCallback(passCode, setErrors)}
      validateOnMount
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            <FormFieldLabel
              wrapLabel
              name="passCode"
              hasErrorMessage={false}
              label={t("Enter the confirmation code that is displayed in Google Authenticator")}
            >
              <FormInput
                name="passCode"
                type="text"
                id="token"
                inputMode="numeric"
                pattern="[0-9]*"
                autoComplete="one-time-code"
                maxLength={6}
              />
            </FormFieldLabel>
            <ErrorMessage name="passCode" sx={{ pt: 3 }} />
          </Form.Item>
          <Flex sx={{ justifyContent: "flex-start", gap: 2, mt: 2 }}>
            <Button variant="primary" type="submit" disabled={isSubmitting || !isValid}>
              {t("Authenticate")}
            </Button>
            {isSubmitting && <IconLoading size="small" />}
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default ActivateFactorForm;
