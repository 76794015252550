import {
  Account,
  AccountQuery,
  AccountStatus,
  AccountType,
  ApprovalStatus,
  ContactType,
  Document,
  DocumentStatus,
  PaymentMethodLinkStatus,
} from "../types/generated/graphql";
import {
  companySchema,
  convertToAccountDetailsInput,
  convertToCompanyContactInput,
  convertToCompanyInput,
  fundingSourceSchema,
  marketSchema,
  personalSchema,
} from "./validation";

export enum SignupStepperStages {
  AccountTypeSelection = "Account_Type_Selection",
  MarketSelection = "Market_Selection",
  ApplicantDetails = "Applicant_Details",
  ApplicantControllerDetails = "Applicant_Controller_Details",
  ApplicantBeneficialOwners = "Applicant_Beneficial_Owners",
  RequiredDocumentation = "Required_Documentation",
  PaymentServiceMethodSelection = "Payment_Service_Method_Selection",
  LinkBankAccount = "Link_Bank_Account",
  LinkRegistryAccount = "Link_Registry_Account",
  FinalVerification = "Final_Verification",
}

/**
 * Checks whether all documents are in uploaded state
 * @param documents
 * @returns
 */
export const areDocumentsUploaded = (documents?: Document[] | null) => {
  return documents?.every((document) => document.status === DocumentStatus.Uploaded);
};

/**
 * Checks if some documents or payment methods are missing in account
 * @param account
 * @returns
 */
export const hasMissingDetails = (account: Account) =>
  !account?.linkedPaymentMethods?.length ||
  !account?.linkedPaymentMethods?.some((paymentMethod) => paymentMethod.status === PaymentMethodLinkStatus.Active) ||
  !areDocumentsUploaded(account?.company?.documents as Document[]) ||
  !account?.company?.contacts?.every((company) => areDocumentsUploaded(company?.documents as Document[]));

/**
 * Moves user to specified state where they left
 * @param account
 * @returns
 */
export const returnToLeftState = (
  account: AccountQuery["account"],
  defaultState = SignupStepperStepStageIndices.Market_Selection,
) => {
  if (!account) return defaultState;

  const eachStagesValidation = getEachStageValidation(account);

  if (!eachStagesValidation[SignupStepperStages.AccountTypeSelection])
    return SignupStepperStepStageIndices.Account_Type_Selection;

  if (!eachStagesValidation[SignupStepperStages.MarketSelection]) return SignupStepperStepStageIndices.Market_Selection;

  if (!eachStagesValidation[SignupStepperStages.ApplicantDetails])
    return SignupStepperStepStageIndices.Applicant_Details;

  if (!eachStagesValidation[SignupStepperStages.ApplicantControllerDetails])
    return SignupStepperStepStageIndices.Applicant_Controller_Details;

  if (!eachStagesValidation[SignupStepperStages.RequiredDocumentation])
    return SignupStepperStepStageIndices.Required_Documentation;

  if (!eachStagesValidation[SignupStepperStages.PaymentServiceMethodSelection])
    return SignupStepperStepStageIndices.Payment_Service_Method_Selection;

  if (!eachStagesValidation[SignupStepperStages.LinkBankAccount])
    return SignupStepperStepStageIndices.Link_Bank_Account;

  if (!eachStagesValidation[SignupStepperStages.ApplicantBeneficialOwners])
    return SignupStepperStepStageIndices.Applicant_Beneficial_Owners;

  return SignupStepperStepStageIndices.Final_Verification;
};

/**
 * Check approved status of account and its nested entities => company and its contacts
 * @param account
 * @returns
 */
export const checkNestedApprovedStatus = (account?: Account | null) => {
  return !!(
    account &&
    (account?.company?.approvalState?.status === ApprovalStatus.Approved ||
      account?.company?.approvalState?.status === ApprovalStatus.NotRequested) &&
    account?.company?.contacts?.every(
      (contact) =>
        contact.approvalState?.status === ApprovalStatus.Approved ||
        contact.approvalState?.status === ApprovalStatus.NotRequested,
    )
  );
};

/**
 * Stages and their [step index, stage index]
 */
export const SignupStepperStepStageIndices: Record<string, [number, number]> = {
  Account_Type_Selection: [0, 0],
  Market_Selection: [1, 0],
  Applicant_Details: [2, 0],
  Applicant_Controller_Details: [2, 1],
  Applicant_Beneficial_Owners: [2, 2],
  Required_Documentation: [2, 3],
  Payment_Service_Method_Selection: [3, 0],
  Link_Bank_Account: [3, 1],
  Link_Registry_Account: [4, 0],
  Final_Verification: [5, 0],
};

/**
 * If status is LinkRetry or Error, allow (re)link payment method
 */
export const canLinkPaymentMethod = (status?: PaymentMethodLinkStatus) =>
  status && (status === PaymentMethodLinkStatus.LinkRetry || status === PaymentMethodLinkStatus.Error);

/**
 * Returns required stages validation status
 * @param account
 * @returns
 */
export const getEachStageValidation = (account: AccountQuery["account"]) => {
  const beneficialOwnerContact = account?.company?.contacts?.filter((c) => c.type === ContactType.BeneficialOwner);
  const controllerContact = account?.company?.contacts?.find((c) => c.type === ContactType.Controller);
  const linkedBankAccountStatus = account?.linkedPaymentMethods[0]?.fundingSource;

  return {
    Account_Type_Selection: !!account?.type,
    Market_Selection: marketSchema.isValidSync(convertToAccountDetailsInput(account)),
    Applicant_Details: companySchema.isValidSync(convertToCompanyInput(account?.company)),
    Applicant_Controller_Details: personalSchema.isValidSync(
      convertToCompanyContactInput(ContactType.Controller, controllerContact),
    ),
    Applicant_Beneficial_Owners: beneficialOwnerContact?.length
      ? beneficialOwnerContact?.every((bo) =>
          personalSchema.isValidSync(convertToCompanyContactInput(ContactType.BeneficialOwner, bo)),
        )
      : true,
    Required_Documentation:
      areDocumentsUploaded(account?.company?.documents) &&
      account?.company?.contacts?.every((contact) => areDocumentsUploaded(contact?.documents)),
    Payment_Service_Method_Selection:
      !!account?.linkedPaymentMethods?.length &&
      account?.linkedPaymentMethods?.some((paymentMethod) => !canLinkPaymentMethod(paymentMethod?.status)),
    Link_Bank_Account: fundingSourceSchema.isValidSync(linkedBankAccountStatus),
  };
};

export const isOnboardingOrRetryUser = (status?: AccountStatus) =>
  status === AccountStatus.Onboarding || status === AccountStatus.Retry;

export const getStepsWithDisabledFields = (account: AccountQuery["account"]) => {
  const steps: Record<SignupStepperStages, string[]> = {
    Account_Type_Selection: [],
    Market_Selection: [],
    Applicant_Details: [],
    Applicant_Controller_Details: [],
    Applicant_Beneficial_Owners: [],
    Required_Documentation: [],
    Payment_Service_Method_Selection: [],
    Link_Bank_Account: [],
    Link_Registry_Account: [],
    Final_Verification: [],
  };
  if (account?.status === AccountStatus.Onboarding) return steps;
  if (account?.company?.approvalState?.status !== ApprovalStatus.Rejected) {
    steps[SignupStepperStages.ApplicantDetails] = [account?.company?.id ?? ""];
  }
  const allDocuments = [
    ...(account?.company?.documents ?? []),
    ...(account?.company?.contacts?.flatMap((contact) => contact.documents) ?? []),
  ];

  for (const doc of allDocuments) {
    if (doc?.approvalState?.status !== ApprovalStatus.Rejected) {
      steps[SignupStepperStages.RequiredDocumentation] = [
        ...(steps[SignupStepperStages.RequiredDocumentation] ?? []),
        doc?.id,
      ];
    }
  }
  const beneficialOwners =
    account?.company?.contacts?.filter((contact) => contact.type === ContactType.BeneficialOwner) ?? [];
  const controller = account?.company?.contacts?.find((contact) => contact.type === ContactType.Controller);

  if (controller?.approvalState?.status !== ApprovalStatus.Rejected) {
    if (controller?.type === ContactType.Controller)
      steps[SignupStepperStages.ApplicantControllerDetails] = [controller.id];
  }
  /**
   * Regardless of presence of BO, disable them by default
   */
  steps[SignupStepperStages.ApplicantBeneficialOwners] = [""];

  /**
   * For each BO if status is not rejected => disable the fields
   */
  for (const contact of beneficialOwners ?? []) {
    if (contact.approvalState?.status !== ApprovalStatus.Rejected) {
      if (contact.type === ContactType.Controller) steps[SignupStepperStages.ApplicantControllerDetails] = [contact.id];
      else
        steps[SignupStepperStages.ApplicantBeneficialOwners] = [
          ...(steps[SignupStepperStages.ApplicantBeneficialOwners] ?? []),
          contact.id,
        ];
    }
  }
  return steps;
};

export const hasPersonalRegistries = (accountType?: AccountType | null) =>
  accountType && [AccountType.Registry].includes(accountType);
