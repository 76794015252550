import { Box, Button, Flex, Heading, IconLoading, IconLongArrow, LegacyCheckbox } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { PaymentMethodLegalContentMap } from "@/app/lib/payment-content-maps";

import { PaymentMethodBlockButton } from "./payment-method-block-button";
import { SelectPaymentMethodProps } from "./select-payment-method.types";

export const SelectPaymentMethodView: FC<SelectPaymentMethodProps> = ({
  loadingLinkPaymentMethod,
  loadingPaymentMethods,
  disableNextButton,
  onNextButtonClick,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  paymentMethods,
  acceptedLegalAgreement,
  setAcceptedLegalAgreement,
  error,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Heading as="h2">{t("Link your payment provider")}</Heading>
      <Box sx={{ mt: 4 }}>
        <Box>
          <Box
            sx={{
              color: "textDarker",
              fontSize: 0,
              pb: 4,
            }}
          >
            {t(
              "In order to place Orders on the TraceX Marketplace, each Participant needs to select a Payment Services Provider to facilitate payments",
            )}
          </Box>
          <Box>
            {loadingPaymentMethods ? (
              <IconLoading />
            ) : (
              <>
                <Flex sx={{ flexWrap: "wrap" }}>
                  {paymentMethods?.map((paymentMethod) => (
                    <PaymentMethodBlockButton
                      key={paymentMethod.id}
                      paymentMethod={paymentMethod}
                      active={selectedPaymentMethod && paymentMethod.id === selectedPaymentMethod.id}
                      onClick={() => setSelectedPaymentMethod(paymentMethod)}
                    />
                  ))}
                </Flex>
                {selectedPaymentMethod && (
                  <LegacyCheckbox
                    label={PaymentMethodLegalContentMap[selectedPaymentMethod.code]}
                    checked={acceptedLegalAgreement}
                    onChange={(event) => {
                      setAcceptedLegalAgreement(event.target.checked);
                    }}
                    sx={{ py: 4 }}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
        <Flex sx={{ justifyContent: "flex-start" }}>
          <Button
            variant="primary"
            sx={{ mt: 4, float: "right" }}
            disabled={disableNextButton || loadingLinkPaymentMethod}
            onClick={onNextButtonClick}
          >
            {t("Next")}
            <IconLongArrow size={4} stroke="white" noSvgPositioning sx={{ ml: 2 }} />
          </Button>
          {loadingLinkPaymentMethod && <IconLoading sx={{ mt: 4, ml: 2 }} />}
        </Flex>
      </Box>
      {error}
    </>
  );
};
