import { CompanyContact } from "@/app/types/generated/graphql";

export const getBeneficialOwnerTouched = (companyContact?: CompanyContact | null, isTouched?: boolean) => {
  if (!companyContact?.id || !isTouched) return;
  return {
    firstName: true,
    lastName: true,
    jobTitle: true,
    dateOfBirth: true,
    physicalAddress: {
      countryCode: true,
      state: true,
      city: true,
      line1: true,
      line2: true,
      postalCode: true,
    },
    identityNumber: true,
    passport: {
      number: true,
      countryCode: true,
    },
  };
};
