import { CountryRegionData } from "react-country-region-selector";

const getCountry = (countryCode?: string) => {
  return CountryRegionData.find((data) => data[1] === countryCode)?.[0];
};
const getRegion = (countryCode?: string, regionCode?: string) => {
  const regionsText = CountryRegionData.find((data) => data[1] === countryCode)?.[2];
  const regions = regionsText?.split("|").map((r) => {
    const name = r.split("~")[0];
    const code = r.split("~")[1];
    return { name, code };
  });
  return regions?.find((r) => r.code === regionCode)?.name;
};

export { getCountry, getRegion };
