import { Box, Button, Flex, Heading, IconLogout, IconRefresh, Link, Text } from "@powerledger/ui-component-lib";
import * as Sentry from "@sentry/react";
import React from "react";

import { getAccessToken } from "@/app/container/apollo-client";
import { getSupportEmail } from "@/app/lib/get-support-email";
import i18n from "@/app/lib/i18n";

const reloadPage = () => window.location.reload();

const supportEmail = getSupportEmail();

type GlobalErrorBoundaryState = {
  hasError: boolean;
  isLoggedIn: boolean;
  error?: string;
};

export class GlobalErrorBoundary extends React.Component<any, GlobalErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: "", isLoggedIn: !!getAccessToken() };
  }
  setIsLoggedIn = () => {
    this.setState({
      isLoggedIn: !!getAccessToken(),
    });
  };

  componentDidMount(): void {
    window.addEventListener("storage", this.setIsLoggedIn);
  }

  componentWillUnmount(): void {
    window.removeEventListener("storage", this.setIsLoggedIn);
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error?.message,
    });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
      this.setState({ hasError: true });
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent error={this.state.error} isLoggedIn={this.state.isLoggedIn} />;
    }

    return this.props.children;
  }
}

const ErrorComponent = ({ error = "", isLoggedIn = false }) => (
  <Flex
    sx={{
      width: "100%",
      textAlign: "center",
      flexDirection: "column",
      fontSize: 2,
      position: "relative",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Box
      sx={{
        position: "absolute",
        opacity: 0.1,
        left: 0,
        right: 0,
        top: 0,
        zIndex: -1,
        bottom: 0,
        background: "url('/assets/loginBackground.svg')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: ["auto", null, null, null, null, "cover"],
      }}
    ></Box>
    <Flex
      sx={{
        gap: 3,
        alignItems: "center",
        flexDirection: "column",
        maxWidth: ["100%", "75%", "60%"],
        color: "text",
      }}
    >
      <Heading sx={{ fontSize: "2rem", lineHeight: "1.35", mr: 3, fontFamily: "inherit" }}>
        <span>{"Unfortunately an error occurred on our end."}</span>
        <br />
        {"The system may be down for maintenance."}
      </Heading>

      {!!error && (
        <Heading sx={{ fontSize: 1, lineHeight: 1, mr: 3, fontFamily: "inherit", color: "text" }}>
          {i18n.t(`Error Details: {{error}}`, { error })}
        </Heading>
      )}

      <Text
        sx={{
          pt: 4,
          fontSize: "1.2rem",
          mb: 4,
        }}
      >
        {"Please reload the page and try again."}
      </Text>
      <Flex sx={{ gap: 3 }}>
        {isLoggedIn && (
          <Button
            type="button"
            variant="inverted"
            onClick={() => {
              localStorage.clear();
              reloadPage();
            }}
          >
            <Flex
              sx={{
                alignItems: "center",
                display: "inline-flex",
                div: {
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <IconLogout
                size={15}
                sx={{
                  mr: 2,
                }}
                noSvgPositioning
                color="primary.500"
              />
              <Text>{i18n.t("Log out")}</Text>
            </Flex>
          </Button>
        )}
        <Button type="button" variant="primary" onClick={reloadPage}>
          <Flex
            sx={{
              alignItems: "center",
              display: "inline-flex",
              div: {
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            <IconRefresh
              size={15}
              sx={{
                mr: 2,
              }}
              noSvgPositioning
              color={"background"}
            />
            <Text>{i18n.t("Reload")}</Text>
          </Flex>
        </Button>
      </Flex>
    </Flex>
    <Flex
      sx={{
        color: "textDark",
        fontSize: 2,
        mt: 3,
        justifyContent: "center",
      }}
    >
      <Text>{i18n.t("You can contact us at")}</Text>
      &nbsp;
      <Link
        href={`mailto:${supportEmail}`}
        sx={{
          color: "accent",
          textDecoration: "none",
          "&:hover": { textDecoration: "underline" },
        }}
      >
        {supportEmail}
      </Link>
    </Flex>
    <Box sx={{ color: "textDark", fontSize: 0, mt: 2 }}>
      {`©${new Date().getFullYear()} Powerledger. All rights reserved`}
    </Box>
  </Flex>
);
