import { Flex, IconCircleTick, IconError, IconEyeOutlined, IconLoading, Text } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { hasMissingDetails } from "@/app/lib/account-service-helpers";
import { Account, AccountStatus, useAccountQuery, useFewAccountDetailsQuery } from "@/app/types/generated/graphql";

import { AccountVerificationStatusProps } from "./account-verification-status.types";

export const AccountVerificationStatus: React.FC<AccountVerificationStatusProps> = ({ isAdmin = false }) => {
  const { t } = useTranslation();

  const { data, loading } = useFewAccountDetailsQuery();
  const { data: fullAccountData, loading: fullAccountDataLoading } = useAccountQuery({
    skip: !isAdmin,
  });
  const verificationStatusContent = useMemo(() => {
    if (data?.account?.status === AccountStatus.Active)
      return (
        <>
          <IconCircleTick size={5} sx={{ mr: 2 }} color="primary.500" />
          <Text sx={{ color: "textDark" }}>{t("Your account is ready to trade")}</Text>
        </>
      );
    if (!isAdmin) {
      return (
        <>
          <IconError size={5} color="error.500" sx={{ mr: 2 }} />
          <Text sx={{ color: "textDark" }}>{t("Your account is not ready to trade")}</Text>
        </>
      );
    }
    return hasMissingDetails(fullAccountData?.account as Account) ? (
      <>
        <IconError size={5} color="error.500" sx={{ mr: 2 }} />
        <Text sx={{ color: "textDark" }}>{t("We're still missing some details. Complete to enable trading")}</Text>
      </>
    ) : (
      <>
        <IconEyeOutlined size={5} color="warning.500" sx={{ mr: 2, opacity: 0.7 }} />
        <Text sx={{ color: "textDark" }}>{t("Your account is being reviewed by the TraceX team")}</Text>
      </>
    );
  }, [data?.account, isAdmin, fullAccountData, t]);

  return loading || fullAccountDataLoading ? (
    <IconLoading />
  ) : (
    <Flex sx={{ alignItems: "center", borderRadius: 4, background: "layoutBackground", p: 3 }}>
      {verificationStatusContent}
    </Flex>
  );
};
