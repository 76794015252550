import { get } from "lodash";

import { AccountQuery } from "@/app/types/generated/graphql";

import { companySchema } from "../schemas";

export const getCompanyTouched = (account: AccountQuery["account"], pageTouched?: boolean) => {
  const company = account?.company;
  const touched = Object.keys(companySchema.fields).reduce((acc, key) => acc || !!get(company, key), false);
  if (!touched && !pageTouched) return;
  return {
    businessName: true,
    businessType: true,
    businessClassification: true,
    industryClassificationUid: true,
    employerIdentificationNumber: true,
    physicalAddress: {
      line1: true,
      line2: true,
      city: true,
      state: true,
      postalCode: true,
      countryCode: true,
    },
  };
};
