import { ApprovalStatus, Company, CompanyInput } from "@/app/types/generated/graphql";

/**
 * Converts company object into companyInput type for validation checking and populating values in form
 */
export const convertToCompanyInput: (company?: Company | null) => CompanyInput = (company) => ({
  businessName: company?.businessName || "",
  businessType: company?.businessType || "",
  industryClassificationUid: company?.industryClassification?.id || "",
  businessClassification: company?.industryClassification?.businessClassificationName || "",
  employerIdentificationNumber: company?.employerIdentificationNumber || "",
  physicalAddress: {
    line1: company?.physicalAddress?.line1 || "",
    line2: company?.physicalAddress?.line2 || "",
    city: company?.physicalAddress?.city || "",
    state: company?.physicalAddress?.state || "",
    postalCode: company?.physicalAddress?.postalCode || "",
    countryCode: company?.physicalAddress?.countryCode || "US",
  },
  approvalStatus: company?.approvalState?.status ?? ApprovalStatus.NotRequested,
});
