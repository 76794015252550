import { IconLoading } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { Button } from "theme-ui";

import { DocumentPopUp } from "./document-popup";
import { ViewDocumentProps } from "./view-document.types";

export const ViewDocument: React.FC<ViewDocumentProps> = ({
  viewLoading,
  onView,
  showDoc,
  closePopup,
  getWindowInstance,
}) => {
  const { t } = useTranslation();
  if (viewLoading) {
    return <IconLoading />;
  }
  return (
    <>
      <Button
        sx={{
          background: "transparent",
          color: "text",
          borderRadius: 0,
          p: 2,
          borderBottom: "1px solid var(--theme-ui-colors-text)",
          mt: 2,
          "&:enabled:hover, &:enabled:focus": {
            bg: "transparent",
            opacity: 0.5,
          },
        }}
        onClick={onView}
      >
        {t("View")}
      </Button>
      {showDoc && (
        <DocumentPopUp
          image={showDoc.image}
          imageName={showDoc.imageName}
          closePopup={closePopup}
          getWindowInstance={getWindowInstance}
        />
      )}
    </>
  );
};
