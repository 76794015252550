import currency from "currency.js";

import { OrderPosition, VintageHalf } from "../types/generated/graphql";
import { FormattedOption } from "./format-rec-options";
import i18n from "./i18n";

export const DEFAULT_FEE = 0;
export const ORDER_LOWER_LIMIT = 50;
export const ORDER_UPPER_LIMIT = 200000;

export const getTotalMarketValue = (cost: string | number, count: number) => currency(cost).multiply(count);

export const getTransactionFee = (cost: string | number, count: number, fee: number) =>
  getTotalMarketValue(cost, count).multiply(fee);

export const getTotalValue = (cost: string | number, count: number, position: OrderPosition, txnFee: number) => {
  const subtotal = getTotalMarketValue(cost, count);
  const fee = getTransactionFee(cost, count, txnFee);
  return position === OrderPosition.Bid ? subtotal.add(fee) : subtotal.subtract(fee);
};

export const isWithinLimit = (value: currency, setOverLimitError: (arg: string) => void) => {
  if (value.subtract(ORDER_LOWER_LIMIT).intValue < 0) {
    setOverLimitError("Total order value should be at least $50.");
    return false;
  }
  if (value.subtract(ORDER_UPPER_LIMIT).intValue >= 0) {
    setOverLimitError(
      "TraceX does not currently support solo orders equal to or greater than $200k, kindly split your order in to smaller constituents.",
    );
    return false;
  } else {
    setOverLimitError("");
    return true;
  }
};

export const addHalfToVintageOptions = (vintage: FormattedOption[0], halfOption: FormattedOption) => {
  return halfOption.map((opt) => ({
    label: `${vintage.label}-${i18n.t(opt.value)}`,
    value: `${vintage.label}-${opt.value}`,
  }));
};

const HALF_REGEX = new RegExp(`${VintageHalf.FrontHalf}|${VintageHalf.BackHalf}`, "g");
export const getTextToShowOnValue = (vintages: string[]) => {
  return vintages.map((vin) => `${vin.split("-")[0]} - ${i18n.t(vin.match(HALF_REGEX)?.[0] ?? "")}`);
};

export const reverseFeePercentageCalculation = (feeAmount: number, executedPrice: number) =>
  Math.round((feeAmount / executedPrice) * 100);
