import { Flex } from "@powerledger/ui-component-lib";
import { Link } from "react-router-dom";

import { TraceXLogo } from "../../components";

export const PublicHeader = ({ actions }: { actions?: any }) => (
  <Flex
    sx={{
      flexDirection: ["column", "row"],
      p: 4,
      alignItems: ["start", "center"],
      justifyContent: "space-between",
      fontFamily: "MintGroteskV08",
    }}
  >
    <Link to="/">
      <TraceXLogo />
    </Link>
    {actions}
  </Flex>
);
