import { IconProps, ThemeUIStyleObject } from "@powerledger/ui-component-lib";
import { FC } from "react";

export const UnsetIconWrapperStyle: FC<{
  Icon: FC<IconProps>;
  sx?: ThemeUIStyleObject;
}> = ({ Icon, sx }) => {
  return (
    <Icon
      sx={{
        position: "unset",
        width: "unset",
        height: "unset",
        svg: {
          fill: "none",
          path: {
            stroke: "primary.500",
          },
        },
        ...sx,
      }}
    />
  );
};
