import { ReactComponent as AuFlag } from "svg-country-flags/svg/au.svg";
import { ReactComponent as ThFlag } from "svg-country-flags/svg/th.svg";
import { ReactComponent as UsFlag } from "svg-country-flags/svg/us.svg";

/*
 * We use svg-country-flags for our flag images. To reduce size of frontend bundle and
 * importing every country's flag, import the specific flags and add only the flags we
 * need to the <CountryFlag/> component's countryFlags prop. The other flags in the
 * library will be tree shaken out.
 *
 * Key is a lowercase ISO 3166-1 Alpha-2 Code
 */
export const TRACEX_COUNTRY_FLAGS: Record<string, JSX.Element> = {
  us: <UsFlag />,
  au: <AuFlag />,
  th: <ThFlag />,
};
