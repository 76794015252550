import "cross-fetch/polyfill";

import { ApolloClient, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLError } from "graphql";

import { FEDERATED_GATEWAY_URL, USE_GRAPHQL_DEMO_DATA } from "../../lib/env-helpers";
import { getSupportEmail } from "../../lib/get-support-email";
import i18n from "../../lib/i18n";
import { fallBackErrorMessage } from "../notifications";
import { typePolicies } from "./demo-data/type-policies";

export const errorLink = onError(({ graphQLErrors, response }) => {
  if (response)
    response.errors = [
      new GraphQLError(
        i18n.t((graphQLErrors?.[0]?.extensions?.errorCode as string) || fallBackErrorMessage, fallBackErrorMessage, {
          supportEmail: getSupportEmail(),
        }),
      ),
    ];
});

const apolloClient = () => {
  const httpLink = createHttpLink({
    uri: FEDERATED_GATEWAY_URL,
  });

  const authLink = setContext((_, { headers }) => {
    const accessToken: string = getAccessToken();
    return {
      headers: {
        ...headers,
        ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
      },
    };
  });

  return new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(USE_GRAPHQL_DEMO_DATA && (typePolicies as any)),
    connectToDevTools: USE_GRAPHQL_DEMO_DATA,
  });
};

export const getAccessToken = () => {
  const localStorageOktaToken = localStorage.getItem("okta-token-storage");
  if (localStorageOktaToken) {
    const oktaTokenStorage = JSON.parse(localStorageOktaToken);
    if (oktaTokenStorage.accessToken && oktaTokenStorage.accessToken.accessToken) {
      return oktaTokenStorage.accessToken.accessToken;
    }
  }
  return "";
};

export default apolloClient;
