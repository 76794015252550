import currency from "currency.js";
import { subYears } from "date-fns";
import { FormikHelpers } from "formik";
import { find } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications";
import { useRecOptions } from "@/app/hooks/use-rec-options";
import { useTransactionFee } from "@/app/hooks/use-transaction-fee/use-transaction-fee";
import { getDatePart } from "@/app/lib/date-helpers";
import {
  CurrencyCode,
  OrderExpiry,
  OrderPosition,
  OrderType,
  useCreateRecOrderMutation,
  useTenantsQuery,
} from "@/app/types/generated/graphql";

import { useFilter } from "../../page-header/filter";
import { FilterValue } from "../../page-header/filter/filter.types";
import { orderExpiriesOptions, orderTypeOptions } from "../helpers";
import { BuyFormOptions, BuyFormValues, BuyOrderLifeCycle, UseBuyOrderModal } from "./buy-order-modal.types";

export const useBuyOrderModal: UseBuyOrderModal = ({ closeModal, defaultAsset }) => {
  const { t } = useTranslation();
  const { accountData, loading: recOptionsLoading } = useRecOptions();

  const [currentStep, setCurrentStep] = useState(BuyOrderLifeCycle.Form);

  const [overLimitError, setOverLimitError] = useState<string>();
  const { data: tenantsData } = useTenantsQuery();
  const [createOrder] = useCreateRecOrderMutation();

  const { getAppliedFee, loading } = useTransactionFee();

  const [currencyCode, currencySymbol] = useMemo(() => {
    const tenant = find(tenantsData?.tenants, { id: accountData?.account?.tenantId });
    return [tenant?.localisation?.currencyCode as CurrencyCode, tenant?.localisation?.currencySymbol || "$"];
  }, [accountData?.account?.tenantId, tenantsData]);

  const { filters, filterValues, stackedValues, removeValue } = useFilter({
    hideStatusFilter: true,
    shouldSelectVintage: true,
    filterFormFieldProps: {
      vintages: {
        label: t("Vintage *"),
      },
      eligibilities: {
        info: t(
          "Eligibilities exist to inform users that Certificates meet the requirements of different external voluntary or compliance programs managed by states",
        ),
      },
      cod: {
        label: t("Commencement of Operations Date (COD)"),
        dynamicInfo: (filterValues) =>
          filterValues.length
            ? t(`RECs that are from projects no older than {{date}} will be shown.`, {
                date: getDatePart(subYears(new Date(), +filterValues?.[0]?.value)),
              })
            : "",
      },
    },
    ...(defaultAsset
      ? {
          initialValues: { ...defaultAsset, statuses: [] as FilterValue[] },
        }
      : {}),
  });

  const handleSubmit = useCallback(
    async (values: BuyFormValues, actions: FormikHelpers<BuyFormValues>) => {
      try {
        await createOrder({
          variables: {
            order: {
              position: OrderPosition.Bid,
              volume: Number(values.volume),
              price: {
                amount: currency(values.unitPrice).intValue.toString(),
                currencyCode: currencyCode || CurrencyCode["Usd"],
              },
              type: values.orderType,
              orderExpiry: values.orderExpiry || null,
              expiryDate: values.expiryDate ? new Date(values.expiryDate).toISOString() : null,
            },
            attributes: {
              vintages: filterValues.vintages.map((selectedOption) => selectedOption.value),
              eligibilities: filterValues.eligibilities.map((selectedOption) => selectedOption.value),
              projects: filterValues.projects.map((selectedOption) => selectedOption.value),
              locations: filterValues.locations.map((selectedOption) => selectedOption.value),
              fuelSources: filterValues.fuelSources.map((selectedOption) => selectedOption.value),
              certifications: filterValues.certifications.map((selectedOption) => selectedOption.value),
              commencementDate: filterValues.cod.length
                ? getDatePart(subYears(new Date(), +filterValues.cod?.[0]?.value))
                : undefined,
            },
          },
        });
        setCurrentStep(BuyOrderLifeCycle.Success);
      } catch (e) {
        e instanceof Error &&
          notifications.error({
            description: t(e?.message),
          });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [createOrder, currencyCode, filterValues, t],
  );

  const buyFormOptions: BuyFormOptions = useMemo(
    () => ({
      orderExpiries: orderExpiriesOptions,
      orderTypes: orderTypeOptions,
    }),
    [],
  );

  const formikInitialValue: BuyFormValues = useMemo(
    () => ({
      orderType: OrderType.Limit,
      orderExpiry: OrderExpiry["Gtc"],
      volume: 0,
      unitPrice: 0,
      fee: getAppliedFee(OrderPosition.Bid),
    }),
    [getAppliedFee],
  );

  return {
    loading,
    filters,
    filterValues,
    stackedValues,
    removeValue,
    isFilterValid: !!filterValues.vintages.length,
    formikInitialValue,
    handleSubmit,
    buyFormOptions,
    currencySymbol,
    overLimitError,
    recOptionsLoading,
    setOverLimitError,
    currentStep,
    closeModal,
  };
};
