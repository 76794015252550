import { Box, Flex } from "theme-ui";

import { RefreshIconButton } from "@/app/components/refresh-icon-button";

import { RetirementHistoryViewProps } from "./retirement-history.types";
import RetirementHistoryHeader from "./retirement-history-header/retirement-history-header";
import RetirementHistoryTable from "./retirement-history-table/retirement-history-table";

export const RetirementHistoryView: React.FC<RetirementHistoryViewProps> = ({
  applyFilters,
  data,
  fetchData,
  pageInfo,
  loading,
  refreshData,
  onSeeDetails,
}) => {
  return (
    <Box as="main" sx={{ m: 4 }}>
      <RetirementHistoryHeader applyFilters={applyFilters} />
      <Flex sx={{ alignItems: "center", justifyContent: "flex-end", mb: 2 }}>
        <RefreshIconButton loading={loading} fetchData={refreshData} />
      </Flex>
      <RetirementHistoryTable
        loading={loading}
        fetchData={fetchData}
        pageInfo={pageInfo}
        data={data}
        onSeeDetails={onSeeDetails}
      />
    </Box>
  );
};
