import {
  IconCheckInCircle,
  IconClipboard,
  IconCompany,
  IconCreditCard,
  IconEmail,
  IconGroup,
  IconLinkThin,
  IconMarket,
  IconRegion,
} from "@powerledger/ui-component-lib";
import { ReactElement } from "react";
export const getIconComponents: Record<string, ReactElement> = {
  Email: <IconEmail width={36} height={32} color="text" />,
  Region: <IconRegion width={36} height={32} color="text" />,
  Clipboard: <IconClipboard width={36} height={32} color="text" />,
  Link: <IconLinkThin width={36} height={32} stroke="text" color="transparent" />,
  Market: <IconMarket width={36} height={32} stroke="text" color="transparent" />,
  Group: <IconGroup width={36} height={32} stroke="text" color="transparent" />,
  CreditCard: <IconCreditCard width={36} height={32} stroke="text" color="transparent" />,
  Company: <IconCompany width={36} height={32} stroke="text" color="transparent" />,
  CheckInCircle: <IconCheckInCircle width={36} height={32} stroke="text" color="transparent" />,
};
