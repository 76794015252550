import { Box, Table } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import { ContactSupportMailLink } from "@/app/container/contact-support-mail-link";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { getVintageHalfText } from "@/app/lib/get-vintage-half-text";
import { getTotalValue, getTransactionFee } from "@/app/lib/order-helpers";
import { OrderPosition } from "@/app/types/generated/graphql";

import { SellFormValues } from "../../pages/sell-order/sell-order.types";
import { GenericTooltip } from "../tooltips";

export const SellOrderSummaryTable = ({ data }: { data: SellFormValues }) => {
  const { t } = useTranslation();

  const tableColumns = useMemo(() => {
    const cols: Column<SellFormValues["values"][0]>[] = [
      {
        Header: t("Vintage"),
        accessor: (row) =>
          `${t(row.attributes.vintage.toString())} ${t(getVintageHalfText(row.attributes.vintageHalf, "-"))}`,
        Cell: ({ value }: { value: string }) => {
          return <Box sx={{ color: "textDarker" }}>{value}</Box>;
        },
      },

      {
        Header: t("Project"),
        accessor: "project",
        Cell: ({ value }) => {
          return <Box sx={{ color: "textDarker" }}>{t(value) || "-"}</Box>;
        },
        disableSortBy: true,
      },
      {
        Header: t("Quantity"),
        accessor: "volume",
        Cell: ({ value }) => {
          return <Box sx={{ color: "textDarker" }}>{t(value.toLocaleString()) ?? "-"}</Box>;
        },
        disableSortBy: true,
      },
      {
        Header: (
          <Box
            sx={{
              width: "100px",
              textAlign: "right",
            }}
          >
            {t("Unit Price")}
          </Box>
        ),
        accessor: "unitPrice",
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <Box sx={{ color: "textDarker", textAlign: "right", width: "100px" }}>
              {t(`${currency(value).format()}`) || "-"}
            </Box>
          );
        },
      },
      {
        Header: (
          <Box
            sx={{
              width: "100px",
              textAlign: "right",
              whiteSpace: "break-spaces",
            }}
          >
            {t("Transaction Fee ({{value}}%)", { value: data.fee * 100 })}
          </Box>
        ),
        id: "txnFee",
        width: "100px",
        disableSortBy: true,
        accessor: (row) => getTransactionFee(row.unitPrice, row.volume, data.fee).format(),
        Cell: ({ value }: { value: string }) => {
          return <Box sx={{ color: "textDarker", textAlign: "right", width: "100px" }}>{`-${t(value)}`}</Box>;
        },
      },
      {
        Header: (
          <Box
            sx={{
              width: "100px",
              textAlign: "right",
            }}
          >
            {t("Total Price")}
          </Box>
        ),
        width: "100px",
        id: "totalPrice",
        disableSortBy: true,
        accessor: (row) => getTotalValue(row.unitPrice, row.volume, OrderPosition.Ask, data.fee).format(),
        Cell: ({ value }: { value: string }) => {
          return <Box sx={{ width: "120px", px: 2, color: "textDarker", textAlign: "right" }}>{t(value)}</Box>;
        },
      },
    ];
    if ((data?.values ?? []).some((d) => !!d.reason)) {
      cols.push({
        Header: t("Reason"),
        accessor: "reason",
        Cell: ({ value }: { value: string }) => (
          <Box
            sx={{
              fontWeight: "bold",
              a: {
                color: "accent",
              },
            }}
          >
            <ContactSupportMailLink description={t(value)} />
          </Box>
        ),
        disableSortBy: true,
      });
    }
    return cols;
  }, [data, t]);

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={data?.values ?? []}
        noHover
        pagination={false}
        selectorPosition="left"
        translation={getTableTranslation(t)}
      />
      <GenericTooltip />
    </>
  );
};
