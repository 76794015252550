import { Box } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { getSupportEmail } from "@/app/lib/get-support-email";

interface SelectPaymentMethodErrorProps {
  errorMessage?: string;
}

export const SelectPaymentMethodError: FC<SelectPaymentMethodErrorProps> = ({ errorMessage }) => {
  const { t } = useTranslation();
  const supportEmail = getSupportEmail();
  return (
    <Box sx={{ color: "error.500", fontSize: 1, mt: 3, textAlign: "right" }}>
      {errorMessage
        ? t(
            "Payment method linking has failed: {{errorMessage}}. Please try again or contact support at {{supportEmail}} if the issue persists.",
            { errorMessage: errorMessage, supportEmail },
          )
        : t(
            "Payment method linking has failed. Please try again or contact support at {{supportEmail}} if the issue persists.",
            {
              supportEmail,
            },
          )}
    </Box>
  );
};
