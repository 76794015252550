import { Flex, Text } from "@powerledger/ui-component-lib";
import { FC } from "react";

export const ApplicationStatusStepIcon: FC<{ label: string }> = ({ label }) => (
  <Flex
    sx={{
      width: 3,
      height: 3,
      bg: "shadow",
      borderRadius: 6,
      justifyContent: "center",
      alignItems: "center",
      mx: 2,
      fontSize: 0,
      flexShrink: 0,
    }}
  >
    <Text sx={{ color: "background" }}>{label}</Text>
  </Flex>
);
