import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { subYears } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { useHasPermissions } from "@/app/hooks/use-has-permission";
import { hasPersonalRegistries } from "@/app/lib/account-service-helpers";
import { getDatePart } from "@/app/lib/date-helpers";
import {
  RecAssetAttributes,
  RegistryServiceSortOrderInput,
  useFewAccountDetailsQuery,
  usePaginatedHoldingsQuery,
} from "@/app/types/generated/graphql";

import { defaultFilter, mapStackedFilterStateToFilterState } from "../../../components/page-header/filter";
import { FilterState } from "../../../components/page-header/filter/filter.types";
import { useRecOptions } from "../../../hooks/use-rec-options";
import { formatAttributes, ReturnTypeFormatAttributes } from "../../../lib/format-attributes";
import { RecHoldingsTableData, RecHoldingsViewProps } from "./rec-holdings.types";

export const useRecHoldings = (): RecHoldingsViewProps => {
  const [selected, setSelected] = useState<string[]>([]);
  const [activeFilters, setActiveFilters] = useState<FilterState>(defaultFilter);
  const navigate = useNavigate();

  const { data: accountData } = useFewAccountDetailsQuery();

  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const { hasPermissions } = useHasPermissions();

  const { pageInfo, offset, fetchData, sort, resetPage, setTotalItems } = useTablePaginationManager<
    RecHoldingsTableData,
    RegistryServiceSortOrderInput[]
  >({});

  const { data, loading, previousData, refetch } = usePaginatedHoldingsQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          recAttributes: {
            eligibilities: activeFilters.eligibilities,
            certifications: activeFilters.certifications,
            locations: activeFilters.locations,
            fuelSources: activeFilters.fuelSources,
            vintages: activeFilters.vintages,
            projects: activeFilters.projects,
            commencementDate: activeFilters.cod?.length
              ? getDatePart(subYears(new Date(), +activeFilters.cod?.[0]))
              : undefined,
          },
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data?.paginatedHoldings?.offsetInfo?.total ?? 0);
    },
  });

  const currentData = useMemo(() => data ?? previousData, [data, previousData]);

  const refetchHoldings = () => {
    refetch();
  };

  const recHoldingsTableData: RecHoldingsTableData[] = useMemo(() => {
    return (
      currentData?.paginatedHoldings?.holdings?.map((recHolding) => {
        const attributes = recHolding.attributes as RecAssetAttributes;
        const formattedAttributes = recOptions
          ? formatAttributes({
              attributes,
              options: recOptions,
            })
          : ({} as ReturnTypeFormatAttributes);
        return {
          ...recHolding,
          attributes,
          ...formattedAttributes,
        };
      }) || []
    );
  }, [currentData?.paginatedHoldings?.holdings, recOptions]);

  const handleFiltersChange = useCallback(
    (filters) => {
      setActiveFilters(mapStackedFilterStateToFilterState(filters));
      resetPage();
    },
    [resetPage],
  );

  const onSell = useCallback(() => {
    navigate("/recs/holdings/sell", { state: { selectedRecs: selected } });
  }, [navigate, selected]);

  const onRetire = useCallback(() => {
    navigate("/recs/managed-recs/retire", { state: { selectedRecs: selected } });
  }, [navigate, selected]);

  const onExport = useCallback(() => {
    navigate("/recs/holdings/export", { state: { selectedRecs: selected } });
  }, [navigate, selected]);

  const pageTitle = hasPersonalRegistries(accountData?.account?.type) ? "REC Holdings" : "Managed RECs";

  return {
    pageTitle,
    handleFiltersChange,
    refetchHoldings,
    recOptions,
    loading: loading || recOptionsLoading,
    recHoldingsTableData,
    fetchData,
    pageInfo,
    setSelected,
    selected,
    onSell,
    onRetire,
    onExport,
    hasPermissions,
  };
};
