import { AuthnTransaction } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { Box, Button, Flex, IconLoadingOutlined, Modal } from "@powerledger/ui-component-lib";
import { Formik, FormikErrors } from "formik";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";

import { ErrorMessage, Form, FormFieldLabel, FormInput } from "@/app/components/form";
import { ModalContext } from "@/app/modal-provider/modal-context";

const handleMFAChallenge = async (transaction: AuthnTransaction, passCode: string): Promise<AuthnTransaction> => {
  // Transaction provided could be ANY state so make sure it is the expected one
  if (transaction.status !== "MFA_CHALLENGE") {
    throw new Error("Unexpected authentication state reached. Expected MFA_CHALLENGE after verify factor");
  }
  // Transaction object functions could be null state so make sure it is there
  if (!transaction.verify) {
    throw new Error("MFA challenge transaction in an invalid state. Verify function unavailable");
  }

  return transaction.verify({
    passCode,
  });
};

const handleMFARequired = async (transaction: AuthnTransaction): Promise<AuthnTransaction> => {
  // Transaction provided could be ANY state so make sure it is the expected one
  if (transaction.status !== "MFA_REQUIRED") {
    throw new Error("Unexpected authentication state reached. Expected MFA_REQUIRED after login");
  }
  // Transaction factors variable could be null or empty
  if (!transaction.factors || transaction.factors.length === 0) {
    throw new Error("MFA required but no factors active");
  }

  // Get the google authenticator factor as that is the only one supported
  // TODO: replace this with a selector for the user to select the MFA type they wish to verify with
  const googleAuthFactor = transaction.factors.find(
    (factor) => factor.provider === "GOOGLE" && factor.factorType === "token:software:totp",
  );

  // Make sure the google authentication factor was found
  if (!googleAuthFactor) {
    throw new Error("No supported factor active. Only MFA via the Google Authenticator app is supported");
  }

  return googleAuthFactor.verify();
};

export const MFAChallengeInput = ({
  visible,
  closeModal,
  loginTransaction,
}: {
  visible: boolean;
  loginTransaction: AuthnTransaction | undefined;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const { oktaAuth } = useOktaAuth();
  const { showContactSupportModal } = useContext(ModalContext);

  const submitCallback = useCallback(
    async (
      passCode: string,
      setErrors: (
        errors: FormikErrors<{
          passCode: string;
        }>,
      ) => void,
    ) => {
      try {
        if (!loginTransaction) {
          throw new Error("MFA tranasaction is undefined");
        }

        const mfaRequiredTransaction = await handleMFARequired(loginTransaction);
        const mfaChallengeTransaction = await handleMFAChallenge(mfaRequiredTransaction, passCode);

        if (mfaChallengeTransaction.status !== "SUCCESS") {
          throw new Error("MFA challenge transaction was not successful");
        }

        await oktaAuth.token.getWithRedirect({
          sessionToken: mfaChallengeTransaction.sessionToken,
        });
      } catch (error: any) {
        switch (error?.xhr?.status) {
          case 400:
            setErrors({ passCode: "Invalid passcode provided, please try again" });
            break; // Bad request, body was malformed
          case 401:
            setErrors({ passCode: "Invalid passcode provided, please try again" });
            break; // Unauthorized, missing authentication in header or body
          case 403:
            setErrors({ passCode: "Invalid passcode provided, please try again" });
            break; // Forbidden, Incorrect passcode provided
          case 500:
            setErrors({ passCode: "There was an issue reaching the MFA provider, please try again" });
            break;
          default:
            showContactSupportModal();
        }
      }
    },
    [loginTransaction, oktaAuth.token, showContactSupportModal],
  );

  return (
    <Modal modalMaxWidth={"600px"} visible={visible} onCancel={closeModal} title={t("Multifactor Authentication")}>
      <Box>
        <Formik
          initialValues={{
            passCode: "",
          }}
          validationSchema={object().shape({
            passCode: string()
              .matches(/^[0-9]*$/, "The code should only contain numbers")
              .min(6, "The code should contain 6 numbers")
              .max(6, "The code should only contain 6 numbers")
              .required("A code is required"),
          })}
          onSubmit={({ passCode }, { setErrors }) => submitCallback(passCode, setErrors)}
          validateOnMount
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Item>
                <FormFieldLabel
                  name="passCode"
                  hasErrorMessage={false}
                  label={t("Enter a code generated by the Google Authenticator app")}
                >
                  <FormInput
                    name="passCode"
                    type="text"
                    id="token"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    autoComplete="one-time-code"
                    maxLength={6}
                  />
                </FormFieldLabel>
                <ErrorMessage name="passCode" sx={{ pt: 3 }} />
              </Form.Item>

              <Flex sx={{ justifyContent: "flex-end", mt: 2 }}>
                <Button variant="primary" type="submit" disabled={isSubmitting || !isValid}>
                  {isSubmitting ? <IconLoadingOutlined /> : t("Submit")}
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
