import { Button, ChevronDownSVG, Chip, Scrollable } from "@powerledger/ui-component-lib";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, ThemeUIStyleObject } from "theme-ui";

import { useOutSideBoundaryClick } from "@/app/hooks/use-outside-boundary-click";
import { ModalContext } from "@/app/modal-provider/modal-context";

import { Filter, useFilter } from "./filter";
import { ApplyFiltersFn, FiltersType, StackedFilterState, StackedValueState } from "./filter/filter.types";
import { HeaderText } from "./header-text";

type ActionProps = { groupId?: string; hide: boolean; disable?: boolean; onClick?: () => void };

type Action = {
  buyAction?: ActionProps;
  sellAction?: ActionProps;
  exportAction?: ActionProps;
  retireAction?: ActionProps;
};

const defaultActionsProps = { hide: true, disable: false, onClick: () => {} };

const ActionDropdown = ({
  children,
  disabled,
  sx,
  menuOpen = false,
}: {
  disabled: boolean;
  sx?: ThemeUIStyleObject;
  children: React.ReactNode;
  menuOpen: boolean;
}) => {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(menuOpen);
  const { menuRef, buttonRef } = useOutSideBoundaryClick({ setShowMenu });

  return (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
    >
      <Button
        onClick={() => setShowMenu((open) => !open)}
        ref={buttonRef}
        disabled={disabled}
        sx={{
          svg: {
            ...(showMenu && { transform: `rotate(180deg)` }),
          },
        }}
      >
        {t("Actions")}
        <ChevronDownSVG width={20} />
      </Button>
      {showMenu && (
        <Box
          ref={menuRef}
          sx={{
            position: "absolute",
            zIndex: 2,
            p: 3,
            background: "plain",
            boxShadow: "0 0 10px  var(--theme-ui-colors-shadow)",
            width: 200,
            right: 0,
            mt: 2,
            borderRadius: 4,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export const PageHeader = ({
  title,
  filterProps,
  actions,
  styles,
  actionMenuOpen = false,
}: {
  styles?: {
    containerSx?: ThemeUIStyleObject;
    headerSx?: ThemeUIStyleObject;
  };
  actionMenuOpen?: boolean;
  title: string;
  actions?: Action;
  filterProps: {
    values?: StackedFilterState;
    initialValues?: StackedFilterState;
    filters?: FiltersType;
    shouldSelectVintage?: boolean;
    hideStatusFilter?: boolean;
    applyFilters: ApplyFiltersFn;
    customSetter?: React.Dispatch<React.SetStateAction<StackedFilterState>>;
  };
}) => {
  const { t } = useTranslation();

  const {
    buyAction = defaultActionsProps,
    sellAction = defaultActionsProps,
    retireAction = defaultActionsProps,
    exportAction = defaultActionsProps,
  } = actions || {};

  const {
    buttonDisabled,
    someValueFilled,
    filters,
    stackedValues,
    appliedFilterValues,
    applyFilter,
    resetFilter,
    removeValue,
    addValue,
  } = useFilter({
    shouldSelectVintage: filterProps.shouldSelectVintage,
    hideStatusFilter: filterProps.hideStatusFilter,
    applyFilters: filterProps.applyFilters,
    overrideFilters: filterProps.filters,
    customValues: filterProps.values,
    initialValues: filterProps.initialValues,
    customSetter: filterProps.customSetter,
  });
  const { showBuyOrderModal } = useContext(ModalContext);
  const openBuyModal = useCallback(() => {
    showBuyOrderModal(appliedFilterValues);
  }, [appliedFilterValues, showBuyOrderModal]);

  const moreThanOneAction =
    Object.keys(actions || {}).filter((action) => !actions?.[action as keyof Action]?.hide).length > 1;

  const buttonStyles = moreThanOneAction
    ? {
        variant: "buttons.secondary",
        width: "100%",
      }
    : { variant: "buttons.primary", ml: ["auto", ""] };

  const buyActionButton = (
    <Button sx={buttonStyles} disabled={buyAction.disable} onClick={openBuyModal}>
      {t("Buy")}
    </Button>
  );

  const sellActionButton = (
    <Button disabled={sellAction.disable} onClick={sellAction.onClick} sx={buttonStyles}>
      {t("Sell")}
    </Button>
  );

  const retireActionButton = (
    <Button disabled={retireAction.disable} onClick={retireAction.onClick} sx={buttonStyles}>
      {t("Retire")}
    </Button>
  );

  const exportActionButton = (
    <Button disabled={exportAction.disable} sx={buttonStyles} onClick={exportAction.onClick}>
      {t("Export")}
    </Button>
  );

  const actionList = (
    <>
      {!buyAction.hide && buyActionButton}
      {!sellAction.hide && sellActionButton}
      {!retireAction.hide && retireActionButton}
      {!exportAction.hide && exportActionButton}
    </>
  );

  return (
    <Flex
      sx={{
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 2,
          width: "100%",
          mb: 3,
          ...(styles?.containerSx ?? {}),
        }}
      >
        <HeaderText title={title} sx={styles?.headerSx ?? {}} />
        <Box
          sx={{
            ml: "auto",
            position: "relative",
          }}
        >
          <Filter
            buttonDisabled={buttonDisabled}
            someValueFilled={someValueFilled}
            filters={filters}
            resetFilter={resetFilter}
            applyFilter={applyFilter}
          />
        </Box>
        <Flex sx={{ width: 210, ml: "auto" }}>
          {moreThanOneAction ? (
            <ActionDropdown
              menuOpen={actionMenuOpen}
              disabled={Boolean(sellAction.disable && exportAction.disable)}
              sx={{
                ml: ["auto", ""],
              }}
            >
              <Flex
                sx={{
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {actionList}
              </Flex>
            </ActionDropdown>
          ) : (
            <>{actionList}</>
          )}
        </Flex>
      </Flex>
      <Box
        sx={{
          position: "relative",
        }}
      >
        {!!stackedValues.length && (
          <Flex
            sx={{
              gap: 2,
              my: 2,
              fontSize: 0,
              alignItems: "center",
              mt: -15,
            }}
          >
            <Box sx={{ width: 3, height: 3, borderRadius: "100%", bg: "blue.700" }} /> {t("Selected, Not Applied")}
            <Box sx={{ width: 3, height: 3, borderRadius: "100%", bg: "primary.700" }} /> {t("Currently Applied")}
            <Box sx={{ width: 3, height: 3, borderRadius: "100%", bg: "error.500" }} /> {t("To Be Deleted")}
          </Flex>
        )}
        <Scrollable
          translation={{
            scrollLeft: t("Scroll Left"),
            scrollRight: t("Scroll Right"),
          }}
        >
          {stackedValues.map((value) => {
            const variant =
              value.state === StackedValueState.DELETING
                ? "error"
                : value.state === StackedValueState.ADDING
                ? "info"
                : "primary";

            const addRemoveProps = {
              ...(value.state === StackedValueState.ADDING || value.state === StackedValueState.ADDED
                ? { onRemove: () => removeValue(value.type, value.value) }
                : {
                    onAdd: () => {
                      addValue(value.type, value);
                    },
                  }),
            };
            return (
              <Scrollable.Item
                key={value.value + value.type}
                sx={{
                  overflow: "hidden",
                  flexShrink: 0,
                }}
              >
                <Chip label={value.label} variant={variant} {...addRemoveProps} />
              </Scrollable.Item>
            );
          })}
        </Scrollable>
      </Box>
    </Flex>
  );
};
