import { Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { AccountStatus, useAccountQuery } from "@/app/types/generated/graphql";

import { UnlinkFundingSourceProps } from "./unlink-funding-source.types";
import { UnlinkFundingSourceModalText } from "./unlink-funding-source-modal-texts";

export const UnlinkFundingSourceModal: FC<UnlinkFundingSourceProps> = ({ visible, closeModal, onConfirm, loading }) => {
  const { t } = useTranslation();
  const { data: accountData, loading: accountDataLoading } = useAccountQuery();

  const confirm = useCallback(async () => {
    closeModal();
    await onConfirm();
  }, [onConfirm, closeModal]);

  return (
    <Modal
      visible={visible}
      maskClosable
      onCancel={closeModal}
      sx={{ p: 4 }}
      hideCloseButton
      title={t("Are you sure you want to remove this bank account?")}
    >
      {loading || accountDataLoading ? (
        <Flex
          sx={{
            justifyContent: "center",
          }}
        >
          <IconLoading sx={{ ml: 2 }} />
        </Flex>
      ) : (
        <>
          {accountData?.account?.status !== AccountStatus.Onboarding && (
            <UnlinkFundingSourceModalText isActive={accountData?.account?.status === AccountStatus.Active} />
          )}

          <Flex sx={{ justifyContent: "flex-end", mt: 4, gap: 2 }}>
            <Button
              type="button"
              variant="error"
              sx={{ width: 9 }}
              onClick={closeModal}
              disabled={loading || accountDataLoading}
            >
              {t("Cancel")}
            </Button>
            <Button variant="primary" sx={{ width: 9 }} onClick={confirm} disabled={loading}>
              {t("Confirm")}
            </Button>
          </Flex>
        </>
      )}
    </Modal>
  );
};
