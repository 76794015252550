import "./wdyr";
import "./app/lib/i18n";
import "./app/lib/custom-yup-methods";

import { Flex, PLCLProvider } from "@powerledger/ui-component-lib";
import * as Sentry from "@sentry/react";
import React, { Suspense } from "react";
import ReactDom from "react-dom";

import App from "./app/app";
import { GlobalErrorBoundary } from "./app/components/error-boundary";
import { themeSwitchOverride, tracexTheme } from "./app/container/theme-override";
import { APOLLO_GRAPH_VARIANT, ENABLE_SENTRY_TRACKING, SENTRY_DSN } from "./app/lib/env-helpers";
//@ts-ignore
import LogoCarbonDevice from "./assets/logos/logo-carbon-device";

if (ENABLE_SENTRY_TRACKING) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: APOLLO_GRAPH_VARIANT,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const themeSwitch = APOLLO_GRAPH_VARIANT === "development" ? themeSwitchOverride : {};
ReactDom.render(
  <React.StrictMode>
    <PLCLProvider themeOverwrites={{ ...tracexTheme, ...themeSwitch }}>
      <GlobalErrorBoundary>
        <Suspense
          fallback={
            <Flex
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <LogoCarbonDevice />
            </Flex>
          }
        >
          <App />
        </Suspense>
      </GlobalErrorBoundary>
    </PLCLProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
