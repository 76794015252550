import { IconCircleTick, IconError, IconPending, IconProps } from "@powerledger/ui-component-lib";
import { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ModalContext } from "@/app/modal-provider";
import { LinkedRegistry, RegistryLinkStatus, useRegistriesQuery } from "@/app/types/generated/graphql";

import { UseLinkedRegistryBlock } from "./linked-registry-block.types";

export const linkedRegistryStatusIconsMap: Record<RegistryLinkStatus, FC<IconProps>> = {
  REGISTERED: (props) => <IconCircleTick color="primary.500" {...props} />,
  PENDING: (props) => <IconPending color="warning.500" {...props} />,
  REGISTRATION_FAILED: (props) => <IconError color="error.500" {...props} />,
};
export const useLinkedRegistryBlock = (registry: LinkedRegistry): UseLinkedRegistryBlock => {
  const { t } = useTranslation();
  const { data, loading: loadingRegistries } = useRegistriesQuery({
    variables: { where: { idIn: [registry.id] } },
  });

  const { showRegistryModal } = useContext(ModalContext);

  const registryData = useMemo(() => data?.registries && data?.registries[0], [data?.registries]);

  const LinkedAccountStateIcon = useMemo(
    () => registry.status && linkedRegistryStatusIconsMap[registry.status],
    [registry.status],
  );

  const linkingStatusText = useMemo(() => {
    switch (registry.status) {
      case RegistryLinkStatus.Pending:
        return t("Linking in progress");
      case RegistryLinkStatus.Registered:
        return `ID: ${registry?.registryOrgLinkId}`;
      default:
        return t("Linking has failed");
    }
  }, [registry.status, registry?.registryOrgLinkId, t]);

  return {
    loadingRegistries,
    LinkedAccountStateIcon,
    registryData,
    linkingStatusText,
    showRegistryModal,
  };
};
