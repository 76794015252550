import { Chip } from "@powerledger/ui-component-lib";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Text, ThemeUIStyleObject } from "theme-ui";

import { SignupStepperStages } from "@/app/lib/account-service-helpers";

import { ErrorDisplayPopperViewProps, ErrorsMapValue } from "./error-display-popper.types";
import { checkNestedErrorsInErrorMap, isErrorMap } from "./use-error-display-popper";

export const ErrorDisplayPopperView: React.FC<ErrorDisplayPopperViewProps> = ({
  showErrors,
  showMenu,
  setShowMenu,
  errorsMap,
}) => {
  const { t } = useTranslation();

  if (!showErrors) return <></>;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "30px",
        right: 0,
        zIndex: 100,
        p: 3,
        borderRadius: 10,
        width: "400px",
        height: showMenu ? "80vh" : "40px",
        transition: "0.3s ease-in-out",
        bg: showMenu ? "error.100" : "",
      }}
    >
      <Flex
        sx={{
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            fontSize: 0,
          }}
          variant="error"
          onClick={() => setShowMenu((prev) => !prev)}
        >
          {t("Review and Fix Errors")}
        </Button>
      </Flex>
      <Box
        sx={{
          overflowY: "auto",
          maxHeight: "70vh",
        }}
      >
        <StepsErrorView title={SignupStepperStages["ApplicantDetails"]} errors={errorsMap.Applicant_Details} />
        <StepsErrorView
          title={SignupStepperStages.ApplicantControllerDetails}
          errors={errorsMap.Applicant_Controller_Details}
        />
        <StepsErrorView
          title={SignupStepperStages.ApplicantBeneficialOwners}
          errors={errorsMap.Applicant_Beneficial_Owners}
        />
        <StepsErrorView title={SignupStepperStages.RequiredDocumentation} errors={errorsMap.Required_Documentation} />
      </Box>
    </Box>
  );
};

const StepsErrorView = ({
  sx = { mt: 3 },
  title,
  errors,
}: {
  sx?: ThemeUIStyleObject;
  title: string;
  errors?: ErrorsMapValue;
}) => {
  const { t } = useTranslation();

  if (!errors) return <></>;

  if (typeof errors === "string") return <></>;

  if (!isErrorMap(errors)) {
    const hasErrors = checkNestedErrorsInErrorMap(errors);
    return (
      <Box sx={sx}>
        {hasErrors && (
          <Text
            sx={{
              textDecoration: "underline",
              fontWeight: "bolder",
            }}
          >
            {t(title)}
          </Text>
        )}
        {Object.keys(errors).map(
          (error) =>
            errors[error] && (
              <StepsErrorView
                key={error}
                title={error}
                errors={errors[error]}
                sx={{
                  fontSize: 0,
                  px: 2,
                }}
              />
            ),
        )}
      </Box>
    );
  }
  return (
    <Box sx={sx}>
      <Text
        sx={{
          textDecoration: "underline",
          fontWeight: "bolder",
        }}
      >
        {t(title)}
      </Text>
      <Box
        sx={{
          fontSize: 1,
        }}
      >
        {ReactHtmlParser(errors.edited ? `<s>${errors.errors}</s>` : errors.errors)}
        {errors.edited && <Chip variant="info" label={t("Updated")} />}
      </Box>
    </Box>
  );
};
