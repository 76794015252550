import { Dispatch, SetStateAction } from "react";

import { InboxMessage } from "@/app/types/generated/graphql";

export enum NotificationsTabType {
  READ = "Read",
  UNREAD = "Unread",
}

export type NotificationViewProps = {
  loading: boolean;
  currentTab: NotificationsTabType;
  setCurrentTab: Dispatch<SetStateAction<NotificationsTabType>>;
  filteredNotifications: {
    allUnread: InboxMessage[];
    allRead: InboxMessage[];
  };
  markAsReadHandler: (id: string) => Promise<void>;
  markAsReadId: string;
};

export type NotificationsListProps = {
  notifications: InboxMessage[];
  markAsRead?: (id: string) => void;
  loading?: boolean;
  markAsReadId?: string;
};

export type UseNotificationsFn = (arg?: {
  showMenu?: boolean;
}) => NotificationViewProps & { unreadMessageCount: number };
