import {
  Box,
  Button,
  Flex,
  IconEmail,
  IconError,
  IconLoading,
  IconTick,
  ThemeUIStyleObject,
} from "@powerledger/ui-component-lib";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Account, useAccountQuery } from "@/app/types/generated/graphql";

import { useOnboardingContext } from "../../onboarding/context";
import { StepType } from "../../onboarding/onboarding.types";
import { getIconComponents } from ".";

export const Step: FC<{
  step: StepType;
  loading: boolean;
  stepIndex: number;
  sx?: ThemeUIStyleObject;
  goToStage: (stage: number) => void;
}> = ({ step, loading, sx, stepIndex, goToStage }) => {
  const { t } = useTranslation();
  const { data: accountData, loading: accountLoading } = useAccountQuery({
    notifyOnNetworkStatusChange: true,
  });
  const { currentStep, currentStage } = useOnboardingContext();

  const active = useMemo(() => currentStep === stepIndex, [currentStep, stepIndex]);

  const IconComponent = getIconComponents[step.icon] || IconEmail;

  const isStageActive = (stageIndex: number) => active && stageIndex === currentStage;

  const isStageDisabled = useCallback(
    (stage: StepType["stages"]["0"]) => accountLoading || stage.isDisabled?.(accountData?.account as Account),
    [accountLoading, accountData?.account],
  );

  const displayStepStateIndicator = useCallback(
    (stageIndex) => {
      const stage = step.stages[stageIndex];
      if (stage && isStageDisabled(stage)) {
        return <></>;
      }
      const accountInfo = accountData?.account;
      const validator = step.stages?.[stageIndex]?.validation;
      if (validator?.getData(accountInfo) && validator?.getSchema(accountInfo)) {
        const isValidated = validator.getSchema(accountInfo)?.isValidSync(validator.getData(accountInfo));

        if (isValidated) {
          return <IconTick size={3} noSvgPositioning color="positive" />;
        }
        if (stage.validation?.isOptional) {
          return <IconError size={3} noSvgPositioning color="yellow" />;
        }
        if (validator?.isTouched) {
          return accountLoading ? (
            <IconLoading size="tiny" sx={{ ml: 2 }} />
          ) : (
            <IconError size={3} noSvgPositioning color="negative" />
          );
        }
      }
      return null;
    },
    [accountData?.account, isStageDisabled, step.stages, accountLoading],
  );

  const showLoading = useCallback(
    (stageIndex) => active && stageIndex === currentStage && loading,
    [active, currentStage, loading],
  );

  if (step.isHidden?.(accountData?.account)) {
    return <></>;
  }

  return (
    <Flex sx={{ position: "relative", pb: 4, ...sx, maxWidth: "500px" }}>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: 6,
          height: 6,
          borderRadius: 4,
          transition: "base",
          ...(active ? { bg: "secondary" } : { bg: "transparent" }),
          flexShrink: 0,
        }}
        className="stepIconContainer"
      >
        {IconComponent}
      </Flex>
      <Box sx={{ ml: 3, flex: 1 }}>
        <Box
          sx={{
            fontSize: 1,
            fontFamily: "MintGroteskV08",
            transition: "base",
            fontWeight: "bold",
            ...(active ? { color: "layoutBackground" } : { color: "textDarker" }),
          }}
        >
          {step.title}
        </Box>
        {step.stages.map((stage, stageIndex) => (
          <Flex
            key={`stepper-${stepIndex}-${stageIndex}`}
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Button
              type="button"
              variant="text"
              key={stage.name}
              disabled={isStageDisabled(stage)}
              onClick={() => goToStage(stageIndex)}
              sx={{
                display: "block",
                textAlign: "start",
                fontSize: 1,
                ...(isStageDisabled(stage)
                  ? { opacity: 0.3 }
                  : isStageActive(stageIndex)
                  ? { color: "text", fontWeight: "500" }
                  : { color: "textDarker" }),
              }}
            >
              {t(stage.name)}
            </Button>
            <Box sx={{ flexShrink: 0, ml: 2 }}>
              {showLoading(stageIndex) ? (
                <IconLoading size="tiny" sx={{ ml: 2 }} />
              ) : (
                displayStepStateIndicator(stageIndex)
              )}
            </Box>
          </Flex>
        ))}
      </Box>
    </Flex>
  );
};
