import { useState } from "react";

import { ReceivedCounterOffersProps } from "./received-counter-offers.types";

export const useReceivedCounterOffers = (): ReceivedCounterOffersProps => {
  const [showAllAttributes, setShowAllAttributes] = useState(false);
  return {
    showAllAttributes,
    setShowAllAttributes,
  };
};
