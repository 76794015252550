import {
  BankSVG,
  Box,
  Button,
  Flex,
  getColor,
  IconLoading,
  IconTrash,
  SquareBlock,
  Text,
  useTheme,
} from "@powerledger/ui-component-lib";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import { SupportEmailTypes } from "@/app/lib/get-support-email";
import { BankAccountV2 as BankAccount, FundingSourceStatus } from "@/app/types/generated/graphql";

import { fundingSourceStatusIconsMap } from "../../funding-source-status-maps";
import { LinkedFundingSourceTooltip } from "../../linked-funding-source-tooltip";
import { UnlinkFundingSourceModal } from "../../unlink-funding-source-modal";
import { BankAccountProps } from "./bank-account.types";

export const BankAccountView: FC<BankAccountProps> = ({
  linkedPaymentMethod,
  loading,
  showUnlinkButton,
  showUnlinkModal,
  setShowUnlinkModal,
  removeFundingSource,
  unlinkLoading,
  plaidLinkError,
  disablePlaidModal,
  openPlaidModal,
  fundingSourceStatusLabel,
  showCompleteLinkButton,
  bankAccountErrorMessage,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const bankAccount = linkedPaymentMethod.fundingSource as BankAccount;

  const FundingSourceStatusIcon = bankAccount.status && fundingSourceStatusIconsMap[bankAccount.status];

  const tooltipContent = useMemo(() => {
    if (disablePlaidModal) {
      return (
        <LinkedFundingSourceTooltip
          message={`Bank account linking unsuccessful, please unlink bank account and try again`}
          supportEmailType={SupportEmailTypes.SUPPORT_EMAIL_INTERNAL_KEY}
        />
      );
    }
    switch (bankAccount.status) {
      case FundingSourceStatus.WaitingForCustomerAction:
        return (
          <LinkedFundingSourceTooltip
            message={
              plaidLinkError
                ? `${plaidLinkError.message}, please`
                : `Select 'Complete link' in order to continue linking your bank account with the required details`
            }
          />
        );
      case FundingSourceStatus.Verifying:
        return (
          <LinkedFundingSourceTooltip
            message={`Awaiting verification from the payment provider in order to complete linking your bank account`}
          />
        );
      default:
        return undefined;
    }
  }, [disablePlaidModal, bankAccount.status, plaidLinkError]);

  return (
    <>
      <Flex sx={{ alignItems: "center" }}>
        <ReactTooltip
          id="linked-funding-source-tooltip"
          overridePosition={({ top, left }) => ({ top: top + 20, left })}
          backgroundColor={getColor(theme, "secondaryDark")}
          clickable
          getContent={() => tooltipContent}
          disable={!tooltipContent}
        />
        <SquareBlock sx={{ width: ["100%", null, null, "50%"], px: 2, ml: -2, maxWidth: 300 }}>
          {loading || unlinkLoading ? (
            <IconLoading />
          ) : (
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                flexDirection: "column",
                width: "100%",
              }}
              data-for="linked-funding-source-tooltip"
              data-tip={tooltipContent}
              data-effect="solid"
            >
              <Box
                sx={{
                  my: 3,
                  svg: {
                    width: 62,
                    height: 51,
                  },
                }}
              >
                <BankSVG />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                }}
              >{`${bankAccount.currencyCode || "-"} ${bankAccount.accountName || "-"}`}</Box>
              <Text
                sx={{
                  fontSize: 1,
                  color: "textDark",
                  flexShrink: 0,
                  fontFamily: "IBMPlexMono",
                  textAlign: "center",
                }}
              >
                {`•••• •••• •••• ${bankAccount.accountNumberMasked || "••••"}`}
              </Text>
              <Flex sx={{ alignItems: "center" }}>
                {FundingSourceStatusIcon && <FundingSourceStatusIcon sx={{ flexShrink: 0 }} size={6} />}
                <Text sx={{ color: "textDarker" }}>{fundingSourceStatusLabel}</Text>
              </Flex>
              <Flex>
                {showCompleteLinkButton && (
                  <Flex sx={{ alignItems: "center", mt: 2 }}>
                    <Button
                      disabled={disablePlaidModal}
                      variant="secondary"
                      onClick={() => {
                        openPlaidModal();
                      }}
                    >
                      {t("Complete Link")}
                    </Button>
                  </Flex>
                )}
                {showUnlinkButton && (
                  <Button
                    data-testid={"bank-account--unlink-account"}
                    variant="text"
                    onClick={() => setShowUnlinkModal(true)}
                    sx={{ p: 0, mt: 2 }}
                  >
                    <IconTrash size={6} color="" stroke="error.500" aria-label={t("Unlink Account")} />
                  </Button>
                )}
              </Flex>
            </Flex>
          )}
        </SquareBlock>
      </Flex>
      {(bankAccountErrorMessage || plaidLinkError) && (
        <Box
          sx={{
            fontSize: 0,
            color: "error.500",
            mt: 2,
          }}
        >
          {bankAccountErrorMessage && t(bankAccountErrorMessage)}
          {plaidLinkError && t(plaidLinkError.message)}
        </Box>
      )}
      <UnlinkFundingSourceModal
        visible={showUnlinkModal}
        closeModal={() => setShowUnlinkModal(false)}
        onConfirm={removeFundingSource}
        loading={unlinkLoading}
      />
    </>
  );
};
