export const SSNerrorMessage = "Invalid Social Security Number";

export const validateSSN = (value: string) => {
  let error;
  const regexp = new RegExp("(?!0{4})\\d{4}$");
  if (!regexp.test(value)) {
    error = SSNerrorMessage;
  }
  return error;
};

export const validateFullSSN = (value: string) => {
  let error;
  const regexp = new RegExp("^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$");
  if (!regexp.test(value)) {
    error = SSNerrorMessage;
  }
  return error;
};
