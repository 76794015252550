import { Box, Chip } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { LocationChipsViewProps } from "./location-chips.types";
import { getCountriesWithLocationCount } from "./use-location-chips";

export const LocationChipsView: FC<LocationChipsViewProps> = ({
  activeCountry,
  locationTextWithCountry,
  removeValue,
  setActiveCountry,
}) => {
  const { t } = useTranslation();
  const countriesWithLocationCount = getCountriesWithLocationCount(locationTextWithCountry);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          mb: 1,
        }}
      >
        {Object.keys(countriesWithLocationCount).map((country) => (
          <Chip
            key={country}
            label={t("{{country}} ({{count}})", { country, count: countriesWithLocationCount[country] })}
            onRemove={
              removeValue
                ? () =>
                    removeValue(
                      "locations",
                      locationTextWithCountry.filter((l) => l.country === country).map((l) => l.value),
                    )
                : undefined
            }
            onClick={() => setActiveCountry((activeCountry) => (activeCountry === country ? "" : country))}
            sx={{
              ...(activeCountry !== country
                ? {
                    backgroundColor: "blueGray.200",
                    color: "blueGray.500",
                    cursor: "pointer",
                    "&:hover, &:focus": {
                      backgroundColor: "blueGray.300",
                      color: "blueGray.700",
                    },
                    "> div > svg > path": {
                      fill: "blueGray.700",
                    },
                    ">button": {
                      "&:enabled:hover, &:enabled:focus": {
                        backgroundColor: "blueGray.700",
                        "svg>path": {
                          fill: "blueGray.25",
                        },
                      },
                      "> div > svg > path": {
                        fill: "blueGray.700",
                      },
                    },
                  }
                : {}),
            }}
            ariaExpanded={activeCountry === country}
          />
        ))}
      </Box>
      {activeCountry ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            my: 3,
          }}
        >
          {locationTextWithCountry
            ?.filter((location) => location.country === activeCountry)
            ?.map((item) => (
              <Chip
                key={item.value}
                label={item.label}
                onRemove={removeValue ? () => removeValue("locations", item.value) : undefined}
              />
            ))}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
