import { useUserResources } from "@/app/pages/user-resources/use-user-resources";

import { UserResourcesView } from "./user-resources-view";

export const UserResources = ({ showHeader = true }) => {
  const logic = useUserResources();
  return <UserResourcesView {...logic} showHeader={showHeader} />;
};

export default UserResources;
