export enum UserRoles {
  RoleUser = "ROLE_USER",
  RoleAdmin = "ROLE_ADMIN",
}

/**
 *
 * If user have some roles that routeRole require than allow the access
 * If no userRoles is given, return false
 * @param routeRole
 * @param userRoles
 * @returns
 */

export function grantAccess(userRoles: UserRoles[], allowedRoles?: UserRoles[]) {
  // if allowedRoles is empty, then we allow access i.e. no roles required
  if (!allowedRoles) {
    return true;
  }

  // If UserRoles has some of the required allowedRoles, then allow access
  if (userRoles?.some((role) => allowedRoles.includes(role))) {
    return true;
  }

  // if userRoles is empty or undefined, then we want to deny access
  return false;
}
