import { FormikHelpers } from "formik";

import { MarketTableData } from "@/app/pages/markets";

export enum CounterSteps {
  COUNTER_FORM,
  COUNTER_SUBMIT,
  COUNTER_SUCCESS,
}

export type CreateCounterOfferModalDefaultProps = {
  visible: boolean;
  closeModal: () => void;
};

export type CreateCounterOfferModalCustomProps = {
  counteredTableData: MarketTableData;
  tenantID?: string;
};

export type CreateCounterOfferHookProps = CreateCounterOfferModalDefaultProps & CreateCounterOfferModalCustomProps;

export type CreateCounterOfferModalProps = CreateCounterOfferHookProps & {
  currentStep: CounterSteps;
  modalSubTitle: string;
  handleStepChange: (step: CounterSteps) => void;
  currencySymbol: string;
  onCounterConfirm: (values: FormValues, action: FormikHelpers<FormValues>) => void;
};

export type QuanityFieldProps = {
  currentStep: CounterSteps;
  field: {
    label: string;
    fieldName: string;
    hasPrefix: boolean;
    fieldType: string;
  };
  currencySymbol: string;
};

export type FormValues = {
  counterVolume: number;
  counterPrice: number;
};
