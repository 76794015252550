import { AccountQuery, ContactType } from "@/app/types/generated/graphql";

export const getControllerTouched = (account: AccountQuery["account"], pageTouched?: boolean) => {
  const controller = account?.company?.contacts?.find((c) => c.type === ContactType.Controller);
  if (!controller && !pageTouched) return;
  return {
    firstName: true,
    lastName: true,
    jobTitle: true,
    dateOfBirth: true,
    physicalAddress: {
      countryCode: true,
      state: true,
      city: true,
      line1: true,
      line2: true,
      postalCode: true,
    },
    identityNumber: true,
    passport: {
      number: true,
      countryCode: true,
    },
  };
};
