import { alpha, Box, Button, Flex, Spinner, Text } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import { MessageStatus } from "@/app/types/generated/graphql";

import { NotificationsListProps } from "./notifications.types";

export const NotificationLists: FC<NotificationsListProps> = ({ notifications, markAsRead, loading, markAsReadId }) => {
  const { t } = useTranslation();

  if (!notifications.length) {
    return (
      <Flex sx={{ alignItems: "center", justifyContent: "center", color: "textDarker", py: 2 }}>{t("No data")}</Flex>
    );
  }

  return (
    <Box sx={{ py: 3, px: 3, bg: alpha("text", 0.05) }}>
      {notifications.map((notification, idx) => (
        <Box
          key={notification.id}
          sx={{
            py: 3,
            ...(idx !== 0 && {
              borderTopColor: "backgroundLighter",
              borderTopWidth: 1,
              borderTopStyle: "solid",
            }),
          }}
        >
          <Flex sx={{ gap: 3, flexWrap: ["wrap", "nowrap"], flexDirection: ["column"] }}>
            <Flex sx={{ flex: 1 }}>
              <Box>
                <Box mt="2">
                  <Text mr="2" sx={{ fontWeight: "bold" }}>
                    {notification.subject}
                  </Text>
                  <Text sx={{ fontSize: 1 }}>
                    {formatDate(notification.dateReceived, {
                      formatStyle: AppDateFormats.UniversalDateTimeFormat,
                      noTZ: true,
                    })}
                  </Text>
                </Box>
                <Text sx={{ color: "textDarker", fontSize: 1 }}>
                  {t("{{body}}", {
                    body: notification.body,
                  })}
                </Text>
              </Box>
            </Flex>
            {notification.status === MessageStatus.Unread && (
              <Flex
                sx={{
                  ml: "auto",
                }}
              >
                <Button
                  disabled={loading}
                  onClick={() => markAsRead?.(notification.id)}
                  sx={{
                    width: 140,
                    height: 34,
                  }}
                >
                  {markAsReadId === notification.id ? <Spinner color="white" size={28} /> : t("Mark as read")}
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>
      ))}
    </Box>
  );
};
