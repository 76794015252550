import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { useCallback, useContext, useMemo, useState } from "react";

import { defaultFilter } from "@/app/components/page-header";
import { StackedFilterState } from "@/app/components/page-header/filter/filter.types";
import { ModalContext } from "@/app/modal-provider";
import {
  ExportRequestStatus,
  RegistryServiceSortOrderInput,
  useExportRequestsQuery,
  useFewAccountDetailsQuery,
} from "@/app/types/generated/graphql";

import { ExportHistoryTableData, UseExportHistoryFn } from "./export-history.types";

export const useExportHistory: UseExportHistoryFn = () => {
  const [filterValues, setFilterValues] = useState<StackedFilterState>(defaultFilter);

  const { showExportRequestDetailsModal } = useContext(ModalContext);

  const { pageInfo, fetchData, resetPage, setTotalItems, offset, sort } = useTablePaginationManager<
    ExportHistoryTableData,
    RegistryServiceSortOrderInput[]
  >({});

  const { data: accountData } = useFewAccountDetailsQuery();

  const {
    data: newData,
    loading,
    previousData,
    refetch,
  } = useExportRequestsQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          exportNumber: filterValues.actionCodes[0]?.value ?? undefined,
          statuses: filterValues.statuses.map((status) => status.value) as ExportRequestStatus[],
          requestedDateRange:
            filterValues.dateRange.length == 2
              ? {
                  fromDate: filterValues.dateRange[0].value,
                  toDate: filterValues.dateRange[1].value,
                }
              : null,
          accountId: accountData?.account?.id,
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data?.exportRequests?.offsetInfo?.total ?? 0);
    },
  });

  const applyFilters = useCallback(
    (arg) => {
      setFilterValues(arg);
      resetPage();
    },
    [resetPage],
  );

  const refreshData = useCallback(() => {
    refetch();
  }, [refetch]);

  const data = useMemo(() => (newData ?? previousData)?.exportRequests?.exportRequests ?? [], [newData, previousData]);

  const onSeeDetails = useCallback(
    (id: string) => {
      showExportRequestDetailsModal({
        id,
      });
    },
    [showExportRequestDetailsModal],
  );

  return { applyFilters, loading, refreshData, data, pageInfo, onSeeDetails, fetchData };
};
