import { Box, Button, Flex, Heading, IconLoading } from "@powerledger/ui-component-lib";
import { find } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormFieldLabel } from "@/app/components";
import { TRACEX_COUNTRY_FLAGS } from "@/app/lib/country-flag-helpers";
import { useFewAccountDetailsQuery, useTenantsQuery } from "@/app/types/generated/graphql";

import { UserSettingsForm } from "../mfa-settings";
import { ResetPasswordModal } from "./reset-password-modal";

export const PersonalDetailsTab = () => {
  const { t } = useTranslation();
  const { data: accountData, loading: accountLoading } = useFewAccountDetailsQuery();
  const { data: tenantsData, loading: tenantLoading } = useTenantsQuery();
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const account = useMemo(() => accountData?.account, [accountData]);

  const [countryCode, market] = useMemo(() => {
    const tenant = find(tenantsData?.tenants, { id: accountData?.account?.tenantId });
    return [tenant?.localisation?.countryCode, tenant?.localisation?.country];
  }, [accountData, tenantsData]);

  return (
    <>
      {(accountLoading || tenantLoading) && (
        <Flex sx={{ justifyContent: "center" }}>
          <IconLoading />
        </Flex>
      )}
      {!accountLoading && !tenantLoading && (
        <>
          <Heading as="h2">{t("Personal & Market Details")}</Heading>
          <Box sx={{ pt: 5 }}>
            <Flex sx={{ alignItems: "center" }}>
              <Box sx={{ flex: 1, px: 1 }}>
                <FormFieldLabel small label={t("Market")}>
                  {market && (
                    <Flex
                      sx={{
                        fontSize: 1,
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                        borderBottomColor: "textDarkest",
                        py: 2,
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: 32, alignItems: "center", mr: 2 }}>
                        {TRACEX_COUNTRY_FLAGS[countryCode ? countryCode.toLowerCase() : ""]}
                      </Box>
                      {t(market)}
                    </Flex>
                  )}
                </FormFieldLabel>
              </Box>
              <Box sx={{ flex: 1, pl: 2 }}>
                <FormFieldLabel small label={t("Email")}>
                  <Box
                    sx={{
                      fontSize: 1,
                      borderBottomWidth: 1,
                      borderBottomStyle: "solid",
                      borderBottomColor: "textDarkest",
                      py: 2,
                    }}
                  >
                    {account?.email}
                  </Box>
                </FormFieldLabel>
              </Box>
            </Flex>
            <Flex sx={{ mt: 4 }}>
              <Box sx={{ flex: 1, pr: 2 }}>
                <FormFieldLabel small label={t("First name")} />
                <Box
                  sx={{
                    fontSize: 1,
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: "textDarkest",
                    py: 2,
                  }}
                >
                  {account?.firstName}
                </Box>
              </Box>
              <Box sx={{ flex: 1, px: 1 }}>
                <FormFieldLabel small label={t("Last name")}>
                  <Box
                    sx={{
                      fontSize: 1,
                      borderBottomWidth: 1,
                      borderBottomStyle: "solid",
                      borderBottomColor: "textDarkest",
                      py: 2,
                    }}
                  >
                    {account?.lastName}
                  </Box>
                </FormFieldLabel>
              </Box>
              <Flex sx={{ flex: 1, pl: 2, alignItems: "center", justifyContent: "flex-end" }}>
                <Button
                  variant="text"
                  sx={{ color: "primary", fontSize: 0 }}
                  onClick={() => setShowResetPasswordModal(true)}
                >
                  {t("Reset Password")}
                </Button>
              </Flex>
            </Flex>
          </Box>
          <Box sx={{ pt: 5 }}>
            <UserSettingsForm />
          </Box>
          <ResetPasswordModal visible={showResetPasswordModal} closeModal={() => setShowResetPasswordModal(false)} />
        </>
      )}
    </>
  );
};
