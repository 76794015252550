import { useTranslation } from "react-i18next";
import { Flex } from "theme-ui";

import { PersonalInformationForm } from "@/app/components/personal-information-form";
import { StepButton, StepLayout } from "@/app/pages/public";
import { ContactType } from "@/app/types/generated/graphql";

import { ApplicantControllerStepViewProps } from "./applicant-controller-step.types";

export const ApplicantControllerStepView: React.FC<ApplicantControllerStepViewProps> = ({
  goBack,
  goNext,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  return (
    <StepLayout
      id="NominateController"
      heading={t("Applicant Controller Details")}
      description={t(
        "Please provide details of the Applicant's controller. A controller is a natural person who holds significant responsibilities to control, manage or direct a company (i.e. CEO, CFO, General Partner, President, etc). We are required to verify the identity of your company's controller for the purposes of opening the account and linking a Payment Services Provider.",
      )}
      actions={
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StepButton back onClick={goBack} disabled={isSubmitting} loading={isSubmitting} />
          <StepButton onClick={goNext} disabled={isSubmitting} loading={isSubmitting} />
        </Flex>
      }
    >
      <PersonalInformationForm formId={ContactType.Controller} />
    </StepLayout>
  );
};
