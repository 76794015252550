import { SelectPaymentMethodView } from "./select-payment-method-view";
import { useSelectPaymentMethod } from "./use-select-payment-method";

export const SelectPaymentMethod = ({ onSelectPaymentMethod }: { onSelectPaymentMethod: () => void }) => {
  const {
    disableNextButton,
    onNextButtonClick,
    acceptedLegalAgreement,
    setAcceptedLegalAgreement,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    loadingLinkPaymentMethod,
    loadingPaymentMethods,
    paymentMethods,
    error,
  } = useSelectPaymentMethod(onSelectPaymentMethod);

  return (
    <SelectPaymentMethodView
      loadingPaymentMethods={loadingPaymentMethods}
      loadingLinkPaymentMethod={loadingLinkPaymentMethod}
      disableNextButton={disableNextButton}
      onNextButtonClick={onNextButtonClick}
      selectedPaymentMethod={selectedPaymentMethod}
      setSelectedPaymentMethod={setSelectedPaymentMethod}
      acceptedLegalAgreement={acceptedLegalAgreement}
      setAcceptedLegalAgreement={setAcceptedLegalAgreement}
      paymentMethods={paymentMethods}
      error={error}
    />
  );
};
