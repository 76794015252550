import { accountQuery } from "./account";
import { assetsQuery } from "./assets";
import { documentsQuery } from "./documents";
import { generatorsQuery } from "./generators";
import { holdingsQuery } from "./holdings";
import { messagesQuery } from "./messages";
import { plaidLinkTokenQuery } from "./plaid-link-token";
import { registriesQuery } from "./registries";
import { tenantsQuery } from "./tenants";

export const typePolicies = {
  typePolicies: {
    Query: {
      fields: {
        holdings: holdingsQuery,
        account: accountQuery,
        registries: registriesQuery,
        assets: assetsQuery,
        messages: messagesQuery,
        generators: generatorsQuery,
        documents: documentsQuery,
        tenants: tenantsQuery,
        plaidLinkToken: plaidLinkTokenQuery,
      },
    },
  },
};
