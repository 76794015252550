import { IconInfo, Table } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import { Box, Button, Flex } from "theme-ui";

import { SeparatorLayout } from "@/app/components";
import { HeaderText } from "@/app/components/page-header";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { getRetirementReason, getRetirementType } from "@/app/lib/format-commodity-action-data-for-report";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import { getCommonRecTableData } from "../rec-table-column";
import { ActionValues, isRetireValues, RecActionSummaryProps } from "./rec-action-summary.types";

export default function RecActionSummary<T extends "retire" | "export">({
  action,
  data,
  recOptions,
  onProceed,
  onGoBack,
  infoText,
  buttonActionText,
}: Readonly<RecActionSummaryProps<T>>) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tableColumns = useMemo(() => {
    const commonColumns = [...getCommonRecTableData(t, recOptions)] as Column<ActionValues["values"]["0"]>[];
    commonColumns.pop();
    commonColumns.push({
      Header: t("Quantity to {{action}}", { action: startCase(action) }),
      accessor: "actionVolume",
      Cell: ({ value }: { value: number }) => <TableValueWrapper value={t(value?.toLocaleString()) || ""} />,
      disableSortBy: true,
    });
    return commonColumns;
  }, [recOptions, action, t]);

  return (
    <Box as="main" sx={{ m: 4, position: "relative" }}>
      <HeaderText title={t("{{action}} RECs Confirmation", { action: startCase(action) })} sx={{ width: 350 }} />
      {infoText && (
        <Flex
          sx={{
            fontSize: 0,
            alignItems: "center",
            wordBreak: "break-all",
          }}
        >
          <IconInfo size={5} color="primary.500" />
          {infoText}
        </Flex>
      )}
      <Box sx={{ my: 3 }}>
        <Table
          pagination={false}
          dataSource={data.values}
          columns={tableColumns}
          translation={getTableTranslation(t)}
        />
      </Box>
      {isRetireValues(data) && (
        <Box sx={{ mt: 3 }}>
          <SeparatorLayout
            showSeparator={false}
            contentStyle={{
              width: "100%",
            }}
            headingStyle={{
              color: "textDark",
            }}
            descriptionStyle={{
              color: "text",
            }}
            data={{
              "Retirement Type": getRetirementType(data.type, data.category),
              "Retirement Reason": getRetirementReason({
                reason: data.reason ?? "-",
                notes: data.notes ?? "-",
                compliancePeriod: data.compliancePeriod,
              }),
              "Retirement Notes": data.notes,
            }}
          />
        </Box>
      )}
      <Flex
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
          py: 3,
        }}
      >
        <Flex sx={{ mt: 4, gap: 3, alignItems: "center" }}>
          <Button
            sx={{
              bg: "transparent",
              borderRadius: 0,
              height: 5,
              color: "text",
              "&:enabled:hover": {
                bg: "transparent",
                borderBottom: "1px solid var(--theme-ui-colors-text)",
              },
              "&:enabled:focus": {
                bg: "transparent",
              },
              "&:disabled": {
                bg: "transparent",
              },
            }}
            disabled={isSubmitting}
            onClick={onGoBack}
          >
            {t("Edit Details")}
          </Button>
          <Button
            variant="primary"
            disabled={isSubmitting}
            onClick={async () => {
              setIsSubmitting(true);
              await onProceed(data);
              setIsSubmitting(false);
            }}
          >
            {t("Confirm {{action}} Request", { action: startCase(buttonActionText ?? action) })}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
