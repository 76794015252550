import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import { FC, lazy } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { ForgotPassword } from "@/app/pages/public";

import { PublicRoutes } from "./routes.type";

const ConfirmEmail = lazy(() => import("@/app/pages/public/confirm-email/confirm-email"));
const Contact = lazy(() => import("@/app/pages/public/contact"));
const ResetPassword = lazy(() => import("@/app/pages/public/reset-password/reset-password"));

export const PublicRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { authState } = useOktaAuth();
  if (authState?.isAuthenticated) return <Navigate to="/" />;
  return children;
};

export const publicRoutes: PublicRoutes[] = [
  {
    path: "/login/callback",
    render: () => <CustomLoginCallbackRoute />,
  },
  {
    path: "/confirm-email",
    render: () => <CustomRouteTokenCheckers Page={ConfirmEmail} />,
  },
  {
    path: "/forgot-password",
    render: () => <ForgotPassword />,
  },
  {
    path: "/reset-password",
    render: () => <CustomRouteTokenCheckers Page={ResetPassword} />,
  },
  {
    path: "/contact-us",
    render: () => <Contact />,
  },
];

export const CustomRouteTokenCheckers = ({ Page }: { Page: FC<any> }) => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  if (token) return <Page token={token} />;
  return <Navigate to="/" replace />;
};

export const CustomLoginCallbackRoute = () => {
  const { oktaAuth } = useOktaAuth();
  /** idx.canProceed() returns true only when user is actually logging in
   * Hence disabling this route if hit directly from brower url panel
   */
  if (oktaAuth.idx.canProceed()) {
    return <LoginCallback />;
  }
  return <Navigate to="/" replace />;
};
