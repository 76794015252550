import { Box, Button, Flex, IconLock, IconTick } from "@powerledger/ui-component-lib";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FactorProvider, FactorStatus, FactorType } from "@/app/types/generated/graphql";

import ResetFactor from "./reset-factor";
import SetUpFactor from "./setup-factor";

export type FactorProps = {
  factorProvider: FactorProvider;
  factorType: FactorType;
  status: FactorStatus;
  name: string;
};
export const NON_ACTIVE_STATUSES = [
  FactorStatus.PendingActivation,
  FactorStatus.Expired,
  FactorStatus.Inactive,
  FactorStatus.NotSetup,
];

export const FactorSetting: FC<FactorProps> = ({ factorProvider, factorType, status, name }) => {
  const { t } = useTranslation();

  const [showEnrollModal, setShowEnrollModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const activatedIcon = useMemo(() => {
    return NON_ACTIVE_STATUSES.includes(status) ? (
      <IconLock size={4} color="text" />
    ) : (
      <IconTick size={6} color="success.50" />
    );
  }, [status]);

  const lifeCycleActions = useMemo(
    () => [
      {
        lifecycleActionName: t("Set up"),
        setShowModal: setShowEnrollModal,
        modalVisible: showEnrollModal,
        isVisible: [
          FactorStatus.Enrolled,
          FactorStatus.PendingActivation,
          FactorStatus.Expired,
          FactorStatus.Inactive,
          FactorStatus.NotSetup,
        ].includes(status),
        modal: (
          <SetUpFactor
            name={name}
            factorProvider={factorProvider}
            factorType={factorType}
            visible={showEnrollModal}
            closeModal={() => setShowEnrollModal(false)}
          />
        ),
      },
      {
        lifecycleActionName: t("Reset"),
        setShowModal: setShowResetModal,
        modalVisible: showResetModal,
        isVisible: status === FactorStatus.Active,
        modal: (
          <ResetFactor
            factorProvider={factorProvider}
            factorType={factorType}
            visible={showResetModal}
            closeModal={() => setShowResetModal(false)}
          />
        ),
      },
    ],
    [t, showEnrollModal, name, status, factorProvider, factorType, showResetModal],
  );
  return (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        height: 7,
        bg: "shadow",
        borderRadius: 4,
      }}
    >
      <Flex sx={{ alignItems: "center", pl: 2 }}>
        {activatedIcon}
        <Box sx={{ fontSize: 1, px: 2, color: "text" }}>{name}</Box>
      </Flex>
      <Flex>
        {lifeCycleActions.map(
          ({ isVisible, setShowModal, lifecycleActionName, modal, modalVisible }) =>
            isVisible && (
              <div key={lifecycleActionName}>
                <Button
                  variant="text"
                  onClick={() => setShowModal(true)}
                  sx={{
                    textTransform: "uppercase",
                    mr: 3,
                    fontSize: 0,
                  }}
                  aria-label={t(`{{action}} MFA`, { action: lifecycleActionName })}
                >
                  {t(lifecycleActionName)}
                </Button>
                {modalVisible && modal}
              </div>
            ),
        )}
      </Flex>
    </Flex>
  );
};

export default FactorSetting;
