import { Button } from "@powerledger/ui-component-lib";
import { forwardRef } from "react";
import { useLocation } from "react-router";

const enabledStyle = {
  bg: "activeLinkBackground",
  svg: {
    path: {
      stroke: "white",
    },
  },
};

export const NavButton = forwardRef<
  HTMLButtonElement,
  {
    onClick?: () => void;
    as?: React.ElementType;
    children: React.ReactNode;
    path?: string;
  }
>(function Component({ as = "button", path, children, onClick = () => {} }, ref) {
  const location = useLocation();

  return (
    <Button
      ref={ref}
      as={as}
      sx={{
        display: "flex",
        bg: "transparent",
        p: 0,
        height: "35px",
        width: "35px",
        overflow: "hidden",
        svg: {
          path: {
            stroke: "primaryHighlight",
          },
        },
        "&:hover, &:focus, &:enabled:hover, &:enabled:focus": enabledStyle,
        position: "relative",
        alignItems: "center",
        ...(location.pathname + location.search === path ? enabledStyle : {}),
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
});
