import { Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";
import { useDemoData } from "@/app/hooks/use-demo-data";
import { useCancelOrderMutation } from "@/app/types/generated/graphql";

export const OrderCancelModal = ({
  visible,
  orderId,
  closeModal,
}: {
  visible: boolean;
  orderId: string;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const isUsingDemoData = useDemoData();
  const [cancelOrder, { loading }] = useCancelOrderMutation({ refetchQueries: ["OrderHistory"] });

  const confirm = useCallback(async () => {
    try {
      await (isUsingDemoData ? Promise.resolve() : cancelOrder({ variables: { orderId } }));
      closeModal();
      notifications.success({ description: t("Order successfully withdrawn") });
    } catch (e: unknown) {
      if (e instanceof Error) {
        notifications.error({
          description: t(e?.message),
        });
      }
    }
  }, [cancelOrder, closeModal, isUsingDemoData, orderId, t]);

  const cleanUpModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Modal
      visible={visible}
      maskClosable
      hideCloseButton
      onCancel={cleanUpModal}
      sx={{ maxHeight: "inherit" }}
      title={t("Are you sure you want to withdraw this order?")}
      subtitle={t(
        "Please Note, withdrawing a partially fulfilled order will remove its unfulfilled portion from the market.",
      )}
    >
      <Flex sx={{ justifyContent: "flex-end", mt: 4 }}>
        <Button variant="error" onClick={closeModal} sx={{ mr: 3, minWidth: 80 }} disabled={loading}>
          {t("Cancel")}
        </Button>
        <Button variant="primary" onClick={confirm} disabled={loading}>
          {t("Proceed")}
        </Button>
        {loading && <IconLoading size="small" sx={{ ml: 2 }} />}
      </Flex>
    </Modal>
  );
};

export default OrderCancelModal;
