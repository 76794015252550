import { format, parseISO } from "date-fns";
import { startCase } from "lodash";

import {
  ExportSummaryQuery,
  RecAssetAttributes,
  RetirementCategory,
  RetirementSummaryQuery,
} from "../types/generated/graphql";
import { formatAttributes } from "./format-attributes";
import { RECOrderAttributeOptions } from "./format-rec-options";
import { volumeFormatter } from "./volume-formatter";

export type FormattedCommodityActionSummary = {
  id: string;
  Vintage: string;
  Project: string;
  Eligibilities: string;
  "Fuel Type": string;
  Location: string;
  Quantity: string;
  "Serial Numbers": string;
  Certifications: string;
  "Facility Effective Date": string;
  "Facility Expiry Date": string;
  COD: string;
};

export const formatCommodityActionReport = (
  commodityActionDetails: RetirementSummaryQuery["retirementById"] | ExportSummaryQuery["exportRequestById"],
  recOptions?: RECOrderAttributeOptions,
): FormattedCommodityActionSummary[] => {
  // Helper function to determine which type of details we are dealing with
  const getDetails = (actionDetails: typeof commodityActionDetails) => {
    if ("commodityDetails" in actionDetails) {
      return actionDetails.commodityDetails;
    } else if ("exportRequestDetails" in actionDetails) {
      return actionDetails.exportRequestDetails;
    } else {
      return null;
    }
  };

  const details = getDetails(commodityActionDetails);

  if (!details) {
    return [];
  }

  return details.flatMap((detail) => {
    const attributes = detail.attributes as RecAssetAttributes;
    const formattedAttributes = formatAttributes({
      attributes,
      options: recOptions,
    });
    const commodities =
      detail.commodityTransactions?.map((transaction) => ({
        "Serial Numbers": `${transaction.commoditySerial.serialPrefix}-${transaction.commoditySerial.serialFrom} to ${transaction.commoditySerial.serialTo}`,
        "Facility Effective Date": transaction.generator.crsEffectiveDate
          ? format(parseISO(transaction.generator.crsEffectiveDate), "P")
          : "N/A",
        "Facility Expiry Date": transaction.generator.crsExpiryDate
          ? format(parseISO(transaction.generator.crsExpiryDate), "P")
          : "N/A",
      })) ?? [];
    return commodities.map((commodityInfo) => ({
      id: detail.id,
      Vintage: formattedAttributes.vintage,
      Project: formattedAttributes.project,
      Eligibilities: joinOrReturnDefault(formattedAttributes.eligibilities),
      "Fuel Type": joinOrReturnDefault(formattedAttributes.fuelSources),
      Location: formattedAttributes.location,
      Quantity: volumeFormatter(detail.volume),
      Certifications: joinOrReturnDefault(formattedAttributes.certifications),
      COD: attributes.commencementDate ?? "-",
      ...commodityInfo,
    }));
  });
};

const joinOrReturnDefault = (value: string[]) => (value?.some(Boolean) ? value.join(", ") : "-");

export const getRetirementReason = (retirementDetails: {
  reason: string;
  notes: string;
  compliancePeriod?: string | null;
}) => {
  const reason = retirementDetails.reason;
  const compliancePeriod = retirementDetails.compliancePeriod
    ? `(Compliance Period - ${retirementDetails.compliancePeriod})`
    : "";
  return `${reason} ${compliancePeriod}`.trim();
};

export const getRetirementType = (retirementType: string, retirementCategory: RetirementCategory) => {
  return `${retirementType} (${startCase(retirementCategory.toLowerCase())})`;
};
