import { FC } from "react";

import { RequiredDocumentProps } from "./required-documents.types";
import { RequiredDocumentsView } from "./required-documents.view";
import { useRequiredDocuments } from "./use-required-documents";

export const RequiredDocuments: FC<RequiredDocumentProps> = (props) => {
  const logic = useRequiredDocuments();
  return <RequiredDocumentsView {...logic} {...props} />;
};
