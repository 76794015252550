import { CountrySelect, Divider, Flex, Grid, RegionSelect, Text } from "@powerledger/ui-component-lib";
import { isEqual } from "lodash";
import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { getSelectTranslation } from "../../lib/get-translations-for-components";
import { Form, FormFieldLabel, FormInput } from "../form";
import { AddressAutoComplete } from "./address-auto-complete";
import { AddressFormFieldProps } from "./address-form-section.type";

const AddressFormFieldsComponent: FC<AddressFormFieldProps> = ({
  setFieldValue,
  setFieldTouched,
  values,
  errors,
  title = "Address",
  formType = "personal",
  disableFields,
  getInputVariant,
  getInputStyles,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex sx={{ my: 4 }}>
        <Text
          sx={{
            textTransform: "uppercase",
            fontSize: 0,
            color: "textDarker",
            mr: 4,
          }}
        >
          {t(title)}
        </Text>
        <Divider sx={{ flex: 1, color: "textDarkest" }} />
      </Flex>

      <Form.Item sx={{ justifyContent: "end" }}>
        <Flex
          sx={{
            alignItems: "flex-start",
          }}
        >
          <FormFieldLabel
            hasErrorMessage={false}
            sx={{
              ...(getInputStyles ? getInputStyles("countryCode", "select") : {}),
              flex: 1.5,
            }}
            small
            name="physicalAddress.countryCode"
            label={t("Country *")}
          />
        </Flex>
        <CountrySelect
          translation={getSelectTranslation(t)}
          disabled={formType === "company" || disableFields}
          inputValue={values?.countryCode || ""}
          onChange={({ value }) => {
            setFieldValue("physicalAddress.countryCode", value);
          }}
        />
      </Form.Item>

      <AddressAutoComplete
        country={values?.countryCode}
        receiveAddress={(address) => {
          setFieldValue("physicalAddress.state", address.region_code);
          setFieldValue("physicalAddress.city", address.address_level2);
          setFieldValue("physicalAddress.line2", address.address_line2);
          setFieldValue("physicalAddress.postalCode", address.postcode);
        }}
      >
        <Form.Item
          sx={{ justifyContent: "end" }}
          onBlur={() => {
            /** Required as address autofills adds extra name like address-search in the input field hence making touched field for [physicalAddress.line1 address-search] to be true */
            setFieldTouched("physicalAddress.line1", true, true);
          }}
        >
          <FormFieldLabel
            hasErrorMessage={false}
            small
            name="physicalAddress.line1"
            label={t("Street Name + Number *")}
          >
            <FormInput
              debounce
              hasErrorIndicator={false}
              disabled={disableFields}
              variant={getInputVariant("")}
              name="physicalAddress.line1"
              sx={{
                "&::placeholder": {
                  color: "textDarkerLighter",
                },
              }}
              placeholder={t("Start Typing Your Address...")}
            />
          </FormFieldLabel>
        </Form.Item>
      </AddressAutoComplete>
      <Grid gap={3} columns={[2, 2, 3]} sx={{ alignItems: "baseline" }}>
        <Form.Item sx={{ justifyContent: "end" }}>
          <FormFieldLabel
            sx={getInputStyles && getInputStyles("state", "select")}
            hasErrorMessage={false}
            small
            name="physicalAddress.state"
            label={t("State *")}
          >
            <RegionSelect
              translation={getSelectTranslation(t)}
              countryValue={values?.countryCode || ""}
              inputValue={values?.state || ""}
              disabled={disableFields}
              onBlur={() => {
                setFieldTouched("physicalAddress.state", true, true);
              }}
              onChange={({ value }) => setFieldValue("physicalAddress.state", value)}
            />
          </FormFieldLabel>
        </Form.Item>
        <Form.Item sx={{ justifyContent: "end" }}>
          <FormFieldLabel hasErrorMessage={false} small name="physicalAddress.city" label={t("City *")}>
            <FormInput
              debounce
              hasErrorIndicator={false}
              name="physicalAddress.city"
              disabled={disableFields}
              variant={getInputVariant("")}
            />
          </FormFieldLabel>
        </Form.Item>

        <Form.Item sx={{ justifyContent: "end" }}>
          <FormFieldLabel small name="physicalAddress.line2" label={t("Unit / Floor Details")} hasErrorMessage={false}>
            <FormInput debounce name="physicalAddress.line2" hasErrorIndicator={false} disabled={disableFields} />
          </FormFieldLabel>
        </Form.Item>
        <Form.Item sx={{ justifyContent: "end" }}>
          <FormFieldLabel
            hasErrorMessage={errors?.postalCode !== "Required"}
            small
            name="physicalAddress.postalCode"
            label={t("Postcode *")}
          >
            <FormInput
              debounce
              hasErrorIndicator={false}
              name="physicalAddress.postalCode"
              disabled={disableFields}
              variant={getInputVariant("")}
            />
          </FormFieldLabel>
        </Form.Item>
      </Grid>
    </>
  );
};

export const AddressFormFields = memo(AddressFormFieldsComponent, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
