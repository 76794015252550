import { Box, Flex } from "theme-ui";

import { RefreshIconButton } from "@/app/components/refresh-icon-button";

import { ExportHistoryViewProps } from "./export-history.types";
import ExportHistoryHeader from "./export-history-header/export-history-header";
import ExportHistoryTable from "./export-history-table/export-history-table";

export const ExportHistoryView: React.FC<ExportHistoryViewProps> = ({
  applyFilters,
  data,
  fetchData,
  pageInfo,
  loading,
  refreshData,
  onSeeDetails,
}) => {
  return (
    <Box as="main" sx={{ m: 4 }}>
      <ExportHistoryHeader applyFilters={applyFilters} />
      <Flex sx={{ alignItems: "center", justifyContent: "flex-end", mb: 2 }}>
        <RefreshIconButton loading={loading} fetchData={refreshData} />
      </Flex>
      <ExportHistoryTable
        loading={loading}
        fetchData={fetchData}
        pageInfo={pageInfo}
        data={data}
        onSeeDetails={onSeeDetails}
      />
    </Box>
  );
};
