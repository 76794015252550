import { pick } from "lodash";

import { demoAccount } from "../../app/container/apollo-client/demo-data";
import { AccountLinkedRegistriesDocument } from "../../app/types/generated/graphql";

export const mockAccountLinkedRegistries = {
  request: {
    query: AccountLinkedRegistriesDocument,
  },
  result: {
    data: {
      account: pick(demoAccount, ["linkedRegistries"]),
    },
  },
};
