import { startCase } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { defaultFilter, handleFilterChange, PageHeader } from "@/app/components/page-header";
import { FiltersType, StackedFilterState } from "@/app/components/page-header/filter/filter.types";
import { RetirementStatus } from "@/app/types/generated/graphql";

import DateRangeFilter from "../../common/date-range-filter/date-range-filter";
import { RetirementHistoryHeaderProps } from "../retirement-history.types";

export const retirementStatusOptions = Object.values(RetirementStatus).map((status) => ({
  label: startCase(status.toLowerCase()),
  value: status,
}));

export default function RetirementHistoryHeader({ applyFilters, isMenuOpen }: Readonly<RetirementHistoryHeaderProps>) {
  const [filterValues, setFilterValues] = useState<StackedFilterState>(defaultFilter);
  const { t } = useTranslation();
  const renderDateRangeFilter = useCallback(
    () => <DateRangeFilter filterValues={filterValues} setFilterValues={setFilterValues} />,
    [filterValues],
  );
  const filters: FiltersType = useMemo(
    () => [
      {
        name: "Requested Date (Range)",
        keys: ["dateRange"],
        onChange: () => {},
        options: [],
        disabled: false,
        values: [],
        isMulti: true,
        render: renderDateRangeFilter,
        isMenuOpen,
        wrapperStyles: {
          menuStyles: {
            height: "320px",
            top: "45px",
          },
        },
      },
      {
        name: "Status",
        keys: ["statuses"],
        onChange: (options) => {
          handleFilterChange({ statuses: options.map((opt) => opt.value) }, setFilterValues);
        },
        options: retirementStatusOptions,
        disabled: false,
        values: filterValues.statuses?.map((val) => val.value),
        components: {
          GroupHeading: () => null,
        },
        isMulti: true,
        isMenuOpen,
        wrapperStyles: {
          menuStyles: {
            height: "300px",
            top: "45px",
          },
        },
      },
    ],
    [filterValues, isMenuOpen, renderDateRangeFilter],
  );

  return (
    <PageHeader
      title={t("Retirement History")}
      filterProps={{
        applyFilters,
        shouldSelectVintage: false,
        hideStatusFilter: true,
        filters,
        values: filterValues,
        customSetter: setFilterValues,
      }}
      styles={{
        headerSx: { width: 500 },
      }}
      actions={{
        sellAction: {
          hide: true,
        },
        retireAction: {
          hide: true,
        },
      }}
    />
  );
}
