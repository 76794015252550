import { MutationFunction } from "@apollo/client";

import { convertFalsyToDefaultValue } from "@/app/lib/convert-falsy-values-to-default-value";
import {
  AddCompanyContactMutation,
  AddCompanyContactMutationVariables,
  UpdateCompanyContactMutation,
  UpdateCompanyContactMutationVariables,
} from "@/app/types/generated/graphql";

import { CompanyContactFormInput } from "../../public/signup/onboarding/onboarding.types";

export const saveCompanyContact = async (
  values: CompanyContactFormInput,
  addMutation: MutationFunction<AddCompanyContactMutation, AddCompanyContactMutationVariables>,
  updateMutation: MutationFunction<UpdateCompanyContactMutation, UpdateCompanyContactMutationVariables>,
) => {
  const { isUSCitizen, approvalStatus, ...companyContactInput } = values;
  if (companyContactInput.id) {
    await updateMutation({
      variables: {
        companyContact: convertFalsyToDefaultValue(companyContactInput),
      },
    });
  } else {
    await addMutation({
      variables: {
        companyContact: convertFalsyToDefaultValue(companyContactInput),
      },
    });
  }
};
