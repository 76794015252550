import { Chip, Tooltip } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { Flex } from "theme-ui";

import { ExportRequestStatus, RetirementStatus } from "@/app/types/generated/graphql";

const chipVariant = {
  [RetirementStatus.Retired]: "chips.primary",
  [RetirementStatus.Failed]: "chips.error",
  [RetirementStatus.Pending]: "chips.info",
  [RetirementStatus.Requested]: "chips.info",
  [RetirementStatus.Rejected]: "chips.error",
  [ExportRequestStatus.Success]: "chips.primary",
  [ExportRequestStatus.Exporting]: "chips.info",
  [ExportRequestStatus.PartialSuccess]: "chips.info",
} as const;

export const ActionStatusChip = ({
  status,
  statusMessage,
  id,
}: {
  id?: string;
  statusMessage?: string | null;
  status: RetirementStatus | ExportRequestStatus;
}) => {
  const { t } = useTranslation();
  return (
    <Flex>
      <Chip
        sx={{
          variant: chipVariant[status],
        }}
        label={t(startCase(status))}
      />
      {statusMessage && <Tooltip id={"action-status-message" + id} content={<>{t(statusMessage)}</>} />}
    </Flex>
  );
};
