import { differenceInYears, parse } from "date-fns";
import * as Yup from "yup";

import { approvalStatusSchema } from "./approvalStatus";
import { physicalAddressSchema } from "./physical-address";

/**
 * Validation Schema for Account Details
 */
export const personalSchema = Yup.object()
  .shape({
    firstName: Yup.string().nullable().hasMaxLengthAndNoSpecialChars().required("Required"),
    lastName: Yup.string().nullable().hasMaxLengthAndNoSpecialChars().required("Required"),
    jobTitle: Yup.string().nullable().hasMaxLengthAndNoSpecialChars(255).required("Required"),
    dateOfBirth: Yup.string()
      .nullable()
      .required("Must be a valid date")
      .test("too-young-check", "Must be at least 18 years old", (value) => {
        let ageInYears = 0;
        if (value) ageInYears = differenceInYears(new Date(), parse(value, "yyyy-MM-dd", new Date()));
        return ageInYears >= 18;
      })
      .test("too-old-check", "Must be at most 124 years old", (value) => {
        let ageInYears = 0;
        if (value) ageInYears = differenceInYears(new Date(), parse(value, "yyyy-MM-dd", new Date()));
        return ageInYears <= 124;
      }),
    isUSCitizen: Yup.boolean().nullable(),
    identityNumber: Yup.string().when("isUSCitizen", {
      is: (isUSCitizen: boolean) => !!isUSCitizen,
      then: Yup.string().nullable().required("Required"),
      otherwise: Yup.string().nullable(),
    }),
    passport: Yup.object().when("isUSCitizen", {
      is: (isUSCitizen: boolean) => !isUSCitizen,
      then: Yup.object().shape({
        number: Yup.string().nullable().required("Required"),
        countryCode: Yup.string().nullable().required("Required"),
      }),
      otherwise: Yup.object().shape({
        number: Yup.string().nullable(),
        countryCode: Yup.string().nullable(),
      }),
    }),
    approvalStatus: approvalStatusSchema,
  })
  .concat(physicalAddressSchema);
