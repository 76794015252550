import ReactPDF from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import React from "react";

import i18n from "@/app/lib/i18n";

import notifications from "../../container/notifications";
import DocumentProps = ReactPDF.DocumentProps;

export const DATE_TIME_FORMAT = "p P";

export const downloadReactPDFDocument = async (filename: string, document: React.ReactElement<DocumentProps>) => {
  const blob = await ReactPDF.pdf(document).toBlob();
  return saveAs(blob, filename);
};

export const handleError = (error: any) => {
  notifications.error({
    description: i18n.t("Something went wrong when downloading the file."),
  });
};
