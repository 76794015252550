import { pick } from "lodash";

import { demoAccount } from "../../app/container/apollo-client/demo-data";
import { AccountPaymentDetailsDocument } from "../../app/types/generated/graphql";

export const mockAccountPaymentDetails = {
  request: {
    query: AccountPaymentDetailsDocument,
  },
  result: {
    data: {
      account: {
        id: demoAccount.id,
        tenantId: demoAccount.tenantId,
        status: demoAccount.status,
        ...pick(demoAccount, ["linkedPaymentMethods"]),
      },
    },
  },
};
