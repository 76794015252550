import { ComponentType, lazy, LazyExoticComponent } from "react";

const PrivacyPolicyTermsEn = lazy(() => import("./privacy-policy.us.mdx"));
const TermsOfAccessEn = lazy(() => import("./terms-of-access.us.mdx"));
const TraceXRulesEn = lazy(() => import("./tracex-rules.us.mdx"));

type DocumentRef = Record<string, LazyExoticComponent<ComponentType<any>>>;
type LanguageRef = Record<string, DocumentRef>;

export const hostDocs: LanguageRef = {
  en: {
    "US.privacyPolicy": PrivacyPolicyTermsEn,
    "US.termsOfAccess": TermsOfAccessEn,
    "US.traceXRules": TraceXRulesEn,
  },

  default: {
    "default.privacyPolicy": PrivacyPolicyTermsEn,
    "default.termsOfAccess": TermsOfAccessEn,
    "default.traceXRules": TraceXRulesEn,
  },
};
