import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, useOktaAuth } from "@okta/okta-react";
import { FC, lazy, Suspense, useCallback } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { AttributesTooltip, Layout, LoadingOverlayIndicator, TermsAndConditionsModal } from "../components";
import notifications from "../container/notifications";
import { isOnboardingOrRetryUser } from "../lib/account-service-helpers";
import { Home } from "../pages/public/home";
import { useFewAccountDetailsQuery } from "../types/generated/graphql";
import { flattenPrivateRoutes, PrivateRoute } from "./routes/private-routes";
import { PublicRoute, publicRoutes } from "./routes/public-routes";
const Onboarding = lazy(() => import("../pages/public/signup/onboarding/onboarding"));

interface AppRouterProps {
  oktaAuth: OktaAuth;
}

export const AppRouter: FC<AppRouterProps> = ({ oktaAuth }) => {
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(
    async (_, originalUri: string | null) => {
      /** Global Error Boundary shows logout if user is logged in */
      /** Event notifies Global Error Boundary that user is logged in  */
      /** Otherwise localstorage update is not caught by component */
      window.dispatchEvent(new Event("storage"));
      navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
        replace: true,
      });
    },
    [navigate],
  );

  const onAuthRequired = useCallback(() => {
    navigate("/", { replace: true });
  }, [navigate]);

  return (
    <Suspense fallback={<LoadingOverlayIndicator />}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
        <Routes>
          <Route path="/" element={<RootRouteRender />} />
          {publicRoutes.map((route) => (
            <Route path={route.path} key={route.path} element={<PublicRoute>{route.render()}</PublicRoute>} />
          ))}
          {flattenPrivateRoutes.map((route) => (
            <Route
              path={route.path}
              key={route.path}
              element={
                <PrivateRoute guard={route.guard}>
                  <Layout>
                    {/* Fallback to match layout to avoid flickering */}
                    <Suspense fallback={<Layout></Layout>}>{route.render()}</Suspense>
                    <AttributesTooltip />
                  </Layout>
                </PrivateRoute>
              }
            />
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Security>
    </Suspense>
  );
};
export const RootRouteRender = () => {
  const { authState } = useOktaAuth();

  const { data, loading, error } = useFewAccountDetailsQuery({
    skip: !authState?.isAuthenticated,
    fetchPolicy: "no-cache",
    onError: (error) => {
      error instanceof Error &&
        notifications.error({
          description: error?.message,
        });
    },
  });

  if (error) {
    throw error;
  }

  //If not authenticated show Login Page
  if (!authState?.isAuthenticated) return <Home />;

  //If authenticated => query is hit and loading is true, Show loading Overlay
  if (loading) return <LoadingOverlayIndicator />;

  //If account status is onboarding or retry, go To Signup
  if (isOnboardingOrRetryUser(data?.account?.status))
    return (
      <Suspense fallback={<LoadingOverlayIndicator />}>
        <Onboarding />
      </Suspense>
    );

  // If User has not accepted the terms and conditions
  if (
    !data?.account?.termsAcceptedDate ||
    !data?.account?.rulesAcceptedDate ||
    !data?.account?.privacyPolicyAcceptedDate
  ) {
    return <TermsAndConditionsModal />;
  }

  //Else Navigate to Market
  return <Navigate to="/market" replace />;
};
