import { AuthnTransaction } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { FormikHelpers, FormikProps, FormikValues } from "formik";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ModalContext } from "../../../../modal-provider";
import { HomePages } from "../home.types";
import { LoginFormValues, MFAModalOpts, UseLoginFn } from "./login.types";

export const useLogin: UseLoginFn = ({ page }) => {
  const { oktaAuth } = useOktaAuth();
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<LoginFormValues>>(null);
  const navigate = useNavigate();
  const { showContactSupportModal } = useContext(ModalContext);

  useEffect(() => {
    if (page !== HomePages.login) {
      formRef.current?.resetForm();
    }
  }, [page]);

  const [mfaModal, setMFAModal] = useState<MFAModalOpts>({
    visible: false,
    transaction: undefined,
  });

  const handleLogin = useCallback(
    async ({ username, password }: FormikValues, { setStatus }: FormikHelpers<LoginFormValues>) => {
      try {
        const loginTransaction: AuthnTransaction = await oktaAuth.signInWithCredentials({
          username,
          password,
        });

        if (loginTransaction.status === "MFA_REQUIRED") {
          setMFAModal({
            visible: true,
            transaction: loginTransaction,
          });
        } else if (loginTransaction.status === "SUCCESS") {
          ReactGA.set({ userId: loginTransaction?.user?.id ?? undefined });
          await oktaAuth.token.getWithRedirect({
            sessionToken: loginTransaction.sessionToken,
          });
        } else {
          throw new Error(`Unknown transaction state hit: ${loginTransaction.status}`);
        }
      } catch (error: any) {
        if (error?.xhr?.status !== 401) showContactSupportModal();
        else setStatus("Sorry, we don't recognise this username & password combination.");
      }
    },
    [oktaAuth, showContactSupportModal],
  );

  const goToPage = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    [navigate],
  );

  return {
    goToPage,
    handleLogin,
    t,
    mfaModal,
    setMFAModal,
    formRef,
  };
};
