import { get } from "lodash";

import { demoAccount, demoRegistries } from "../../app/container/apollo-client/demo-data";
import { RegistriesDocument } from "../../app/types/generated/graphql";

export const mockRegistries = {
  request: {
    query: RegistriesDocument,
    variables: {
      where: {
        idIn: [get(demoAccount.linkedRegistries, "0.id")],
      },
    },
  },
  result: {
    data: {
      registries: demoRegistries,
    },
  },
};
