import { Text, TextProps } from "@powerledger/ui-component-lib";
import { ErrorMessage as ErrorMessageBase, ErrorMessageProps as ErrorMessageBaseProps } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type ErrorMessageProps = ErrorMessageBaseProps & TextProps;

export const ErrorMessage: FC<ErrorMessageProps> = ({ children, sx, ...props }) => {
  const { t } = useTranslation();
  return (
    <ErrorMessageBase {...props}>
      {(msg) => <Text sx={{ variant: "forms.errorMessage", ...sx }}>{t(msg)}</Text>}
    </ErrorMessageBase>
  );
};

export default ErrorMessage;
