import { ApolloError } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";
import { useAccountTenant } from "@/app/hooks";
import { getSupportEmail } from "@/app/lib/get-support-email";
import {
  LinkPaymentMethodMutationFn,
  PaymentMethod,
  PaymentMethodLinkStatus,
  useLinkPaymentMethodMutation,
} from "@/app/types/generated/graphql";

import { SelectPaymentMethodError } from "./error";
import { SelectPaymentMethodProps } from "./select-payment-method.types";

export const handleLinkPaymentMethod = async (
  linkPaymentMethod: LinkPaymentMethodMutationFn,
  onLinkPaymentMethod: () => void,
  acceptedLegalAgreement: boolean,
  selectedPaymentMethod?: PaymentMethod,
) => {
  if (!selectedPaymentMethod) {
    throw new Error("No payment method selected");
  }

  if (!acceptedLegalAgreement) {
    throw new Error("Payment method legal agreement not accepted");
  }

  const result = await linkPaymentMethod({
    variables: {
      input: {
        paymentMethodCode: selectedPaymentMethod.code,
      },
    },
  });

  if (
    (result.data && result.data.linkPaymentMethod?.linkedPaymentMethod?.status === PaymentMethodLinkStatus.Error) ||
    (result.errors && result.errors.length > 0)
  ) {
    throw new Error("An error occurred when trying to link payment method");
  }

  if (result.data?.linkPaymentMethod?.linkedPaymentMethod && onLinkPaymentMethod) {
    onLinkPaymentMethod();
  }
};

export const useSelectPaymentMethod = (onSelectPaymentMethod: () => void): SelectPaymentMethodProps => {
  const { t } = useTranslation();
  const supportEmail = getSupportEmail();

  const [error, setError] = useState<JSX.Element | undefined>();
  const [acceptedLegalAgreement, setAcceptedLegalAgreement] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | undefined>();

  const [linkPaymentMethod, { loading: loadingLinkPaymentMethod }] = useLinkPaymentMethodMutation();

  const { data: accountTenantData, loading: loadingPaymentMethods } = useAccountTenant();
  const paymentMethods = useMemo(
    () => accountTenantData?.paymentProviders?.flatMap((provider) => provider.paymentMethods),
    [accountTenantData],
  );

  const onNextButtonClick = useCallback(async () => {
    try {
      setError(undefined);
      await handleLinkPaymentMethod(
        linkPaymentMethod,
        onSelectPaymentMethod,
        acceptedLegalAgreement,
        selectedPaymentMethod,
      );
    } catch (error) {
      if (error instanceof Error || error instanceof ApolloError) {
        setError(<SelectPaymentMethodError />);
      }
      notifications.error({
        description: t("Something went wrong, please contact support at {{supportEmail}} for assistance", {
          supportEmail,
        }),
      });
    }
  }, [supportEmail, t, acceptedLegalAgreement, linkPaymentMethod, selectedPaymentMethod, onSelectPaymentMethod]);

  return {
    loadingLinkPaymentMethod,
    loadingPaymentMethods,
    acceptedLegalAgreement,
    setAcceptedLegalAgreement,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    disableNextButton: !selectedPaymentMethod || !acceptedLegalAgreement,
    onNextButtonClick,
    paymentMethods,
    error,
  };
};
