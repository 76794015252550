import { SelectTranslation, TableTranslation } from "@powerledger/ui-component-lib";
import { merge } from "lodash";

export const getTableTranslation = (t: any, translationValue?: Partial<TableTranslation>) =>
  merge(
    {
      item: t("Item"),
      items: t("Items"),
      noDataText: t("No data"),
      pagination: {
        firstPage: "",
        previousPage: "",
        nextPage: "",
        lastPage: "",
      },
      translationFn: t,
    },
    translationValue,
  );

export const getSelectTranslation = (t: any, translationValue?: Partial<SelectTranslation>) =>
  merge(
    {
      allSelected: t("All Selected"),
      loading: t("Loading..."),
      translate: t,
    },
    translationValue,
  );

export const getScrollableTranslation = (t: any) => ({
  scrollLeft: t("Scroll Left"),
  scrollRight: t("Scroll Right"),
});
