import { Box, Flex, Heading, ThemeUIStyleObject } from "theme-ui";

import { HelpSVG } from "@/assets/icons";

export const HeaderText = ({
  title,
  tooltipText,
  sx,
}: {
  sx?: ThemeUIStyleObject;
  title: string;
  tooltipText?: string;
}) => (
  <Flex
    as="header"
    sx={{
      justifyContent: "space-between",
      width: 150,
      ...sx,
    }}
  >
    <Heading sx={{ variant: "texts.tracexDefaultHeading" }}>
      {title}
      {tooltipText && (
        <Box
          sx={{
            display: "inline",
            cursor: "pointer",
            ml: 2,
          }}
          data-tip={tooltipText}
        >
          <HelpSVG />
        </Box>
      )}
    </Heading>
  </Flex>
);
