import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { useCallback, useContext, useMemo, useState } from "react";

import { defaultFilter } from "@/app/components/page-header";
import { StackedFilterState } from "@/app/components/page-header/filter/filter.types";
import { ModalContext } from "@/app/modal-provider";
import {
  RegistryServiceSortOrderInput,
  RetirementStatus,
  useRetirementHistoryQuery,
} from "@/app/types/generated/graphql";

import { RetirementHistoryTableData, UseRetirementHistoryFn } from "./retirement-history.types";

export const useRetirementHistory: UseRetirementHistoryFn = () => {
  const [filterValues, setFilterValues] = useState<StackedFilterState>(defaultFilter);

  const { showRetirementRequestDetailsModal } = useContext(ModalContext);

  const { pageInfo, fetchData, resetPage, setTotalItems, offset, sort } = useTablePaginationManager<
    RetirementHistoryTableData,
    RegistryServiceSortOrderInput[]
  >({});

  const {
    data: newData,
    loading,
    previousData,
    refetch,
  } = useRetirementHistoryQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          statuses: filterValues.statuses.map((status) => status.value) as RetirementStatus[],
          startDateRange:
            filterValues.dateRange.length == 2
              ? {
                  fromDate: filterValues.dateRange[0].value,
                  toDate: filterValues.dateRange[1].value,
                }
              : null,
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data?.retirementHistory?.offsetInfo?.total ?? 0);
    },
  });

  const applyFilters = useCallback(
    (arg) => {
      setFilterValues(arg);
      resetPage();
    },
    [resetPage],
  );

  const refreshData = useCallback(() => {
    refetch();
  }, [refetch]);

  const data = useMemo(() => (newData ?? previousData)?.retirementHistory?.retirements ?? [], [newData, previousData]);

  const onSeeDetails = useCallback(
    (id: string) => {
      showRetirementRequestDetailsModal({
        id,
      });
    },
    [showRetirementRequestDetailsModal],
  );

  return { applyFilters, loading, refreshData, data, pageInfo, onSeeDetails, fetchData };
};
