import * as Sentry from "@sentry/react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlaidLinkOnExit } from "react-plaid-link";

import { notifications } from "../../container/notifications";
import { getSupportEmail, SupportEmailTypes } from "../../lib/get-support-email";
import {
  AddFundingSourceMutationVariables,
  PaymentMethodCode,
  useAddFundingSourceMutation,
} from "../../types/generated/graphql";

const buildAddFundingSourceErrorArguments = (
  errorCode: string | null,
  errorMessage: string | null,
): AddFundingSourceMutationVariables => ({
  addFundingSourceInput: {
    paymentMethodCode: PaymentMethodCode.DwollaPlaid,
    token: "",
    identifier: "",
    errorCode: errorCode,
    errorMessage: errorMessage,
  },
});

export const usePlaidLinkOnExitHandler = () => {
  const { t } = useTranslation();
  const supportEmail = getSupportEmail(SupportEmailTypes.SUPPORT_EMAIL_INTERNAL_KEY);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [addFundingSource] = useAddFundingSourceMutation();

  const onExit = useCallback<PlaidLinkOnExit>(
    async (error, metadata) => {
      if (error) {
        Sentry.withScope((scope) => {
          scope.setExtras({
            metadata,
          });
          Sentry.captureException(error);
        });
        const addFundingSourceArgs = buildAddFundingSourceErrorArguments(error.error_code, error.error_message);
        try {
          setLoading(true);
          await addFundingSource({ variables: addFundingSourceArgs });
        } finally {
          notifications.error({
            description: t(`Unexpected error occurred - please contact {{supportEmail}} for support`, { supportEmail }),
          });
          setDisable(true);
          setLoading(false);
        }
      }
    },
    [addFundingSource, supportEmail, t],
  );

  return {
    disable,
    loading,
    onExit,
  };
};
