import { postcodeValidator, postcodeValidatorExistsForCountry } from "postcode-validator";
import * as Yup from "yup";

export const physicalAddressSchema = Yup.object().shape({
  physicalAddress: Yup.object().shape({
    countryCode: Yup.string().nullable().required("Required"),
    state: Yup.string().nullable().required("Required"),
    city: Yup.string().nullable().required("Required"),
    line1: Yup.string().nullable().required("Required"),
    line2: Yup.string().nullable(),
    postalCode: Yup.string()
      .nullable()
      .required("Required")
      .test("validPostCode", `Invalid Postcode.`, function (value, { parent }) {
        return postcodeValidatorExistsForCountry(parent.countryCode ?? "")
          ? postcodeValidator(value ?? "", parent.countryCode)
          : true;
      }),
  }),
});
