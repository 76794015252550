import { CompanyContactFormInput } from "@/app/pages/public/signup/onboarding/onboarding.types";
import { ApprovalStatus, CompanyContact, ContactType } from "@/app/types/generated/graphql";

/**
 * Converts contacts object into CompanyContactInput type for validation checking and populating values in form
 */

export const convertToCompanyContactInput: (
  contactType: ContactType,
  companyContact?: CompanyContact | null,
) => CompanyContactFormInput = (contactType, companyContact) => ({
  id: companyContact?.id || "",
  firstName: companyContact?.firstName || "",
  lastName: companyContact?.lastName || "",
  jobTitle: companyContact?.jobTitle || "",
  dateOfBirth: companyContact?.dateOfBirth || "",
  identityNumber: companyContact?.identityNumber || "",
  isUSCitizen: companyContact?.identityNumber ? true : companyContact?.passportNumber ? false : true,
  physicalAddress: {
    line1: companyContact?.physicalAddress?.line1 || "",
    line2: companyContact?.physicalAddress?.line2 || "",
    city: companyContact?.physicalAddress?.city || "",
    state: companyContact?.physicalAddress?.state || "",
    postalCode: companyContact?.physicalAddress?.postalCode || "",
    countryCode: companyContact?.physicalAddress?.countryCode || "US",
  },
  passport: {
    number: companyContact?.passportNumber || "",
    countryCode: companyContact?.passportCountryCode || "",
  },
  type: contactType,
  approvalStatus: companyContact?.approvalState?.status ?? ApprovalStatus.NotRequested,
});
