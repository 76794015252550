import { Box, Flex, Text } from "@powerledger/ui-component-lib";
import React from "react";
import { useTranslation } from "react-i18next";

import { useGetLocationTexts } from "@/app/hooks/use-get-locations-text/use-get-location-texts";

import { MaxContentLimitViewProps, TableValueWrapperProps } from "./table-value-wrapper.types";

const MaxContentLimitView: React.FC<MaxContentLimitViewProps> = ({
  title,
  array,
  maxContent = 1,
  getIcon,
  getToolTipText,
  csv,
}) => {
  const { t } = useTranslation();

  const showValue = array.slice(0, maxContent).map((val) => {
    const IconComponent = getIcon?.(val.toString());
    const tooltip = t(getToolTipText?.(val.toString()) || val.toString() || "");
    return IconComponent ? (
      <IconComponent
        key={val}
        size={5}
        color="textDark"
        data-tip-disable={!tooltip}
        data-tip={t(tooltip)}
        data-for={title}
      />
    ) : (
      t(val.toString())
    );
  });
  const remainingValue = array.slice(maxContent).map((val) => {
    const tooltip = t(getToolTipText?.(val.toString()) || val.toString() || "");
    return tooltip || t(val.toString());
  });
  const delimiter = csv ? ", " : " ";
  return (
    <Flex
      sx={{
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {getIcon ? showValue : showValue.join(delimiter)}
      {Boolean(remainingValue.length) && (
        <Text sx={{ ml: 1 }} data-for={title || "eligibilities"} data-tip={remainingValue}>
          {t(" + {{remainingLength}} more", { remainingLength: remainingValue.length })}
        </Text>
      )}
    </Flex>
  );
};

export const TableValueWrapper: React.FC<TableValueWrapperProps> = ({
  title = "",
  value,
  csv = false,
  maxContent = 1,
  getIcon,
  getToolTipText,
  containerSx,
}) => {
  const { getLocationTextsWithCountry } = useGetLocationTexts();
  if (title === "Locations") {
    value = getLocationTextsWithCountry(value as string[]);
  }
  const { t } = useTranslation();
  if (Array.isArray(value)) {
    return (
      <Box sx={{ color: "textDark", lineHeight: 2, ...containerSx }}>
        <MaxContentLimitView
          array={value}
          title={title}
          maxContent={maxContent}
          getIcon={getIcon}
          csv={csv}
          getToolTipText={getToolTipText}
        />
      </Box>
    );
  } else {
    return <Box sx={{ color: "textDark", lineHeight: 2, ...containerSx }}>{t(value?.toLocaleString())} </Box>;
  }
};
