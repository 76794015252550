import { Flex, IconInfo, Table, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { sumBy } from "lodash";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import { getCommonRecTableData } from "../../../common/rec-table-column";
import { RecHoldingsTableData } from "../../rec-holdings.types";
import { RecHoldingsTableProps } from "./rec-holdings-table.types";

export const RecHoldingsTable: FC<RecHoldingsTableProps> = ({
  data = [],
  loading = false,
  setSelected,
  recOptions,
  refetchHoldings,
  fetchData,
  pageInfo,
}) => {
  const { t } = useTranslation();

  const tableColumns: Column<RecHoldingsTableData>[] = useMemo(
    () => getCommonRecTableData(t, recOptions),
    [recOptions, t],
  );

  const totalRecs = useMemo(() => sumBy(data, "currentBalance"), [data]);

  const showTransferReminder = useMemo(() => data?.some((h) => h.availableBalance < h.currentBalance), [data]);

  return (
    <>
      <Flex sx={{ alignItems: "center", mb: 2 }}>
        <Flex sx={{ fontWeight: 700, fontSize: 2, gap: 2, color: "textDark", mt: 2 }}>
          <Text>{t("Total RECS")}</Text>
          <Text>
            {currency(totalRecs, {
              separator: ",",
              precision: 0,
              symbol: "",
            }).format()}
          </Text>
        </Flex>
        <Flex sx={{ ml: "auto" }}>
          <RefreshIconButton loading={loading} fetchData={refetchHoldings} />
        </Flex>
      </Flex>
      {showTransferReminder && (
        <Flex
          sx={{
            background: "lightActive",
            alignItems: "center",
            borderWidth: "0.5px",
            borderStyle: "solid",
            borderColor: "shadow",
            p: 1,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            position: "relative",
            zIndex: 1,
            mb: "-7px",
            borderBottomWidth: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <IconInfo size={5} color="success.800" />
          <Text sx={{ fontSize: 0, color: "textDark" }}>
            {t(
              "Any RECs that you purchase on TraceX will be in your MRETS personal account. You will have to transfer them to the TraceX For Sale account to import them.",
            )}
          </Text>
        </Flex>
      )}
      <Table
        disableCheckbox={(rows) => !rows.length || rows.some((row) => row.original.availableBalance <= 0)}
        columns={tableColumns}
        noHover
        dataSource={data}
        loading={loading}
        isMultiSelect
        selectorPosition="left"
        fetchData={fetchData}
        pageInfo={pageInfo}
        manualPagination
        showScrollArrows
        getSelected={(rows) => setSelected(rows as string[])}
        translation={getTableTranslation(t)}
      />
    </>
  );
};
