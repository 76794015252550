import { Box, CountrySelect, Flex, Grid, LegacyCheckbox } from "@powerledger/ui-component-lib";
import { subYears } from "date-fns";
import { Field, FormikErrors } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AddressFormFields } from "@/app/components/address-form-section";
import { LocalDatePicker } from "@/app/components/date-picker";
import { Form, FormFieldLabel, FormInput } from "@/app/components/form";
import notifications from "@/app/container/notifications";
import { getDateBasedOnOffset } from "@/app/lib/format-date";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";
import { AddressInput } from "@/app/types/generated/graphql";

import { SSNInput, validateFullSSN } from "../../pages/settings/company-details-tab/ssn-input";
import { PersonalInformationFormViewProps } from "./personal-information-form.types";

export const PersonalInformationFormView: React.FC<PersonalInformationFormViewProps> = ({
  formId,
  handleSubmit,
  setFieldTouched,
  setFieldValue,
  values,
  errors,
  fieldsDisabled,
  getInputStyles,
  getInputVariant,
  getRequiredFieldsMessage,
}) => {
  const { t } = useTranslation();

  const identificationFields = useMemo(() => {
    if (values.isUSCitizen)
      return (
        <Grid gap={3} columns={[2, null, 3]}>
          <Form.Item key="personal-informatioin-form-identityNumber-input" sx={{ justifyContent: "end" }}>
            <FormFieldLabel
              hasErrorMessage={errors.identityNumber !== "Required"}
              small
              name="identityNumber"
              label={t(`Social Security Number *`)}
              wrapLabel
            >
              <Field
                name="identityNumber"
                validate={(value: string) => validateFullSSN(value)}
                component={SSNInput}
                variant={getInputVariant("identityNumber")}
                disabled={fieldsDisabled}
              />
            </FormFieldLabel>
          </Form.Item>
        </Grid>
      );

    return (
      <Grid gap={3} columns={[2, null, 3]} sx={{ alignItems: "baseline" }}>
        <Form.Item key="personal-informatioin-form-passportNumber-input" sx={{ justifyContent: "end" }}>
          <FormFieldLabel hasErrorMessage={false} small name="passport.number" label={t("Passport Number *")}>
            <FormInput
              debounce
              hasErrorIndicator={false}
              name="passport.number"
              onChange={(event) => {
                if (values.identityNumber) setFieldValue("identityNumber", null);
                setFieldValue("passport.number", event.target.value);
              }}
              disabled={fieldsDisabled}
            />
          </FormFieldLabel>
        </Form.Item>
        <Form.Item key="personal-informatioin-form-passportCountryCode-input" sx={{ justifyContent: "end" }}>
          <FormFieldLabel
            hasErrorMessage={false}
            sx={getInputStyles("passport.countryCode", "select")}
            small
            name="passport.countryCode"
            label={t("Passport Country *")}
          >
            <CountrySelect
              translation={getSelectTranslation(t)}
              inputValue={values.passport?.countryCode}
              disabled={fieldsDisabled}
              onBlur={() => setFieldTouched("passport.countryCode", true, true)}
              onChange={({ value }) => {
                if (values.identityNumber) setFieldValue("identityNumber", null);
                setFieldValue("passport.countryCode", value);
              }}
            />
          </FormFieldLabel>
        </Form.Item>
      </Grid>
    );
  }, [
    values.isUSCitizen,
    setFieldValue,
    t,
    errors,
    setFieldTouched,
    values.identityNumber,
    values.passport?.countryCode,
    getInputStyles,
    getInputVariant,
    fieldsDisabled,
  ]);

  return (
    <Form id={formId} onSubmit={handleSubmit} aria-label="form">
      <Grid gap={3} columns={[2, null, 3]} sx={{ alignItems: "baseline" }}>
        <Form.Item sx={{ justifyContent: "end" }}>
          <FormFieldLabel
            hasErrorMessage={errors?.firstName !== "Required"}
            small
            name="firstName"
            label={t("First Name *")}
          >
            <FormInput debounce hasErrorIndicator={false} name="firstName" disabled={fieldsDisabled} />
          </FormFieldLabel>
        </Form.Item>
        <Form.Item sx={{ justifyContent: "end" }}>
          <FormFieldLabel
            hasErrorMessage={errors?.lastName !== "Required"}
            small
            name="lastName"
            label={t("Last Name *")}
          >
            <FormInput debounce hasErrorIndicator={false} name="lastName" disabled={fieldsDisabled} />
          </FormFieldLabel>
        </Form.Item>
        <Form.Item sx={{ justifyContent: "end" }}>
          <FormFieldLabel
            hasErrorMessage={errors["jobTitle"] !== "Required"}
            small
            name="jobTitle"
            label={t("Job Title *")}
          >
            <FormInput debounce hasErrorIndicator={false} name="jobTitle" disabled={fieldsDisabled} />
          </FormFieldLabel>
        </Form.Item>
        <Form.Item sx={{ justifyContent: "end" }}>
          <FormFieldLabel
            sx={getInputStyles("dateOfBirth", "datepicker")}
            small
            hasErrorMessage={false}
            name="dateOfBirth"
            label={t("Date of Birth *")}
          >
            <LocalDatePicker
              id="dobPicker"
              noOffset
              value={values.dateOfBirth || undefined}
              disabled={fieldsDisabled}
              onChange={(value: string) => {
                const selectedDate = new Date(value);
                const currentUTCDate = getDateBasedOnOffset(new Date().toISOString(), true);
                // subtract 18 years from the currentUTCDate
                const minimumAllowedDate = subYears(currentUTCDate ?? new Date(), 18);

                if (selectedDate >= minimumAllowedDate) {
                  setFieldValue("dateOfBirth", "");
                  notifications.error({
                    description: "Minimum age requirement is 18 years.",
                  });
                  return;
                }
                setFieldValue("dateOfBirth", value);
              }}
              onBlur={() => setFieldTouched("dateOfBirth", true, true)}
              minDate={subYears(new Date(), 124)}
              maxDate={subYears(new Date(), 18)}
            />
          </FormFieldLabel>
        </Form.Item>
        <Box sx={{ flex: 2, pl: 3 }} />
      </Grid>
      <Flex sx={{ mt: 2 }}>
        <LegacyCheckbox
          label={t("Is this person a U.S. citizen?")}
          checked={values.isUSCitizen}
          onChange={(event) => {
            setFieldValue("isUSCitizen", event.target.checked);
          }}
          disabled={fieldsDisabled}
          sx={{ py: 3 }}
        />
      </Flex>
      <Box sx={{ mt: 2 }}>{identificationFields}</Box>
      <AddressFormFields
        errors={(errors.physicalAddress ?? {}) as FormikErrors<AddressInput>}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        disableFields={fieldsDisabled}
        title="Residential Address"
        values={values.physicalAddress as AddressInput}
        getInputStyles={getInputStyles}
        getInputVariant={getInputVariant}
      />

      {getRequiredFieldsMessage()}
    </Form>
  );
};
