import { useFormikContext } from "formik";
import { flatten } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useFormikInputError } from "@/app/hooks/use-formik-input-error";
import {
  BusinessClassification,
  CompanyInput,
  IndustryClassification,
  useBusinessClassificationsQuery,
} from "@/app/types/generated/graphql";

import { useOnboardingContext } from "../onboarding/context";
import { GetInitialBusinessClassificationNameArgs, UseApplicantDetailsStepFn } from "./applicant-details-step.types";

export const getInitialBusinessClassificationName = ({
  businessClassificationsOptions,
  initialIndustryClassificationUid,
}: GetInitialBusinessClassificationNameArgs) => {
  return (
    flatten(businessClassificationsOptions?.map((bc) => bc.industryClassifications)).find(
      (industryClassification) => industryClassification.id === initialIndustryClassificationUid,
    )?.businessClassificationName || ""
  );
};

export const businessTypes = [
  { label: "Corporation", value: "corporation" },
  { label: "LLC", value: "llc" },
  { label: "Partnership", value: "partnership" },
];

export const useApplicantDetailsStep: UseApplicantDetailsStepFn = () => {
  const {
    goBack: moveBackward,
    goNext: moveForward,
    saveCurrentStage,
    pageTouched,
    stepsDisabledFieldsMap,
  } = useOnboardingContext();

  const { isSubmitting, setFieldValue, values, initialValues, handleSubmit, setFieldTouched, errors, touched } =
    useFormikContext<CompanyInput & { businessClassification: string }>();

  const { getInputStyles, getInputVariant, getRequiredFieldsMessage } = useFormikInputError<CompanyInput>(
    errors,
    touched,
    pageTouched?.includes("Applicant_Controller_Details"),
  );
  const { data: businessClassificationsData, loading: loadingBusinessClassifications } =
    useBusinessClassificationsQuery();

  const businessClassificationsOptions = useMemo<BusinessClassification[]>(
    () => businessClassificationsData?.businessClassifications || [],
    [businessClassificationsData],
  );

  const [industryClassificationsOptions, setIndustryClassificationsOptions] = useState<IndustryClassification[]>([]);

  const setIndustryClassificationDropdownOptions = useCallback(
    (businessClassification: string) => {
      const industryClassificationsOptions = businessClassificationsOptions?.find(
        (b) => b.name === businessClassification,
      )?.industryClassifications;
      setIndustryClassificationsOptions(industryClassificationsOptions || []);
    },
    [businessClassificationsOptions],
  );

  useEffect(() => {
    if (!businessClassificationsData) return;
    const options = businessClassificationsData.businessClassifications || [];

    const initialBusinessClassification = getInitialBusinessClassificationName({
      businessClassificationsOptions: options,
      initialIndustryClassificationUid: initialValues.industryClassificationUid,
    });

    setFieldValue("businessClassification", initialBusinessClassification);
    setIndustryClassificationDropdownOptions(initialBusinessClassification);
  }, [
    setFieldValue,
    businessClassificationsData,
    businessClassificationsOptions,
    initialValues.industryClassificationUid,
    setIndustryClassificationDropdownOptions,
    businessClassificationsData?.businessClassifications,
  ]);

  const fieldsDisabled = !!stepsDisabledFieldsMap.Applicant_Details.length;

  const goNext = useCallback(async () => {
    await saveCurrentStage();
    moveForward();
  }, [moveForward, saveCurrentStage]);

  const goBack = useCallback(async () => {
    await saveCurrentStage();
    moveBackward();
  }, [moveBackward, saveCurrentStage]);

  return {
    fieldsDisabled,
    handleSubmit,
    errors,
    getInputVariant,
    getInputStyles,
    setFieldTouched,
    setFieldValue,
    values,
    setIndustryClassificationDropdownOptions,
    loadingBusinessClassifications,
    businessClassificationsOptions,
    industryClassificationsOptions,
    getRequiredFieldsMessage,
    isSubmitting,
    goNext,
    goBack,
  };
};
