import { AccountDetailsInput, AccountQuery } from "@/app/types/generated/graphql";

/**
 * Converts account object into AccountDetailsInput type for validation checking and populating values in form
 */

export const convertToAccountDetailsInput: (
  account?: Partial<AccountQuery["account"]> | null,
) => AccountDetailsInput = (account) => ({
  tenantUid: account?.tenantId,
  termsAccepted: !!account?.termsAcceptedDate,
  privacyPolicyAccepted: !!account?.privacyPolicyAcceptedDate,
  rulesAccepted: !!account?.rulesAcceptedDate,
});
