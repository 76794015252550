import { IconError, IconPending } from "@powerledger/ui-component-lib";

import { RegistryLinkStatus, useAccountQuery } from "@/app/types/generated/graphql";

export function useRegistrySettingsStatusIcon() {
  const { data: accountData, loading: loadingAccount } = useAccountQuery();

  if (loadingAccount) return null;

  if (!accountData?.account?.linkedRegistries?.length) return IconError;

  if (
    accountData?.account?.linkedRegistries?.some(
      (registry) => registry.status === RegistryLinkStatus.RegistrationFailed,
    )
  ) {
    return IconError;
  } else if (
    accountData?.account?.linkedRegistries?.some((registry) => registry.status !== RegistryLinkStatus.Registered)
  )
    return IconPending;

  return null;
}
