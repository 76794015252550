import { useRecOptions } from "@/app/hooks";
import { formatAttributes } from "@/app/lib/format-attributes";
import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { ExportDetailsQuery, RecAssetAttributes, useExportDetailsQuery } from "@/app/types/generated/graphql";

import { UseExportRequestDetailsFn } from "./export-history-details-modal.types";

export const getExportHistoryDetailsData = (
  data: ExportDetailsQuery["exportRequestById"]["exportRequestDetails"],
  recOptions?: RECOrderAttributeOptions,
) =>
  !recOptions
    ? []
    : data?.map((detail) => ({
        ...detail,
        attributes: detail.attributes as RecAssetAttributes,
        ...formatAttributes({ attributes: detail.attributes as RecAssetAttributes, options: recOptions }),
      })) ?? [];

export const useExportRequestDetails: UseExportRequestDetailsFn = ({ id = "" }) => {
  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const { data, loading: queryLoading } = useExportDetailsQuery({
    variables: {
      id,
    },
  });

  const loading = queryLoading ?? recOptionsLoading;
  return {
    recOptions,
    loading,
    exportData: data?.exportRequestById,
  };
};
