import { Box, Button, Flex, Modal } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const UnverifiedAccountModal = ({ visible }: { visible: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal visible={visible} modalMaxWidth="1000px" sx={{ maxHeight: "inherit" }} hideCloseButton>
      <>
        <Box sx={{ color: "textDarker", fontSize: [0, 1], textTransform: "uppercase", letterSpacing: 2, mb: 4 }}>
          {t("Alert")}
        </Box>
        <Flex sx={{ pb: [3, 4] }}>
          <Box>
            <Box sx={{ fontSize: [3, null, 5], fontFamily: "MintGroteskV08", fontWeight: "bold" }}>
              {t("Your account is unverified")}
            </Box>
            <Box sx={{ color: "textDarker", mt: 2 }}>
              {t(
                "You cannot browse TraceX until your account is verified. You can check your verification status and manage your account in account settings.",
              )}
            </Box>
          </Box>
        </Flex>
        <Flex sx={{ justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant="primary"
            onClick={() => {
              navigate("/settings");
            }}
          >
            {t("Go to account settings")}
          </Button>
        </Flex>
      </>
    </Modal>
  );
};
